import styled from '@emotion/styled';
import React, { useEffect, useState } from 'react';

import { colors, fonts } from '../../../../../../../styles/theme';
import { useMobileHeaderContext } from '../../../context/mobileHeaderProvider';

const Title = styled.div`
    font-family: ${fonts.OpenSans_SemiBold};
    font-size: 13px;

    line-height: 0;
    display: flex;
    gap: 4px;
    align-items: center;

    color: ${colors.$primary700};

    padding: 8px;
    border-radius: 8px;
    background-color: transparent;
    height: 40px;

    display: flex;
    justify-content: space-between;

    img {
        transform: rotate(270deg);
    }

    cursor: pointer;
`;

const StyledMenu = styled.div<{ isOpen: boolean }>`
    display: ${props => (props.isOpen ? 'flex' : 'none')};

    position: absolute;
    left: 0;
    top: 72px;
    width: 100%;

    height: 92vh;
    z-index: 10;

    overflow-y: scroll;

    flex-direction: column;

    background-color: white;
`;

const MobileSubmenu = ({
    title,
    trackingId,
    children,
}: {
    title: string;
    trackingId: string;
    children: React.ReactNode | React.ReactNode[];
}) => {
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);

    const { isSubmenuActive, setIsSubmenuActive } = useMobileHeaderContext();

    useEffect(() => {
        if (!isSubmenuActive) return setIsDropdownOpen(false);
    }, [isSubmenuActive]);

    const handleClick = () => {
        setIsDropdownOpen(v => !v);
        setIsSubmenuActive(v => !v);
    };

    return (
        <div>
            <Title onClick={handleClick} data-ga-send={trackingId}>
                {title}
                <img src="/images/header/icon-arrow-down-menu.svg" />
            </Title>
            <StyledMenu isOpen={isDropdownOpen}>{children}</StyledMenu>
        </div>
    );
};

export default MobileSubmenu;
