import styled from '@emotion/styled';
import { Link } from 'gatsby';

import { breakpoint, colors, fonts } from '../../../../../styles/theme';

export const Header = styled.header<{ isScrolled: boolean; forceWhiteBackground?: boolean; hasHeaderMedia?: boolean }>`
    position: relative;
    background: ${props => (props.forceWhiteBackground ? 'white' : props.isScrolled ? 'white' : 'transparent')};
    transition: all 0.25s ease;
    display: flex;
    align-items: center;
    padding: 0 16px;
    z-index: 9;
`;

export const StyledLink = styled(Link)`
    padding: 8px;
    border-radius: 8px;
    background-color: transparent;
    height: 40px;

    &:hover {
        background-color: rgba(1, 58, 81, 0.05);
    }
`;

export const StyledAnchor = styled.a`
    padding: 8px;
    border-radius: 8px;
    background-color: transparent;
    height: 40px;

    &:hover {
        background-color: rgba(1, 58, 81, 0.05);
    }
`;

export const StyledNav = styled.nav`
    position: relative;
    display: flex;
    gap: 4px;
    ${breakpoint.custom.max('1099')} {
        flex-direction: column;
        position: unset;
    }
`;

export const NavLink = styled(StyledLink)`
    font-family: ${fonts.OpenSans_SemiBold};
    font-size: 13px;

    line-height: 0;
    display: flex;
    align-items: center;

    color: ${colors.$primary700};
`;
