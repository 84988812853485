import 'swiper/css';
import 'swiper/css/pagination';

import styled from '@emotion/styled';
import { Link } from 'gatsby';
import * as React from 'react';

import { breakpoint, colors } from '../../styles/theme';

const SectionTitle = styled.div`
    font-size: 16px;
    color: ${colors.$primary};
    margin-bottom: 8px;
`;

const SectionBigTitle = styled.div`
    font-family: Eina-SemiBold;
    font-size: 32px;
    color: #ffffff;
`;

const SectionText = styled.div`
    font-size: 14px;
    color: #ffffff;
    line-height: 150%;
`;

const SectionFactureXCard = styled.div`
    background-color: #013a51;
    padding: 100px 120px;
    display: flex;
    flex-basis: 50%;
    gap: 56px;
    > div {
        flex-basis: 50%;
    }
    ${breakpoint['$xlarge-max']} {
        padding: 90px 80px;
        gap: 20px;
    }
    ${breakpoint['$large-max']} {
        padding: 70px 20px;
        flex-direction: column;
        align-items: center;
    }
`;

const StyledImg = styled.img`
    max-width: 400px;
    width: 100%;
`;

const SectionFactureXWrapper = styled.section`
    height: 100%;
    background: linear-gradient(white 50%, #021e2b 50%);
`;

const SectionFactureX = (props: { tracking?: string }) => (
    <SectionFactureXWrapper id="facturX" className="section">
        <div className="container">
            <SectionFactureXCard>
                <div>
                    <SectionTitle>Factur-X</SectionTitle>
                    <SectionBigTitle>La Factur-X arrive dès 2024 Qu’est ce que c’est ?</SectionBigTitle>
                    <SectionText>
                        La Factur-X c’est un format de facture électronique standardisé qui va permettre de simplifier
                        et de sécuriser l’envoi et la réception de factures d’ici à 2026.
                        <br />
                        <br />
                        Qu’est ce que ça change pour vous ?
                        <br />
                    </SectionText>
                    <SectionText>
                        La facturation papier vit ses derniers jours. À partir de 2024, la facturation électronique
                        deviendra progressivement une obligation pour toutes les entreprises !<br /> Pas de panique !
                        Chez Dougs on travaille déjà sur le sujet et on est prêt à vous accompagner. Alors pour
                        découvrir toutes les actus sur la Factur-X{' '}
                        <Link to="/blog/facture-electronique-obligation/" data-ga-send={props.tracking}>
                            lisez&nbsp;notre&nbsp;article&nbsp;👈
                        </Link>
                    </SectionText>
                </div>
                <div>
                    <StyledImg src="/images/fonctionnalites/logiciel-de-facturation-gratuit/illu-24.svg" />
                </div>
            </SectionFactureXCard>
        </div>
    </SectionFactureXWrapper>
);

export default SectionFactureX;
