import styled from '@emotion/styled';
import { graphql } from 'gatsby';
import { IGatsbyImageData } from 'gatsby-plugin-image';
import * as React from 'react';

import { breakpoint, colors, fonts } from '../../styles/theme';
import DownloadChecklist from '../media/DownloadChecklist';
import IntroText from '../Post/IntroText';
import RessourceSlice from '../ressources/prismic/RessourceSlice';
import VatNumber from '../VatNumer';
import { Button } from './slices/Button';
import { Card } from './slices/Card';
import { FaqSlice as Faq } from './slices/Faq';
import { Guide } from './slices/Guide';
import HighlightCard from './slices/HighlightCard';
import { Image } from './slices/Image';
import { LineHorizontal } from './slices/LineHorizontal';
import { Newsletter } from './slices/Newsletter';
import { Overline } from './slices/Overline';
import { Quote } from './slices/Quote';
import { Summary } from './slices/Summary';
import { Text } from './slices/Text';
import { WordpressContent, WordpressContentTable } from './slices/WordpressContent';
import ZoomableImage from './slices/ZoomableImage';
import { splitNormalText } from './utils';
import SectionSimulator from '../Builder/sections/SectionSimulator/SectionSimulator';

const sliceComponents = {
    normal_text: Text,
    overline: Overline,
    quote: Quote,
    button: Button,
    card: Card,
    line_horizontal: LineHorizontal,
    image: Image,
    guide: Guide,
    tva_number: VatNumber,
    download_checklist: DownloadChecklist,
    wordpresscontent: WordpressContent,
    faq: Faq,
    newsletter: Newsletter,
    highlight: HighlightCard,
    download_ressource: RessourceSlice,
    sectionsimulator: SectionSimulator,
};

const Container = styled.div`
    padding-top: 0px;

    ${breakpoint.laptop} {
        padding-top: 24px;
    }
`;

const SummaryMobile = styled.div`
    margin-bottom: 48px;
    margin-top: 48px;

    ${breakpoint.laptop} {
        display: none;
    }
`;

const Label = styled.div`
    color: ${colors.$primary};
    font-family: ${fonts.Eina_SemiBold};
    font-size: 13px;
    margin-bottom: 8px;
`;

const StyledFrameSlice = styled.div`
    table {
        margin-bottom: 0;
    }

    & > *:last-child {
        margin-bottom: 0;
    }

    ul:last-child {
        display: flex;
        flex-direction: column;
        gap: 16px;
        li {
            margin-bottom: 0;
        }
    }
`;

const SliceZoneFrame = styled.div`
    display: flex;
    flex-direction: column;
    gap: 48px;
    margin-top: 48px;
`;

const Cover = styled.div``;

export const SliceZone = ({
    sliceZone,
    noChecklist = false,
    image = null,
    summaryItems,
}: {
    sliceZone: Queries.PrismicPostDataBody[];
    noChecklist: boolean;
    image: IGatsbyImageData;
    summaryItems?: { slug: string; label: string }[];
}) => {
    const introTextSliceIndex = sliceZone.findIndex(slice => slice.slice_type === 'normal_text');
    const sliceZoneContent = splitNormalText(sliceZone)
        .map((slice, index) => {
            if (index === introTextSliceIndex) return;

            const SliceComponent = sliceComponents[slice.slice_type];

            // Afficher un tableau wordpresscontent
            if (
                slice.slice_type === 'wordpresscontent' &&
                slice.primary.content.text &&
                slice.primary.content.text.includes('</table>')
            ) {
                return (
                    <StyledFrameSlice>
                        <WordpressContentTable key={`table-${slice.id}`} content={slice.primary.content.text} />
                    </StyledFrameSlice>
                );
            }

            if (SliceComponent) {
                return (
                    <StyledFrameSlice>
                        <SliceComponent
                            slices={sliceZone}
                            slice={slice}
                            key={`slice-${slice.id}`}
                            index={index}
                            isInBlog
                        />
                    </StyledFrameSlice>
                );
            }
            return null;
        })
        .filter(e => e);

    // Afficher DownloadChecklist s'il n'est pas défini dans sliceZone
    if (
        !noChecklist &&
        !sliceZone
            .map(slice => slice.slice_type)
            .find(slicetype => slicetype === 'download_checklist' || slicetype === 'wordpresscontent')
    ) {
        sliceZoneContent.splice(3, 0, <DownloadChecklist key="download-checklist" />);
    }

    const introTextSlice = sliceZone.find(slice => slice.slice_type === 'normal_text');

    return (
        <Container>
            <Cover>
                {!!introTextSlice && (
                    <IntroText render={introTextSlice.primary.content_text.richText} htmlSerializer={null} />
                )}
                <ZoomableImage image={image} alt="cover-image" />
                <SummaryMobile>
                    <Label>SOMMAIRE</Label>
                    <Summary items={summaryItems} key="summary" isMobile />
                </SummaryMobile>
            </Cover>
            <SliceZoneFrame>{sliceZoneContent}</SliceZoneFrame>
        </Container>
    );
};

export const postDataNewsletterFragment = graphql`
    fragment PostNewsletter on PrismicPostDataBodyNewsletter {
        id
        slice_type
    }
`;

export const postDataImageFragment = graphql`
    fragment PostImage on PrismicPostDataBodyImage {
        id
        slice_type
        primary {
            legend
            description
            image {
                url
                alt
            }
        }
    }
`;

export const postDataWordpressContentFragment = graphql`
    fragment PostWordpress on PrismicPostDataBodyWordpresscontent {
        id
        slice_type
        primary {
            content {
                richText
                html
                text
            }
        }
    }
`;

export const postDataOverlineFragment = graphql`
    fragment PostOverline on PrismicPostDataBodyOverline {
        id
        slice_type
        primary {
            content_text {
                richText
            }
        }
    }
`;

export const postDataNormalTextFragment = graphql`
    fragment PostNormalText on PrismicPostDataBodyNormalText {
        id
        slice_type
        primary {
            content_text {
                richText
                html
                text
            }
        }
    }
`;

export const postDataLineHorizontalFragment = graphql`
    fragment PostLineHorizontal on PrismicPostDataBodyLineHorizontal {
        id
        slice_type
        primary {
            content_text {
                richText
            }
        }
    }
`;

export const postDataCardFragment = graphql`
    fragment PostCard on PrismicPostDataBodyCard {
        id
        slice_type
        primary {
            content_text {
                richText
            }
        }
    }
`;

export const postDataButtonFragment = graphql`
    fragment PostButton on PrismicPostDataBodyButton {
        id
        slice_type
        primary {
            content_text {
                richText
            }
            link {
                url
                target
            }
        }
    }
`;

export const postDataGuideFragment = graphql`
    fragment PostGuide on PrismicPostDataBodyGuide {
        id
        slice_type
    }
`;

export const postDataTvaFragment = graphql`
    fragment PostTVA on PrismicPostDataBodyTvaNumber {
        id
        slice_type
    }
`;

export const postDataDownloadFragment = graphql`
    fragment PostDownload on PrismicPostDataBodyDownloadChecklist {
        id
        slice_type
        primary {
            checklist {
                document {
                    ... on PrismicChecklist {
                        id
                        _previewable
                        data {
                            cta_text
                            description {
                                text
                            }
                            form_hubspot_id
                            title
                            image {
                                gatsbyImageData
                            }
                        }
                    }
                }
            }
        }
    }
`;

export const postDataSummaryFragment = graphql`
    fragment PostSummary on PrismicPostDataBodySummary {
        id
        slice_type
    }
`;

export const postDataFaqFragment = graphql`
    fragment PostFaq on PrismicPostDataBodyFaq {
        id
        slice_type
        items {
            answer {
                html
                text
            }
            question
        }
    }
`;

export const postDataHighlightFragment = graphql`
    fragment PostHighlight on PrismicPostDataBodyHighlight {
        id
        slice_label
        slice_type
        primary {
            background_color
            icon
            text {
                richText
            }
        }
    }
`;

export const postDataDownloadRessource = graphql`
    fragment PostDownloadRessource on PrismicPostDataBodyDownloadRessource {
        id
        slice_label
        slice_type
        primary {
            cta_text
            ressource
        }
    }
`;

export const postSectionsimulator = graphql`
    fragment PostSectionsimulator on PrismicPostDataBodySectionsimulator {
        id
        slice_label
        slice_type
    }
`;
