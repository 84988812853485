import styled from '@emotion/styled';
import React from 'react';

import { useHeaderContext } from '../../../../contexts/headerContextProvider';
import { Header } from './styles/styles';

const StyledHeader = styled(Header)`
    justify-content: center;
    height: 64px;
`;

const StyledLogo = styled.img`
    width: 100px;
    padding: 16px 0;
`;

const HeaderMinimal = () => {
    const { stickyHeader } = useHeaderContext();

    return (
        <StyledHeader isScrolled={stickyHeader.hasReachedScrollBreakpoint}>
            <StyledLogo src="/images/header/logo-dougs.svg" alt="Dougs expert comptable en ligne" />
        </StyledHeader>
    );
};

export default HeaderMinimal;
