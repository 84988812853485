import { Link } from 'gatsby';
import * as React from 'react';

import { SeoTitle } from '../seo/seoTitle';
import * as styles from './mini-card-icon.module.scss';

export type MiniCardIconItem = {
    icn: string;
    title: string;
    text: React.ReactNode;
    btnLink?: string;
    btnText?: string;
};

const MiniCardIcon = ({ card }: { card: MiniCardIconItem }) => {
    const Container = card.btnLink ? Link : 'div';
    return (
        <Container className={styles.miniCardIcon} to={card.btnLink}>
            <div className={styles.miniCardIconWrapperIcon}>
                <img className={styles.miniCardIconIcon} src={card.icn} />
            </div>
            <SeoTitle as={'h2'} seoStyle={'h4'}>
                <div className="category-thumb__title">{card.title}</div>
            </SeoTitle>
            <p className={styles.miniCardIconText}>{card.text}</p>
            <div className="link-underline">{card.btnText}</div>
        </Container>
    );
};

export default MiniCardIcon;
