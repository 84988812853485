import { Link } from 'gatsby';
import * as React from 'react';

import Sticky from '../layout/header/utils/Sticky';
import * as styles from './post-aside.module.scss';
import PostTags from './PostTags';

const PostAside = ({
    category,
    posts,
}: {
    category: { databaseId?: string; uri: string; name: string; color: string };
    posts: { title: string; uri: string; data: any }[];
}) => {
    return (
        <Sticky as="div" className={styles.postAside}>
            <Link to={category.uri} className={`post-category__name post-category__name--${category.color}`}>
                {category.name}
            </Link>
            {posts &&
                posts.map((post, index) => (
                    <div key={index} className={`post-inline post-inline--${category.color}`}>
                        <PostTags post={post} className="mb-1" />
                        <Link
                            to={post.uri}
                            className="post-inline__link"
                            dangerouslySetInnerHTML={{ __html: post.title }}
                        ></Link>
                    </div>
                ))}
        </Sticky>
    );
};

export default PostAside;
