import useBreakpoint from 'use-breakpoint';

/**
 * It is important to bind the object of breakpoints to a variable for memoization to work correctly.
 * If they are created dynamically, try using the `useMemo` hook.
 */
const BREAKPOINTS = { small: 0, medium: 640, large: 1024, xlarge: 1226, xxlarge: 1460 };

export type BreakpointTypes = 'small' | 'medium' | 'large' | 'xlarge' | 'xxlarge';

const useBreakpoints = (value?: BreakpointTypes[]) => {
    const { breakpoint } = useBreakpoint(BREAKPOINTS);
    return value ? value.includes(breakpoint) : breakpoint;
};

export default useBreakpoints;
