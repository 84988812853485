const CANONICAL_CONFIG: { old: string; new: string }[] = [
    {
        old: '/quel-est-le-nombre-de-jours-travailles-en-2020/',
        new: '/blog/quel-est-le-nombre-de-jours-ouvres-et-de-jours-ouvrables-en-2022/',
    },
    {
        old: '/quel-est-le-nombre-de-jours-travailles-en-2021/',
        new: '/blog/quel-est-le-nombre-de-jours-ouvres-et-de-jours-ouvrables-en-2022/',
    },
];

export const getCanonicalUrl = (location: string): string => {
    const newUrl: { old: string; new: string } = CANONICAL_CONFIG.find(config => location.includes(config.old));
    return 'https://www.dougs.fr' + (newUrl?.new || location);
};
