import React from 'react';

import { CREA_NETLINKING_PAGES, RESSOURCES_CREA } from '../../constants/data.const';
import MobileSubmenu from './MobileSubmenu/MobileSubmenu';
import SubmenuSection from './MobileSubmenu/SubmenuSection';

const CreaSubmenu = () => {
    return (
        <MobileSubmenu title={"Création d'entreprise"} trackingId="topbar-creation-hover">
            <SubmenuSection variant="primary" items={CREA_NETLINKING_PAGES} />

            <SubmenuSection title="Ressources" variant="secondary" items={RESSOURCES_CREA} />
        </MobileSubmenu>
    );
};

export default CreaSubmenu;
