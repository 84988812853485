import styled from '@emotion/styled';
import { Link } from 'gatsby';

import { breakpoint, colors, fonts } from '../../../../styles/theme';
import { ARROW_BUTTON_COLOR, FULL_WIDTH, SIZE, StyledButtonProps, StyledLinkProps, VARIANT } from './types';

export const getColors = (color: keyof typeof ARROW_BUTTON_COLOR) => {
    switch (color) {
        case ARROW_BUTTON_COLOR.PRIMARY:
            return {
                primary: colors.$primary,
                secondary: 'white',
            };
        case ARROW_BUTTON_COLOR.PRIMARY_700:
            return {
                primary: colors.$primary700,
                secondary: 'white',
            };
        case ARROW_BUTTON_COLOR.WHITE:
            return {
                primary: 'white',
                secondary: colors.$primary700,
            };
        case ARROW_BUTTON_COLOR.ORANGE:
            return {
                primary: colors.$orange,
                secondary: 'white',
            };
        case ARROW_BUTTON_COLOR.LIGHT_ORANGE:
            return {
                primary: colors.$lightOrange,
                secondary: colors.$orange,
            };
        default:
            return {
                primary: 'white',
                secondary: colors.$primary700,
            };
    }
};

export const getSizes = (size: keyof typeof SIZE) => {
    switch (size) {
        case SIZE.SMALL:
            return {
                height: '40px',
                padding: '0px 24px',
                paddingOnHover: '0px 32px 0px 16px',
                arrowRightAbsolute: '12px',
                arrowWidth: '16px',
            };
        case SIZE.NORMAL:
            return {
                height: '48px',
                padding: '0px 32px',
                paddingOnHover: '0px 40px 0px 24px',
                arrowRightAbsolute: '14px',
                arrowWidth: '20px',
            };

        default:
            return {
                height: '48px',
                padding: '0 32px',
                paddingOnHover: '0px 12px 0px 16px',
                arrowRightAbsolute: '14px',
                arrowWidth: '20px',
            };
    }
};

const ButtonStyles = styled.button<StyledButtonProps>`
    position: relative;

    cursor: pointer;

    font-family: ${fonts.OpenSans_Bold};
    font-size: 14px;
    font-weight: 700;
    line-height: normal;

    width: ${props => (props.fullWidth === FULL_WIDTH.BOTH ? '100%' : 'fit-content')};

    display: flex;
    justify-content: center;

    ${breakpoint['$large-max']} {
        width: ${props => (props.fullWidth === FULL_WIDTH.DISABLED ? 'fit-content' : '100%')};
    }

    div {
        position: relative;

        display: flex;
        justify-content: center;
        align-items: center;

        transition:
            all 200ms ease-out,
            border-width 0s;

        height: ${props => props.sizes.height};
        padding: ${props => props.sizes.padding};

        width: fit-content;

        span {
            position: relative;
            z-index: 2;
            font-weight: 600;
            ${props => !props.isWrap && 'white-space: nowrap'}
        }

        //ARROW EFFECT

        svg {
            position: absolute;
            right: 25px;
            opacity: 0;
            transition: all 200ms ease-out;
            z-index: 2;
        }
    }

    &:hover {
        div {
            padding: ${props => props.sizes.paddingOnHover};
            svg {
                right: ${props => props.sizes.arrowRightAbsolute};
                opacity: 1;
            }
        }
    }
`;

export const DefaultButton = styled(ButtonStyles)<StyledButtonProps>`
    border-radius: 24px;
    cursor: pointer;
    position: relative;

    background: ${props => props.colors.primary};
    border: ${props => (props.haveBorder ? `1px solid ${props.colors.secondary}` : 'none')};
    color: ${props => props.colors.secondary};

    &::after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        opacity: 0;
        background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.15) 100%),
            ${props => props.colors.primary}; /* Background color on hover */
        border-radius: 24px;
        z-index: 1; /* Place the pseudo-element below the button's content */
        transition: opacity 200ms ease-out; /* Transition the background color */
    }

    &:hover::after {
        opacity: 1;
    }

    &:hover {
        box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.1);
    }
`;

export const OutlinedButton = styled(ButtonStyles)<StyledButtonProps>`
    background: transparent;
    color: ${props => props.colors.primary};
    transition: all 200ms ease-out;
    box-shadow: inset 0 0 0 1px ${props => props.colors.primary};
    border: none;
    border-radius: 24px;
    z-index: 1;

    &:hover {
        box-shadow: inset 0 0 0 2px ${props => props.colors.primary};
    }
`;

export const LinkButton = styled.div<StyledLinkProps>`
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${props => props.colors.primary};

    position: relative;

    cursor: pointer;

    padding: 4px 20px 4px 0px;

    font-family: ${fonts.OpenSans_Bold};
    font-size: 14px;
    //ARROW EFFECT

    span {
        background: linear-gradient(to right, ${props => props.colors.primary}, ${props => props.colors.primary});
        background-size: 100% 1px;
        background-position: left 100%;
        background-repeat: no-repeat;
        transition: background-size 200ms;
    }

    svg {
        position: absolute;
        right: 20px;
        opacity: 0;
        transition: all 200ms ease-out;
    }

    &:hover {
        span {
            background-size: 0 1px;
        }
        svg {
            right: 0px;
            opacity: 1;
        }
    }
`;

export const StyledLink = styled(Link)<{ fullWidth: keyof typeof FULL_WIDTH; variant: keyof typeof VARIANT }>`
    display: flex;
    width: ${props => (props.fullWidth !== FULL_WIDTH.BOTH || props.variant === VARIANT.LINK ? 'fit-content' : '100%')};

    ${breakpoint['$large-max']} {
        width: ${props =>
            props.fullWidth === FULL_WIDTH.DISABLED || props.variant === VARIANT.LINK ? 'fit-content' : '100%'};
    }
`;

export const StyledAnchor = styled.a<{ fullWidth: keyof typeof FULL_WIDTH; variant: keyof typeof VARIANT }>`
    display: flex;
    width: ${props => (props.fullWidth !== FULL_WIDTH.BOTH || props.variant === VARIANT.LINK ? 'fit-content' : '100%')};

    ${breakpoint['$large-max']} {
        width: ${props =>
            props.fullWidth === FULL_WIDTH.DISABLED || props.variant === VARIANT.LINK ? 'fit-content' : '100%'};
    }
`;

export const StyledDiv = styled.div<{ fullWidth: keyof typeof FULL_WIDTH; variant: keyof typeof VARIANT }>`
    display: flex;
    width: ${props => (props.fullWidth !== FULL_WIDTH.BOTH || props.variant === VARIANT.LINK ? 'fit-content' : '100%')};

    ${breakpoint['$large-max']} {
        width: ${props =>
            props.fullWidth === FULL_WIDTH.DISABLED || props.variant === VARIANT.LINK ? 'fit-content' : '100%'};
    }
`;
