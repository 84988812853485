import * as React from 'react';
import { createContext, ReactNode, useContext, useEffect, useState } from 'react';

const AddBlockContext = createContext(false);

export default function AddBlockContextProvider({ children }: { children: ReactNode }) {
    const [isAdblockDetected, setIsAdblockDetected] = useState(false);
    const Intercom = typeof window === 'undefined' ? undefined : window.Intercom;

    useEffect(() => {
        !Intercom && setIsAdblockDetected(!Intercom);
    }, [Intercom]);

    return <AddBlockContext.Provider value={isAdblockDetected}>{children}</AddBlockContext.Provider>;
}

export function useAddBlockContext() {
    return useContext(AddBlockContext);
}
