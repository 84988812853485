import { useCallback, useState } from 'react';

export default () => {
    const [isModalOpen, setIsModalOpen] = useState(false);

    const toggleModal = useCallback(() => {
        setIsModalOpen(old => !old);
    }, []);

    const openModal = useCallback(() => {
        setIsModalOpen(true);
    }, []);

    const closeModal = useCallback(() => {
        setIsModalOpen(false);
    }, []);

    return {
        isModalOpen,
        openModal,
        closeModal,
        toggleModal,
    };
};
