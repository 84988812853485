import { graphql, Link, useStaticQuery } from 'gatsby';
import * as React from 'react';

import { ROUTES } from '../../constants/const/routes.const';
import { SeoTitle } from '../seo/seoTitle';
import GuideThumb from './GuideThumb';

const GuideBanner = ({ withSeoTitles = true }: { withSeoTitles?: boolean }) => {
    const prismicGuides = useStaticQuery(query);
    const guides = prismicGuides.allPrismicGuide.nodes || [];
    return (
        <div className="section section--dark section--large">
            <div className="container">
                <div className="row u-align-center">
                    <div className="col-md-3">
                        <SeoTitle as={withSeoTitles ? 'h2' : 'div'} seoStyle={!withSeoTitles ? 'h2' : ''}>
                            Les guides pratiques de l’entrepreneur
                        </SeoTitle>
                        <Link
                            to={ROUTES.GUIDES.uri}
                            data-ga-send="event,button,click,blog-cta-allguides"
                            data-ga4="container-guides-button-voirtous-click-link"
                            className="Button m-outline m-small mb-3"
                        >
                            Les voir tous &nbsp;&rarr;
                        </Link>
                    </div>
                    <div className="col-md-9">
                        <div className="slider">
                            <div className="slider__content">
                                {guides.slice(0, 3).map((guide, index) => (
                                    <div className="slider__item" key={index}>
                                        <GuideThumb
                                            index={index + 1}
                                            withSeoTitles={withSeoTitles}
                                            guide={guide}
                                            size="small"
                                            className="resource-thumb--dark"
                                        />
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default GuideBanner;

const query = graphql`
    {
        allPrismicGuide(limit: 3, sort: { data: { created_at: DESC } }) {
            nodes {
                data {
                    ...GuideItem
                }
            }
        }
    }
`;
