import * as React from 'react';
import { createContext, ReactNode, useContext } from 'react';

const PageContext = createContext({
    ctaTag: undefined,
    google_score: '4.5',
    trustpilot_score: '4.5',
    google_number: '350',
    trustpilot_number: '150',
    customer_number: 21000,
});

export default function PageContextProvider({ context, children }: { context: any; children: ReactNode }) {
    return <PageContext.Provider value={context}>{children}</PageContext.Provider>;
}

export function usePageContext() {
    return useContext(PageContext);
}
