import { graphql } from 'gatsby';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';
import * as React from 'react';

import TopPaddingBasedOnHeader from '../components/layout/header/utils/TopPaddingBasedOnHeader';
import Layout from '../components/layout/Layout';
import PostThumb from '../components/media/PostThumb';
import Breadcrumb from '../components/navigation/Breadcrumb';
import Pagination from '../components/Pagination';
import { SeoTitle } from '../components/seo/seoTitle';
import { mediaBreadcrumbParser } from '../utils/BreadcrumbParser';
import FacebookIcon from './../images/icons/facebook.svg';
import LinkedinIcon from './../images/icons/linkedin.svg';
import TwitterIcon from './../images/icons/twitter.svg';

const AuthorPrismicPage = ({
    data,
    pageContext,
}: {
    data: { prismicAuteur: Queries.PrismicAuteur; allPrismicPost: { nodes: [Queries.PrismicPost] } };
    pageContext: {
        pagination: {
            count: number;
            current: number;
        };
        first: number;
        id: string;
        postIds: string[];
    };
}) => {
    const author = data.prismicAuteur.data;
    const authorUri = data.prismicAuteur.uri;
    const breadcrumb = mediaBreadcrumbParser(author);

    const posts = data.allPrismicPost.nodes;

    const description = author.description ? author.description : author.shortDescription;

    return (
        <Layout title={`${author.name}`} hasHeaderMedia>
            <TopPaddingBasedOnHeader padding={{ desktop: 0, tablet: 0, mobile: 0 }} />
            <div className="container">{breadcrumb && <Breadcrumb items={breadcrumb} />}</div>
            <section className="section author-hero">
                <div className="container author-hero__content">
                    <div className="row">
                        <div className="col-md-4 offset-md-1">
                            <img
                                className={'avatar'}
                                src={author.avatar.url}
                                alt={author.name}
                                width={120}
                                height={120}
                            />
                            <h1 className="mt-3 mb-0">{author.name}</h1>
                            <div>{author.job}</div>
                            <div className="social-link__wrapper my-3">
                                {author?.facebookUrl && (
                                    <a href={author.facebookUrl} className="social-link link-gray-primary">
                                        <FacebookIcon />
                                    </a>
                                )}
                                {author?.twitterUrl && (
                                    <a href={author.twitterUrl} className="social-link link-gray-primary">
                                        <TwitterIcon />
                                    </a>
                                )}
                                {author?.linkedinUrl && (
                                    <a href={author.linkedinUrl} className="social-link link-gray-primary">
                                        <LinkedinIcon />
                                    </a>
                                )}
                            </div>
                        </div>
                        <div className="col-md-6">
                            <SeoTitle className="h3">À propos de {author.name}</SeoTitle>
                            <div
                                className="p m-big"
                                dangerouslySetInnerHTML={{
                                    __html: description?.replace(/(?:\r\n|\r|\n)/g, '<br />'),
                                }}
                            ></div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="section m-margin">
                <div className="container">
                    <h2 className="h5">Tous les articles de {author.name}</h2>
                    <div className="row thumb-list mb-10">
                        {posts.map((post, index) => (
                            <div className="col-md-4 col-sm-6" key={index}>
                                <PostThumb
                                    post={{
                                        ...post,
                                        img: post.data.cover.gatsbyImageData,
                                    }}
                                    isNoFollow
                                />
                            </div>
                        ))}
                    </div>

                    {pageContext.pagination.count > 1 && (
                        <Pagination uri={authorUri} pagination={pageContext.pagination} />
                    )}
                </div>
            </section>
        </Layout>
    );
};

export default withPrismicPreview(AuthorPrismicPage);

export const pageQuery = graphql`
    query ($id: String!, $postIds: [String]) {
        prismicAuteur(id: { eq: $id }) {
            ...PostAuthor
        }
        allPrismicPost(
            filter: { data: { indexed: { eq: true } }, id: { in: $postIds } }
            sort: { data: { published_date: DESC } }
        ) {
            nodes {
                id
                uid
                uri
                _previewable
                first_publication_date
                data {
                    meta_description
                    published_date
                    titre
                    cover {
                        alt
                        gatsbyImageData
                    }
                    category {
                        document {
                            ... on PrismicCategory {
                                id
                                data {
                                    title
                                    slug
                                    color
                                }
                            }
                        }
                    }
                    labels {
                        tag {
                            document {
                                ... on PrismicTag {
                                    id
                                    uri
                                    data {
                                        name
                                        slug
                                    }
                                }
                            }
                        }
                    }
                    body {
                        ...PostQuote
                        ...PostImage
                        ...PostOverline
                        ...PostNormalText
                        ...PostLineHorizontal
                        ...PostButton
                        ...PostCard
                        ...PostGuide
                        ...PostTVA
                    }
                    author {
                        document {
                            ...PostAuthor
                        }
                    }
                }
            }
        }
    }
`;
