import { Link } from 'gatsby';
import * as React from 'react';

import styled from '@emotion/styled';
import { colors, fonts } from '../../styles/theme';

export type AuthorInfos = Queries.PrismicAuteur;

const Container = styled(Link)`
    display: flex;
    color: ${colors.$primary700};
`;

const AuthorName = styled.div`
    font-size: 15px;
    font-family: ${fonts.Eina_SemiBold};
`;

const AuthorJob = styled.div`
    font-size: 13px;
`;

const PostInfos = ({ author, entryTime = false }: { date?: string; author: AuthorInfos; entryTime?: boolean }) => {
    if (!author?.data) return <div />;

    return (
        <Container
            to={author.uri}
            data-ga-send="event,button,click,blog-author"
            data-ga4="container-author-text-topauthor-click-link"
            rel="nofollow"
        >
            {author.data.avatar && (
                <img
                    loading="lazy"
                    alt={author.data.name}
                    src={author.data.avatar.url}
                    className="avatar mr-1"
                    height="40"
                    width="40"
                />
            )}
            <div>
                <AuthorName id={entryTime ? 'author' : undefined}>{author.data.name}</AuthorName>
                <AuthorJob className={'text-mini u-block' + (entryTime ? ' entry-time' : '')}>
                    {author.data.job || 'rédacteur'}
                </AuthorJob>
            </div>
        </Container>
    );
};

export default PostInfos;
