import styled from '@emotion/styled';
import * as React from 'react';
import { createContext, useContext, useState } from 'react';

import useModal from '../../../hooks/useModal';
import HubspotForm from '../../hubspot/HubspotForm';
import Modal from '../../modals/Modal';

const Titre = styled.div`
    font-size: 24px !important;
`;

export const RessourceModalContext = createContext({
    selectedRessource: {
        title: null,
        form_hubspot_id: null,
    },
    setSelectedRessource: (prevState: undefined) => {
        console.log(prevState);
        return undefined;
    },
    handleOpen: ressource => {
        return null;
    },
});

const RessourceModalProvider = ({ children }: { children: React.ReactNode }) => {
    const { openModal, isModalOpen, closeModal } = useModal();

    const [selectedRessource, setSelectedRessource] = useState(null);

    const handleOpen = ressource => {
        setSelectedRessource(ressource);
        openModal();
    };

    const handleClose = () => {
        setSelectedRessource(null);
        closeModal();
    };

    return (
        <RessourceModalContext.Provider value={{ handleOpen, setSelectedRessource, selectedRessource }}>
            <Modal isOpen={isModalOpen} close={handleClose} size="small">
                {selectedRessource && (
                    <React.Fragment>
                        <Titre className="h2">{selectedRessource?.title}</Titre>
                        <HubspotForm
                            trackingId={selectedRessource?.title}
                            formId={selectedRessource?.form_hubspot_id}
                            target={'HubspotForm-ressource'}
                        ></HubspotForm>
                    </React.Fragment>
                )}
            </Modal>
            {children}
        </RessourceModalContext.Provider>
    );
};

export default RessourceModalProvider;

export function useModalRessourceContext() {
    return useContext(RessourceModalContext);
}
