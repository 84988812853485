import styled from '@emotion/styled';
import React, { useState } from 'react';

import { breakpoint, colors, fonts } from '../../../../../../styles/theme';

const Title = styled.div`
    font-family: ${fonts.OpenSans_SemiBold};
    font-size: 13px;

    line-height: 0;
    display: flex;
    gap: 4px;
    align-items: center;

    color: ${colors.$primary700};

    padding: 8px;
    border-radius: 8px;
    background-color: transparent;
    height: 40px;

    cursor: pointer;

    &:hover {
        background-color: rgba(1, 58, 81, 0.05);
    }
`;

const StyledDropdown = styled.div<{ isOpen: boolean; isFirst: boolean }>`
    border-radius: 8px;
    display: ${props => (props.isOpen ? 'flex' : 'none')};

    margin-top: 18px;
    position: absolute;
    overflow: hidden;
    background-color: white;

    ${breakpoint.custom.max('1400')} {
        left: ${props => props.isFirst && 0};
        right: ${props => !props.isFirst && 0};
    }

    box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.1);
`;

const Space = styled.div<{ isActive: boolean }>`
    position: absolute;
    height: 18px;
    width: 20vw;
    background-color: transparent;

    display: ${props => (props.isActive ? 'block' : 'none')};
`;

const Dropdown = ({
    title,
    trackingId,
    isFirstDropdown,
    children,
}: {
    title: string;
    trackingId: string;
    isFirstDropdown?: boolean;
    children: React.ReactNode | React.ReactNode[];
}) => {
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);

    return (
        <div
            onClick={() => setIsDropdownOpen(v => !v)}
            onMouseEnter={() => {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                if (window && window.dataLayer) {
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    window.dataLayer = window.dataLayer || [];
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    window.dataLayer.push({
                        event: 'tracking_event',
                        platform: 'Marketing WebSite',
                        tag_name: trackingId,
                    });
                }
                return setIsDropdownOpen(true);
            }}
            onMouseLeave={() => setIsDropdownOpen(false)}
        >
            <Title>
                {title}
                <img src="/images/header/icon-arrow-down-menu.svg" />
            </Title>
            {/* <Relative> */}
            <Space isActive={isDropdownOpen} />
            <StyledDropdown isOpen={isDropdownOpen} isFirst={isFirstDropdown}>
                {children}
            </StyledDropdown>
            {/* </Relative> */}
        </div>
    );
};

export default Dropdown;
