import * as React from 'react';
import { useMemo } from 'react';

import { readingTimeCalculator } from '../../utils/ReadingTime';
import { TagItem } from '../tag/Tag';
import TagList from '../tag/TagList';
import { PostDatas } from './PostHighlight';

const PostTags = ({
    post,
    className,
    entryTime,
    noDate = false,
}: {
    post: PostDatas;
    className?: string;
    entryTime?: boolean;
    noDate?: boolean;
}) => {
    const content =
        post.content ||
        post.data?.body
            ?.map(slice => slice.primary?.content_text?.richText.map(content => content.text).join(' '))
            .join(' ')
            .trim() ||
        post.data?.body
            ?.map(slice => slice.primary?.content?.richText.map(content => content.text).join(' '))
            .join(' ');

    const readingTime = post.readingTime || (content && readingTimeCalculator(content)) || '5';

    const tagList = useMemo(() => {
        const itemsPrismic: TagItem[] = post.data?.labels.length
            ? post.data.labels
                  .filter(tag => tag.tag?.document)
                  .map(tag => {
                      return {
                          text: tag.tag?.document?.data.name,
                          url: tag.tag?.document?.uri,
                          className: 'tag--gray',
                      };
                  })
            : [];
        return [...itemsPrismic, { text: `${readingTime}min` }];
    }, [post?.data?.labels, readingTime]);
    return (
        <TagList
            noDate={noDate}
            entryTime={entryTime}
            className={className}
            tags={tagList}
            date={post.data?.published_date || post.date}
        />
    );
};

export default PostTags;
