import * as React from 'react';
import { createContext, useContext, useState } from 'react';
import { z } from 'zod';

import { IneligibleReasons, payloadType, Steps } from '../components/meetUs/types/types';

interface StepContextData {
    step: '' | Steps;
    calendlyLink: string;
    setCalendlyLink: (link: string) => void;
    setStep: (step: Steps) => void;
    getCalendlyLink?: (payload?: payloadType | null) => Promise<string | [IneligibleReasons, IneligibleReasons?]>;
    setPayload?: (prevState: payloadType) => void;
    payload: payloadType;
}

export const StepContext = createContext<StepContextData>({
    step: '',
    calendlyLink: '',
    setStep: () => {},
    setCalendlyLink: () => {},
    getCalendlyLink: undefined,
    payload: {} as payloadType,
});

const CalendlyLinkIneligibleErrorSchema = z.object({
    statusCode: z.literal(400),
    error: z.literal('Bad Request'),
    message: z
        .nativeEnum(IneligibleReasons)
        .array()
        .min(1)
        .max(2)
        .transform(data => data as [IneligibleReasons, IneligibleReasons?]),
});

const CalendlyLinkEligibleResponseSchema = z.object({
    url: z.string().url(),
});

const StepContextProvider = ({ children }: { children: React.ReactNode }) => {
    const [payload, setPayload] = useState({} as payloadType);
    const [step, setStep] = useState('' as Steps);
    const [loading, setLoading] = useState(false);
    const [calendlyLink, setCalendlyLink] = useState('');

    const formatServiceOfInterest = data => {
        if (data === 'crea') {
            return ['Pour la création de mon entreprise'];
        }
        if (data === 'compta') {
            return ['Pour la comptabilité de mon entreprise'];
        }
        if (data === 'both') {
            return ['Pour la comptabilité de mon entreprise', 'Pour la création de mon entreprise'];
        }
        return data;
    };

    async function getCalendlyLink(data: payloadType) {
        if (!loading) {
            try {
                setLoading(true);
                const contact = data || payload;
                delete contact.fetch;
                delete contact.client;
                contact.serviceOfInterest = formatServiceOfInterest(contact.serviceOfInterest);

                const response = await fetch('/.netlify/functions/calendly', {
                    method: 'POST',
                    body: JSON.stringify(contact),
                    headers: {
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*',
                    },
                    redirect: 'follow' as RequestRedirect,
                });

                if (response.ok) {
                    const { url } = CalendlyLinkEligibleResponseSchema.parse(await response.json());
                    return url;
                }

                if (response.status === 400) {
                    const { message } = CalendlyLinkIneligibleErrorSchema.parse(await response.json());
                    return message;
                }

                throw new Error('Something went wrong');
            } finally {
                setLoading(false);
            }
        }
    }

    return (
        <StepContext.Provider
            value={{ step, setStep, calendlyLink, setCalendlyLink, getCalendlyLink, setPayload, payload }}
        >
            {children}
        </StepContext.Provider>
    );
};

export default StepContextProvider;

export function useStepContext() {
    return useContext(StepContext);
}
