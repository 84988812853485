import styled from '@emotion/styled';
import { useLocation } from '@reach/router';
import React from 'react';

import { ROUTES } from '../../../../../../constants/const/routes.const';
import { useMobileHeaderContext } from '../../context/mobileHeaderProvider';
import { NavLink, StyledNav } from '../../styles/styles';
import ComptaSubmenu from './ComptaSubmenu';
import CreaSubmenu from './CreaSubmenu';
import RessourcesSubmenu from './RessourcesSubmenu';

const StyledNavLink = styled(NavLink)`
    &:hover {
        background-color: transparent;
    }
`;

const Navbar = () => {
    const currentRoute = useLocation();

    const { setIsMenuOpen, isSubmenuActive, setIsSubmenuActive } = useMobileHeaderContext();

    const handleClick = (uri: string) => {
        if (currentRoute.pathname === uri) {
            setIsMenuOpen(v => !v);

            if (isSubmenuActive) return setIsSubmenuActive(false);
        }
    };
    return (
        <StyledNav>
            <ComptaSubmenu />
            <CreaSubmenu />
            <StyledNavLink
                to={ROUTES.FACTURATION.uri}
                data-ga-send="event,button,click,topbar-invoicing"
                onClick={() => handleClick(ROUTES.FACTURATION.uri)}
            >
                {ROUTES.FACTURATION.name}
            </StyledNavLink>
            <StyledNavLink
                to={ROUTES.PRICES.uri}
                data-ga-send="event,button,click,topbar-pricing"
                onClick={() => handleClick(ROUTES.PRICES.uri)}
            >
                {ROUTES.PRICES.name}
            </StyledNavLink>
            <RessourcesSubmenu />
        </StyledNav>
    );
};

export default Navbar;
