export const HEADER = {
    get IS_BANNER_FIXED() {
        return process.env.GATSBY_IS_GREEN_BANNER_FIXED === 'true' ? true : false;
    },
    HEIGHT: {
        MAIN: 72,
        MINIMAL: 64,
        BANNER: 72,
        MEDIA: 72,
        get MAIN_FIXED() {
            return this.MAIN + this.BANNER;
        },
        get MAIN_MEDIA() {
            return this.MAIN + this.MEDIA;
        },
        get MAIN_FIXED_MEDIA() {
            return this.MAIN + this.BANNER + this.MEDIA;
        },
        get MINIMAL_FIXED() {
            return this.MINIMAL + this.BANNER;
        },
        get MINIMAL_MEDIA() {
            return this.MINIMAL + this.MEDIA;
        },
        get MINIMAL_FIXED_MEDIA() {
            return this.MINIMAL + this.BANNER + this.MEDIA;
        },
    },
} as const;
