import * as React from 'react';
import { createContext, ReactNode, useContext } from 'react';

type TrackingContextType = {
    trackingId: string;
};

const TrackingContext = createContext<TrackingContextType>({
    trackingId: '',
});

export default function TrackingContextProvider({
    context,
    children,
}: {
    context: TrackingContextType;
    children: ReactNode;
}) {
    return (
        <TrackingContext.Provider
            value={{
                ...context,
            }}
        >
            {children}
        </TrackingContext.Provider>
    );
}

export function useTrackingContext() {
    return useContext(TrackingContext);
}
