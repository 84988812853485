export const wording = {
    not_france: {
        link: 'https://chez.dougs.fr/les-recettes-de-l-entrepreneur-region',
        title: "Dougs n'est pas encore disponible dans votre région...",
        firstParagraph:
            "Malheureusement, Dougs ne peut actuellement pas assurer le suivi comptable de votre activité dans les DOM-TOM. Mais rien n'est figé ! Dougs grandit vite et notre plateforme évolue en continu pour accompagner toujours plus de professionnels.",
        lastParagraph:
            'Si vous souhaitez être alerté sur la disponibilité de Dougs dans votre région, vous pouvez vous inscrire ci-dessous.',
        modal: {
            title: "Dougs n'est pas encore<br />disponible dans votre région...",
            firstParagraph:
                "Malheureusement, Dougs ne peut actuellement pas assurer le suivi comptable de votre activité dans les DOM-TOM.<br />Mais rien n'est figé ! Dougs grandit vite et notre plateforme évolue en continu pour accompagner toujours plus de professionnels.",
            lastParagraph:
                'Si vous souhaitez être alerté sur la disponibilité de Dougs dans votre région, vous pouvez vous inscrire ci-dessous.',
        },
    },
    both: {
        link: 'https://chez.dougs.fr/les-recettes-de-l-entrepreneur-gestion',
        title: 'Dougs ne peut pas encore assurer la gestion de votre comptabilité...',
        firstParagraph:
            "Malheureusement, Dougs ne peut actuellement pas assurer le suivi comptable de votre secteur d'activité et n'est pas disponible dans votre région. Mais rien n'est figé ! Dougs grandit vite et notre plateforme évolue en continu pour accompagner toujours plus de professionnels.",
        lastParagraph:
            "Si vous souhaitez être alerté sur la disponibilité de Dougs dans votre région et pour votre secteur d'activité, vous pouvez vous inscrire ci-dessous.",
        modal: {
            title: 'Dougs ne peut pas encore<br />assurer la gestion de votre comptabilité...',
            firstParagraph:
                "Malheureusement, Dougs ne peut actuellement pas assurer le suivi comptable de votre secteur d'activité et n'est pas disponible dans votre région. Mais rien n'est figé ! Dougs grandit vite et notre plateforme évolue en continu pour accompagner toujours plus de professionnels.",
            lastParagraph:
                "Si vous souhaitez être alerté sur la disponibilité de Dougs dans votre région et pour votre secteur d'activité, vous pouvez vous inscrire ci-dessous.",
        },
    },
    bad_activity: {
        link: 'https://chez.dougs.fr/les-recettes-de-l-entrepreneur-activite',
        title: "Dougs n'assure pas encore le suivi de la comptabilité de votre secteur d'activité...",
        firstParagraph:
            "Malheureusement, Dougs ne peut actuellement pas assurer le suivi comptable de votre secteur d'activité. Mais rien n'est figé ! Dougs grandit vite et notre plateforme évolue en continu pour accompagner toujours plus de professionnels.",
        lastParagraph:
            "Si vous souhaitez être alerté sur la disponibilité de Dougs pour votre secteur d'activité, vous pouvez vous inscrire ci-dessous.",
        modal: {
            title: "Dougs n'assure pas encore le suivi<br />de la comptabilité de votre secteur d'activité...",
            firstParagraph:
                "Malheureusement, Dougs ne peut actuellement pas assurer le suivi comptable de votre secteur d'activité.<br />Mais rien n'est figé ! Dougs grandit vite et notre plateforme évolue en continu pour accompagner toujours plus de professionnels.",
            lastParagraph:
                "Si vous souhaitez être alerté sur la disponibilité de Dougs pour votre secteur d'activité, vous pouvez vous inscrire ci-dessous.",
        },
    },
};

export const formData = {
    services: [
        { value: 'crea', label: 'Pour la création de mon entreprise', radioLabel: "Création d'entreprise" },
        { value: 'compta', label: 'Pour la comptabilité de mon entreprise', radioLabel: 'Comptabilité' },
        { value: 'both', label: 'Les deux', radioLabel: 'Les deux' },
    ],

    cities: [
        { value: 'france_metropolitaine', label: 'France Métropolitaine & Corse' },
        { value: 'corse_outre_mer', label: 'Outre mer' },
        { value: 'union_europeenne', label: 'Union Européenne' },
        { value: 'hors_union_europeenne', label: 'Hors Union Européenne' },
    ],

    activities: [
        { value: 'Artisanat_BTP', label: 'Artisanat / BTP' },
        { value: 'Automobile', label: 'Automobile : Achat / Revente / Loueurs' },
        { value: 'VTC', label: 'VTC' },
        { value: 'Autre', label: 'Autre' },
        { value: 'E-commerce', label: 'E-commerce' },
        { value: 'Freelance', label: 'Freelance / Consultant' },
        { value: 'Holding', label: 'Holding' },
        { value: 'Prestation_de_service', label: 'Prestation de service' },
        { value: 'Professions_liberales_medicales', label: 'Professions liberales / medicales' },
        { value: 'Restauration', label: 'Restauration / Commerce de proximité' },
        { value: 'SCI_Immobilière/LMNP', label: 'SCI Immobilière / LMNP' },
        {
            value: "Vente_de_biens_d'occasion_et_marchand_de_biens",
            label: "Vente de biens d'occasion / Marchand de biens",
        },
    ],
};
