import styled from '@emotion/styled';
import { useLocation } from '@reach/router';
import { Link } from 'gatsby';
import React from 'react';

import { colors, fonts } from '../../../../../../../styles/theme';
import { useMobileHeaderContext } from '../../../context/mobileHeaderProvider';

const DropLink = styled(Link)`
    display: flex;
    flex-direction: row;
    width: 100%;

    min-width: 238px;
`;

const AnchorLink = styled.a`
    display: flex;
    flex-direction: row;
    width: 100%;

    min-width: 238px;
`;

const ImageWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    border-radius: 8px;

    img {
        width: 20px;
        height: 20px;
    }
`;

const IconWrapper = styled.div`
    display: flex;
    font-size: 24px;
    font-weight: 400;
    align-items: center;
    justify-content: center;

    border-radius: 8px;
`;

const Title = styled.span`
    font-family: ${fonts.OpenSans_SemiBold};
    font-size: 13px;

    width: 100%;

    padding: 8px;
    border-radius: 0 8px 8px 0;
    height: 40px;

    display: flex;
    align-items: center;

    color: ${colors.$primary700};
`;

const Wrapper = styled.div`
    width: 100%;
`;

const SubmenuLink = ({
    title,
    trackingId,
    icon,
    url,
    variant,
}: {
    title: string;
    trackingId: string;
    icon: string;
    url: string;
    variant: 'primary' | 'secondary';
}) => {
    const currentRoute = useLocation();

    const { setIsMenuOpen, isSubmenuActive, setIsSubmenuActive } = useMobileHeaderContext();

    const handleClick = () => {
        if (currentRoute.pathname === url) {
            setIsMenuOpen(v => !v);

            if (isSubmenuActive) return setIsSubmenuActive(false);
        }
    };

    if (url.charAt(0) !== '/') {
        <Wrapper>
            <AnchorLink href={url} data-ga-send={`event,button,click,${trackingId}`}>
                {variant === 'primary' ? (
                    <ImageWrapper>
                        <img src={`/images/header/icons/farfa-${icon}.svg`} />
                    </ImageWrapper>
                ) : (
                    <IconWrapper>{icon}</IconWrapper>
                )}
                <Title>{title}</Title>
            </AnchorLink>
        </Wrapper>;
    }

    return (
        <Wrapper>
            <DropLink onClick={handleClick} to={url} data-ga-send={`event,button,click,${trackingId}`}>
                {variant === 'primary' ? (
                    <ImageWrapper>
                        <img src={`/images/header/icons/farfa-${icon}.svg`} />
                    </ImageWrapper>
                ) : (
                    <IconWrapper>{icon}</IconWrapper>
                )}
                <Title>{title}</Title>
            </DropLink>
        </Wrapper>
    );
};

export default SubmenuLink;
