export type SeoFragmentFragment = {
    titleText?: string;
    metaDesc?: string;
    metaTitle?: string;
    opengraphTitle?: string;
    opengraphDescription?: string;
    twitterTitle?: string;
    twitterDescription?: string;
    metaRobotsNoindex?: string | number;
};

type PageName =
    | 'index'
    | 'fonctionnalites'
    | 'tarifs'
    | 'creationEntreprise'
    | 'confidentiality'
    | 'media'
    | 'posts'
    | 'api'
    | 'jobs'
    | 'maintenance'
    | 'outilsSimulation'
    | 'equipe'
    | 'presse'
    | 'mentionsLegales'
    | 'cgu'
    | 'expertComptable'
    | 'social'
    | 'juridique'
    | 'performances'
    | 'declarations'
    | 'facturation'
    | 'faq'
    | 'notesDeFrais'
    | 'synchroBancaire'
    | 'freelance'
    | 'ecommerce'
    | 'artisan'
    | 'liberal'
    | 'tpe'
    | 'temoignages'
    | 'temoignageDevFreelance'
    | 'temoignageEcommerce'
    | 'temoignageFreelance'
    | 'temoignageTpeStartup'
    | 'temoignageTpe'
    | 'videos'
    | 'webinars'
    | 'ressources'
    | 'planDuSite'
    | 'creationEntrepriseEnLigne';

export const seoFragments: { readonly [pageName in PageName]: SeoFragmentFragment } = {
    /* Core */
    index: {
        titleText: 'Expert-comptable en ligne pas cher, simple et efficace - Dougs ✅',
        metaDesc:
            'Expert-comptable pas cher : Dougs est le seul expert comptable en ligne 100% sans paperasse. Votre comptabilité en ligne moins chère, à partir de 49€/mois.',
    },
    fonctionnalites: {
        titleText: 'Expert-comptable en ligne avec une application - Essai Gratuit - Dougs',
        metaDesc:
            "Expert-comptable en ligne pour les consultants, les artisans, le e-commerce, les startups, les freelances : le suivi d'un vrai expert-comptable dès 49€/mois",
    },
    faq: {
        titleText: 'Foire aux questions - Expert-comptable en ligne - Dougs',
        metaDesc:
            'Nous repondons à vos questions - Expert-comptable en ligne pour les consultants, les artisans, le e-commerce, les startups, les freelances',
    },
    tarifs: {
        titleText: 'Tarifs expert-comptable en ligne - Dougs, découvrez nos offres',
        metaDesc:
            "Les tarifs détaillés de Dougs, cabinet d'expertise-comptable en ligne. Votre bilan à partir de 49€HT par mois. Conseil inclus !",
    },
    creationEntreprise: {
        titleText: 'Création d’entreprise en ligne : démarrez votre projet en ' + new Date().getFullYear(),
        metaDesc:
            'Créer votre entreprise en seulement 6 étapes avec Dougs ! Notre cabinet d’expertise comptable vous guide pas à pas pour un lancement rapide.',
        opengraphTitle: 'Création d’entreprise en ligne : démarrez votre projet en ' + new Date().getFullYear(),
        twitterTitle: 'Création d’entreprise en ligne : démarrez votre projet en ' + new Date().getFullYear(),
    },
    media: {
        titleText: 'Le blog qui aide les entrepreneurs à réussir - Dougs Compta',
        metaDesc:
            'Que vous souhaitiez devenir entrepreneur ou juste devenir meilleur, le blog de Dougs est là pour vous faciliter la vie et vous aider à réussir.',
    },
    posts: {
        titleText: 'Le blog des entrepreneurs qui réussissent - Dougs Compta',
        metaDesc:
            'Le blog de Dougs écrit pour les entrepreneurs qui souhaitent en savoir plus sur les questions comptables, juridiques, fiscales et sociales liées à leur entreprise.',
    },
    api: {
        titleText: "L'expert-comptable en ligne avec une API - Dougs Compta",
        metaDesc:
            "Startups, développeurs, utilisez l'API REST de Dougs pour interagir avec vos opérations bancaires, votre comptabilité et automatiser votre gestion.",
    },
    jobs: {
        titleText: 'Jobs - Startup comptabilité en ligne Dougs',
        metaDesc:
            'Développeur fullstack ou collaborateur comptable, venez travailler dans notre équipe pluridisciplinaire, jeune et dynamique dans une ambiance startup !',
    },
    maintenance: {
        titleText: 'Maintenance - Dougs Compta',
        metaRobotsNoindex: '1',
    },
    outilsSimulation: {
        titleText: 'Outils de simulation - Dougs Compta',
        metaDesc: 'Découvrez nos outils pour vous aider dans vos décisions',
        metaRobotsNoindex: '1',
    },
    equipe: {
        titleText: "L'equipe, les humains qui font Dougs. Le cabinet, membre inscrit à l'ordre des experts-comptables",
        metaDesc: "L'equipe, les humains qui font Dougs. Le cabinet, membre inscrit à l'ordre des experts-comptables.",
    },
    presse: {
        titleText: 'Relation presse et brand assets',
        metaDesc: "Informations et logos pour communiquer sur Dougs, cabinet d'expertise-comptable en ligne.",
    },
    mentionsLegales: {
        titleText: 'Mentions légales - Dougs Compta',
        metaRobotsNoindex: '1',
    },
    cgu: {
        titleText: "Conditions Générales d'Utilisation - Dougs Compta",
        metaRobotsNoindex: '1',
    },
    confidentiality: {
        titleText: 'Politique de confidentialité - Dougs Compta',
        metaRobotsNoindex: '1',
    },
    planDuSite: {
        titleText: 'Plan du Site - Dougs.fr',
        metaDesc: "Bienvenue sur le plan du site ! Accédez d'un simple clic à toutes les pages du site Dougs.fr ",
    },
    /* Services */
    expertComptable: {
        titleText: "Cabinet d'expert-comptable en ligne. Simple, sans paperasse - Dougs",
        metaDesc:
            "Essayez un cabinet d'expert-comptable en ligne 100% sans paperasse. Les experts-comptables Dougs vous accompagnent et vous conseillent. À partir de 49€/mois.",
    },
    social: {
        titleText: 'Fiches de paie en ligne. Conseils et accompagnement - Dougs',
        metaDesc:
            "Laissez nos juristes en droit social réaliser vos fiches de paie en ligne. Avec des fiches de paie précises et dans les temps, évitez les Prud'Hommes.",
    },
    juridique: {
        titleText: "Conseils juridiques par Dougs, cabinet d'expert-comptable",
        metaDesc:
            "Obtenez les réponses à vos questions par nos conseillers juridiques. Cabinet membre de l'Ordre des experts-comptables. Statut juridique, forme juridique.",
    },

    /* Features */
    performances: {
        titleText: 'Performances en temps réel, tableau de bord de votre entreprise',
        metaDesc:
            'Suivez les performances de votre entreprise en temps réel. Avec vos tableaux de bord toujours à jour, anticipez et prenez les bonnes décisions.',
    },
    declarations: {
        titleText: 'Déclarations fiscales de votre entreprise',
        metaDesc:
            "Ne passez jamais à côté d'une déclaration fiscale. Votre cabinet d'expert-comptable Dougs vous notifie toujours au bon moment.",
    },
    facturation: {
        titleText: 'Logiciel de facturation gratuit, conforme et efficace',
        metaDesc:
            "Choisissez un logiciel de facturation conforme, pratique et efficace. N'oubliez aucune mention légale et évitez les redressements.",
    },
    notesDeFrais: {
        titleText: "Gestion des notes de frais - Dougs cabinet d'expert-comptable",
        metaDesc:
            "Gérez vos notes de frais en quelques clics. Suivez en temps réel votre compte courant d'associé. Prise en compte automatique dans votre comptabilité.",
    },
    synchroBancaire: {
        titleText: "Synchronisation avec votre compte professionnel - Dougs cabinet d'expert-comptable en ligne",
        metaDesc:
            "L'application unique de Dougs, expert-comptable en ligne agréé par la banque de France, se synchronise directement avec votre compte professionnel.",
    },

    /* Persona */
    freelance: {
        titleText: 'Expert-comptable pour freelances et indépendants',
        metaDesc:
            'Gagnez du temps et prenez les bonnes décisions avec Dougs, cabinet expert-comptable en ligne pour freelance et indépendant. Votre bilan pour 79€HT par mois.',
    },
    ecommerce: {
        titleText: 'Expert-comptable spécialiste en e-commerce',
        metaDesc:
            'Gestion de vos comptes bancaires, Paypal, Stripe et commissions Amazon et Shopify. Vendez plus sans payer plus cher de compta. Votre bilan pour 89€HT par mois.',
    },
    artisan: {
        titleText: 'Expert-comptable en ligne pour le BTP et les artisans',
        metaDesc:
            'Passez du temps avec vos client, pas avec votre comptable. Vous savez toujours où vous en êtes, même en déplacement. Votre bilan pour 79€HT par mois.',
    },
    tpe: {
        titleText: 'Expert-comptable en ligne pour TPE',
        metaDesc:
            'Votre bilan et vos déclarations fiscales sans efforts. Vous dirigez votre activité, nous gérons votre compta. Votre bilan pour 79€HT par mois.',
    },
    liberal: {
        titleText: 'Expert-comptable spécialisé en profession libérale en 2024',
        metaDesc:
            'Expert-comptable pour profession libérale : gestion complète de votre comptabilité, conseils, bilan et déclarations fiscales dès 79€ HT par mois.',
    },

    /* Témoignages */
    temoignages: {
        titleText: "Vos avis sur Dougs le cabinet d'expert-comptable en ligne",
        metaDesc:
            'Découvrez les avis et témoignages des entrepreneurs qui font déjà confiance à Dougs, expert-comptable en ligne',
    },
    temoignageDevFreelance: {
        titleText: 'Témoignage de Matthieu, développeur freelance et client Dougs',
        metaDesc:
            '“J’avais besoin d’une solution comptable accessible 24/24 et 7/7, c’est chose faite avec l’expert-comptable en ligne Dougs.”',
    },
    temoignageEcommerce: {
        titleText: 'Témoignage de David, entrepreneur e-commerce et client Dougs',
        metaDesc:
            '“Avec l’expert-comptable en ligne Dougs, je peux gérer tous les moyens de paiement que je propose sur mon site e-commerce et suivre mes chiffres en temps réel.”',
    },
    temoignageFreelance: {
        titleText: 'Témoignage de Florence, Consultante freelance et cliente Dougs',
        metaDesc:
            '“Avec l’expert-comptable en ligne Dougs, tout est simple, rapide et transparent. Une solution sans contrainte, qui me permet de faire ma compta dans le bus.”',
    },
    temoignageTpeStartup: {
        titleText: 'Témoignage de Grégoire, artisan e-commerçant et client Dougs',
        metaDesc:
            'Mes associés ne sont pas dans la même ville que moi. Avec l’expert-comptable en ligne Dougs, c’est simple : on peut tout gérer à distance.”',
    },
    temoignageTpe: {
        titleText: 'Témoignage d’Aurélie et Clément, fondateurs de TPE et clients Dougs',
        metaDesc:
            '“Grâce à l’expert-comptable en ligne Dougs, nous consacrons moins de 2h / mois à notre comptabilité !”',
    },
    videos: {
        titleText: 'Les vidéos pour entrepreneurs qui réussissent by Dougs',
        metaDesc:
            'Tout pour gérer son entreprise et son business en tant qu’entrepreneur. Comptabilité, fiscalité ou encore juridique, nous abordons tous les sujets utiles.',
    },
    webinars: {
        titleText: 'Les webinars pour entrepreneurs qui réussissent by Dougs',
        metaDesc:
            'Tout pour gérer son entreprise et son business en tant qu’entrepreneur. Comptabilité, fiscalité ou encore juridique, nous abordons tous les sujets utiles.',
    },
    ressources: {
        titleText: "Nos ressources pour réussir votre projet d'entreprise",
        metaDesc:
            'Toutes les conseils et outils utiles pour simplifier la gestion de votre entreprise et faire grandir votre business !',
    },
    creationEntrepriseEnLigne: {
        titleText: "Création d'entreprise en ligne",
        metaDesc: `Création de société en ligne, moins cher et plus rapide. Cabinet d'expertise-comptable en ligne, Dougs vous conseille dans la création de votre entreprise.`,
        metaRobotsNoindex: '1',
    },
};
