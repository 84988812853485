import { Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import * as React from 'react';

import { SeoTitle } from '../seo/seoTitle';
import * as styles from './post-thumb.module.scss';
import { PostDatas } from './PostHighlight';
import PostInfos from './PostInfos';
import PostTags from './PostTags';

const PostThumb = ({
    post,
    size,
    isNoFollow = false,
    blue = false,
    index = 1,
    noSeo = false,
}: {
    post: PostDatas; // change to Queries.PrismicPost and Video
    size?: string;
    isNoFollow?: boolean;
    oldImg?: boolean;
    blue?: boolean;
    noSeo?: boolean;
    index?: number;
}) => {
    const propsNoFollow = isNoFollow
        ? {
              rel: 'nofollow',
          }
        : {};
    return (
        <div
            className={`${styles.postThumb} ${blue ? styles.postThumbBlue : ' '} ${size ? 'post-thumb--' + size : ''}`}
        >
            <Link
                to={post.uri}
                {...propsNoFollow}
                className="img-ratio-wrapper"
                data-ga4={`container-miniature${index}-img-topimage-click-link`}
                data-ga-send={`event,button,click,blog-related-article${index}`}
            >
                <GatsbyImage image={post.img} alt={post.title || post.data.titre || ''} className="img-ratio" />
            </Link>
            <div className={styles.postThumbText}>
                <div>
                    <PostTags className="my-1" post={post} />
                    <Link
                        to={post.uri}
                        {...propsNoFollow}
                        data-ga4={`container-miniature${index}-text-toptitle-click-link`}
                        className={styles.postThumbTitle}
                    >
                        <SeoTitle as={noSeo ? 'div' : 'h3'} seoStyle={'u-none'}>
                            {post.title || post.data.titre}
                        </SeoTitle>
                    </Link>
                </div>
                <div>
                    {post.data?.author?.document && (
                        <PostInfos author={post.data.author?.document as Queries.PrismicAuteur} />
                    )}
                    <div className={styles.postThumbFooter}>
                        <Link
                            to={post.uri}
                            data-ga4={`container-miniature${index}-text-read-click-link`}
                            {...propsNoFollow}
                        >
                            Lire l'article
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PostThumb;
