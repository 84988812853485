import { Link } from 'gatsby';
import * as React from 'react';

import * as styles from './tag.module.scss';

export type TagItem = {
    text: string;
    className?: string;
    url?: string;
};

const Tag = ({ text, className, url }: TagItem) => {
    const tagClassName = `${styles.tag} ${className || ''}`;
    return (
        <>
            {url ? (
                <Link
                    to={url || undefined}
                    data-ga-send="event,button,click,blog-tag"
                    data-ga4="container-tag-text-click-link"
                    className={tagClassName}
                >
                    {text}
                </Link>
            ) : (
                <div className={tagClassName}>{text}</div>
            )}
        </>
    );
};

export default Tag;
