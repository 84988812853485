import { graphql } from 'gatsby';
import * as React from 'react';

import styled from '@emotion/styled';
import { breakpoint, colors, fonts } from '../../styles/theme';

interface PostAuthorBlockProps {
    author: Queries.PrismicAuteur;
}

const Avatar = styled.img`
    width: 56px;
    height: 56px;
    border-radius: 32px;

    ${breakpoint.desktop} {
        width: 64px;
        height: 64px;
    }
`;

const AuthorInfo = styled.a`
    display: flex;
    gap: 12px;
    align-items: center;
    margin-bottom: 8px;
`;

const AuthorName = styled.div`
    color: ${colors.$primary700};
    font-family: ${fonts.Eina_SemiBold};
    font-size: 16px;

    ${breakpoint.desktop} {
        font-size: 18px;
    }
`;

const AuthorJob = styled.div`
    color: ${colors.$primary700};
    font-family: ${fonts.OpenSans_Regular};
    font-size: 13px;

    ${breakpoint.desktop} {
        font-size: 15px;
    }
`;

const AuthorDescription = styled.p`
    color: ${colors.$gray};
    font-family: ${fonts.OpenSans_Regular};
    font-size: 13px;
    margin-bottom: 4px;
    line-height: 170%;

    ${breakpoint.desktop} {
        font-size: 15px;
    }
`;

const More = styled.a`
    color: ${colors.$primary};
    font-family: ${fonts.OpenSans_Bold};
    font-size: 12px;

    :hover {
        text-decoration: underline;
    }

    ${breakpoint.desktop} {
        font-size: 13px;
    }
`;

const PostAuthorBlock = ({ author: authorData }: PostAuthorBlockProps) => {
    const author = authorData.data;
    return (
        <div>
            <AuthorInfo href={authorData.uri} data-ga-send="event,button,click,post-author-label">
                <Avatar src={author.avatar.url} alt={author.name} />
                <div>
                    <AuthorName>{author.name}</AuthorName>
                    <AuthorJob>{author.job}</AuthorJob>
                </div>
            </AuthorInfo>
            {author.shortDescription && <AuthorDescription>{author.shortDescription}</AuthorDescription>}
            <More href={authorData.uri} data-ga-send="event,button,click,post-author-more">
                En savoir plus
            </More>
        </div>
    );
};

export default PostAuthorBlock;

export const AuthorPrismic = graphql`
    fragment PostAuthor on PrismicAuteur {
        id
        uri
        _previewable
        data {
            name
            job
            slug
            avatar {
                url
            }
            facebookUrl
            linkedinUrl
            twitterUrl
            shortDescription
            description
        }
    }
`;
