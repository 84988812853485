import styled from '@emotion/styled';
import { Link } from 'gatsby';
import React from 'react';

import { colors, fonts } from '../../../../../../styles/theme';
import DropdownLink from './DropdownLink';

const StyledSection = styled.div<{ variant: 'primary' | 'secondary' }>`
    padding: 32px;
    background-color: ${props => (props.variant === 'primary' ? 'white' : 'rgba(1, 58, 81, 0.05)')};
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;

const Title = styled.p`
    font-family: ${fonts.OpenSans_SemiBold};
    font-size: 13px;
    text-transform: uppercase;

    margin-bottom: 14px;

    color: rgba(1, 58, 81, 0.8);
`;

const StyledDiv = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
`;

const BottomLink = styled(Link)`
    margin-top: 16px;
    font-family: ${fonts.OpenSans_Bold};
    font-size: 13px;
    align-self: flex-end;
    color: ${colors.$primary};

    padding: 8px;
    border-radius: 4px;
    line-height: normal;

    display: flex;
    align-items: center;
    gap: 4px;

    &:hover {
        background: rgba(17, 171, 236, 0.1);
    }
`;

const Divider = styled.hr`
    color: rgba(1, 58, 81, 0.1);
    height: 1px;
    margin: 8px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: stretch;
`;

const DropdownSection = ({
    title,
    variant,
    items,
    bottomLink,
}: {
    title?: string;
    variant: 'primary' | 'secondary';
    items: any;
    bottomLink?: {
        text: string;
        url: string;
        trackingId: string;
    };
}) => {
    return (
        <StyledSection variant={variant}>
            <div>
                {title && <Title>{title}</Title>}
                <StyledDiv>
                    {items.map((item, i) => {
                        if (item.divider) {
                            return <Divider key={i + Math.random()} />;
                        } else {
                            return <DropdownLink variant={variant} key={item.title} {...item} />;
                        }
                    })}
                </StyledDiv>
            </div>

            {bottomLink && (
                <BottomLink to={bottomLink.url} data-ga-send={`event,link,click,${bottomLink.trackingId}`}>
                    {bottomLink.text} <img src="/images/header/icon-arrow-right-menu.svg" />
                </BottomLink>
            )}
        </StyledSection>
    );
};

export default DropdownSection;
