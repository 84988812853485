import { OPERATIONS_BANQUAIRES } from '../components/Builder/sections/SectionSimulator/const';
import { SavingsForm } from '../components/Builder/sections/SectionSimulator/hooks/useSimulatorForm';
import { SIMULATOR_SAVING_CASES } from '../components/Builder/sections/SectionSimulator/type';

const PACK_SERENITE_PRICE = 99;
const PACK_LNMP_PRICE = 29;
const PEOPLE_PRICE = 30;

const getPack = (form: SavingsForm): string => {
    switch (form.type) {
        case 'service':
        case 'produit':
            if (form.ca === 'cat1') return 'SERENITE';
            return 'PME';
        case 'sci':
            return 'LMNP';
        default:
            return '';
    }
};

const getSavingsWithDougs = (form: SavingsForm) => {
    form.lignes = form.lignes.length ? form.lignes : '';
    form.ca = form.type === 'sci' ? 'cat1' : form.ca;

    const pack = getPack(form);

    let savings: number;
    if (form.ca === 'cat1' || form.type === 'sci') {
        savings =
            form.honoraires -
            ((form.type !== 'sci' ? PACK_SERENITE_PRICE : PACK_LNMP_PRICE) * 12 +
                (form.type !== 'sci' ? form.peoples : 0) * PEOPLE_PRICE * 12);
    }
    if (form.ca === 'cat2') {
        savings =
            form.honoraires -
            (OPERATIONS_BANQUAIRES.find(op => op.value === form.lignes).price +
                (form.type !== 'sci' ? form.peoples : 0) * PEOPLE_PRICE) *
                12;
    }

    if (form.ca === 'cat3') return { status: SIMULATOR_SAVING_CASES.CUSTOM, savings: undefined };
    if (savings > 0) return { status: SIMULATOR_SAVING_CASES.OK, form, savings, pack };
    if (savings <= 0) return { status: SIMULATOR_SAVING_CASES.ERROR, form, savings };
};

export default getSavingsWithDougs;
