import { ROUTES } from '../../../../../constants/const/routes.const';

// COMPTA DROPDOWN

export const SERVICES = [
    {
        title: 'Expertise-comptable',
        trackingId: 'topbar-service-expert-comptable',
        icon: 'chart-bar',
        url: '/expert-comptable/',
    },
    {
        title: "Création d'entreprise",
        trackingId: 'topbar-service-creation',
        icon: 'rocket-launch',
        url: '/creation-entreprise/',
    },
    {
        title: 'Fiche de paie et gestion sociale',
        trackingId: 'topbar-service-fiches-paies',
        icon: 'file-user',
        url: '/fiches-de-paie-en-ligne/',
    },
    {
        title: 'Conseil juridique',
        trackingId: 'topbar-service-juridique',
        icon: 'balance-scale',
        url: '/conseil-juridique/',
    },
];

export const FONCTIONNALITES = [
    {
        title: 'Logiciel de facturation',
        trackingId: 'topbar-feature-invoicing',
        icon: 'file-invoice-dollar',
        url: ROUTES.FACTURATION.uri,
    },
    {
        title: 'Bilan et déclarations fiscales',
        trackingId: 'topbar-feature-declarations',
        icon: 'book',
        url: ROUTES.TAX_SUMMARY.uri,
    },
    {
        title: 'Synchronisation bancaire',
        trackingId: 'topbar-feature-synchro-banque',
        icon: 'sync',
        url: ROUTES.BANK_SYNCHRONIZATION.uri,
    },
    {
        title: 'Compte de résultat en temps réel',
        trackingId: 'topbar-feature-performances',
        icon: 'chart-pie',
        url: ROUTES.PERFORMANCES.uri,
    },
    { title: 'Notes de frais', trackingId: 'topbar-feature-note-frais', icon: 'receipt', url: ROUTES.EXPENSE.uri },
];

export const POUR_QUI = [
    {
        title: 'Freelance, indépendant',
        trackingId: 'topbar-persona-freelance',
        icon: '🧑‍💻',
        url: '/expert-comptable-freelance/',
    },
    { title: 'TPE', trackingId: 'topbar-persona-tpe', icon: '🏡', url: '/expert-comptable-tpe/' },
    { title: 'Artisan', trackingId: 'topbar-persona-artisan', icon: '🧰', url: '/expert-comptable-artisan/' },
    { title: 'E-commerce', trackingId: 'topbar-persona-ecommerce', icon: '🛒', url: '/expert-comptable-ecommerce/' },
    {
        title: 'Professionnel libéral',
        trackingId: 'topbar-persona-liberal',
        icon: '🖋️',
        url: '/expert-comptable-liberal/',
    },
];

// RESSOURCES DROPDOWN

export const RESSOURCES = [
    { title: 'Blog', trackingId: 'topbar-ressources-blog', icon: 'file-alt', url: '/blog/' },
    { title: 'Webinars', trackingId: 'topbar-ressources-webinars', icon: 'presentation', url: '/webinars/' },
    {
        title: 'Simulateurs, guides et checklist',
        trackingId: 'topbar-ressources-ressources',
        icon: 'clipboard-check',
        url: '/ressources/',
    },
    {
        title: 'Outils de simulation',
        trackingId: 'topbar-ressources-simulators',
        icon: 'rocket-launch',
        url: '/outils-simulation/',
    },
    { title: 'Vidéos', trackingId: 'topbar-ressources-videos', icon: 'play-circle', url: '/road-to-business' },
    { divider: true },
    { title: 'Nous contacter', trackingId: 'topbar-ressources-contact', icon: 'comment-alt-lines', url: '/contact/' },
];

export const TOP_SIMULATEURS = [
    {
        title: 'Vidéo de démonstration',
        trackingId: 'topbar-ressources-video-demo',
        icon: '🎬',
        url: '/demo',
    },
    {
        title: 'Simulateur SASU vs MICRO',
        trackingId: 'topbar-ressources-sasu-micro',
        icon: '🥊',
        url: 'https://chez.dougs.fr/simulateur-microentreprise-sasu-page?utm_campaign=Page%20Simulateur%20-%20Blog&utm_source=blog&utm_medium=simulateur-page&utm_term=realiser-une-simulation&utm_content=micro-vs-sasu',
    },
    {
        title: 'Simulateur de rémunération',
        trackingId: 'topbar-ressources-remuneration',
        icon: '💰',
        url: 'https://chez.dougs.fr/simulateur-remuneration-dirigeants-page?utm_campaign=Page%20Simulateur%20-%20Blog&utm_source=blog&utm_medium=simulateur-page&utm_term=realiser-une-simulation',
    },
];

// CREA DROPDOWN

export const CREA_NETLINKING_PAGES = [
    {
        title: ROUTES.COMPANY_CREATION.name,
        trackingId: 'topbar-creation',
        icon: 'rocket-launch',
        url: ROUTES.COMPANY_CREATION.uri,
    },
    {
        title: 'Créer votre SASU',
        trackingId: 'topbar-creation-sasu',
        icon: 'building',
        url: '/creer-sasu/',
    },
    {
        title: 'Créer votre SAS',
        trackingId: 'topbar-creation-sas',
        icon: 'building',
        url: '/creer-sas/',
    },
    {
        title: 'Créer votre SARL',
        trackingId: 'topbar-creation-sarl',
        icon: 'building',
        url: '/creer-sarl/',
    },
    {
        title: 'Créer votre EURL',
        trackingId: 'topbar-creation-eurl',
        icon: 'building',
        url: '/creer-eurl/',
    },
    {
        title: 'Créer votre SCI',
        trackingId: 'topbar-creation-sci',
        icon: 'building',
        url: '/creer-sci/',
    },
    {
        title: 'Créer votre holding',
        trackingId: 'topbar-creation-holding',
        icon: 'building',
        url: '/creer-holding/',
    },
];

export const RESSOURCES_CREA = [
    {
        title: 'Simulateur SASU vs MICRO',
        trackingId: 'tobpar-creation-simulateur-sasu-micro',
        icon: '🥊',
        url: 'https://chez.dougs.fr/simulateur-microentreprise-sasu-page?utm_campaign=Page%20Simulateur%20-%20Blog&utm_source=blog&utm_medium=simulateur-page&utm_term=realiser-une-simulation&utm_content=micro-vs-sasu',
    },
    {
        title: 'Simulateur EURL vs MICRO',
        trackingId: 'tobpar-creation-simulateur-eurl-micro',
        icon: '⚔️',
        url: 'https://chez.dougs.fr/simulateur-microentreprise-eurl',
    },
    {
        title: 'Guide comment choisir son statut ?',
        trackingId: 'tobpar-creation-guide-statut',
        icon: '👍',
        url: '/guide/choisir-statut-juridique-creation-entreprise',
    },
    {
        title: 'Blog création entreprise',
        trackingId: 'tobpar-creation-simulateur-blog-creation',
        icon: '📖',
        url: '/blog/tag/creation-entreprise/',
    },
];
