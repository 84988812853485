import * as React from 'react';

import DateTimeDisplay from './DateTimeDisplay';
import { useCountdown } from './hooks/useCountdown';

const ShowCounter = ({
    days,
    hours,
    minutes,
    seconds,
}: {
    days: number;
    hours: number;
    minutes: number;
    seconds: number;
}) => {
    return (
        <div className="show-counter">
            <DateTimeDisplay value={days} type={'Jours'} isDanger={false} />
            <DateTimeDisplay value={hours} type={'Heures'} isDanger={false} />
            <DateTimeDisplay value={minutes} type={'Minutes'} isDanger={false} />
            <DateTimeDisplay value={seconds} type={'Secondes'} isDanger={false} />
        </div>
    );
};

const Countdown = ({ sessions }: { sessions: Queries.WebinarSessions[] }) => {
    const targetDate = Math.min(...sessions.map(session => session.estimated_started_at));
    const [days, hours, minutes, seconds] = useCountdown(targetDate);

    if (days + hours + minutes + seconds <= 0) {
        return <ShowCounter days={0} hours={0} minutes={0} seconds={0} />;
    } else {
        return <ShowCounter days={days} hours={hours} minutes={minutes} seconds={seconds} />;
    }
};

export default Countdown;
