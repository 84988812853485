import * as React from 'react';

import { Banner, Message, MessageDesktop, MessageMobile } from './styles';
import { useHeaderBanner } from './useHeaderBanner';

const HeaderBanner = () => {
    const { message } = useHeaderBanner();

    return (
        <Banner>
            <Message>
                <MessageDesktop>{message.desktop}</MessageDesktop>
                <MessageMobile>{message.mobile}</MessageMobile>
            </Message>
        </Banner>
    );
};

export default HeaderBanner;
