import styled from '@emotion/styled';
import * as React from 'react';
import { useEffect } from 'react';

import { useStepContext } from '../../../contexts/stepContextProvider';
import { mixpanelEvent } from '../../layout/scripts';
import { Button } from '../../UI/Button/legacy/button/Button';

const Wrapper = styled.div`
    text-align: center;
    img {
        width: 142px;
        margin-bottom: 16px;
    }
    @media screen and (max-width: 1024px) {
        padding: 48px;
        img {
            width: 90px;
        }
    }
`;

const Title = styled.div`
    font-size: 40px;
    font-family: Eina-SemiBold, serif;
    @media screen and (max-width: 1024px) {
        font-size: 32px;
    }

    color: white;
`;

const Paragraph = styled.div`
    color: white;
`;

const StepOldModal = () => {
    const { payload } = useStepContext();
    useEffect(() => {
        mixpanelEvent({ params: 'send,event,modal,click,meet-us-already-client'.split(','), options: payload });
    }, [payload]);
    return (
        <Wrapper>
            <img src="/images/blog/illu-26.svg" />
            <Title>Vous êtes déjà client Dougs ?</Title>
            <Paragraph className="mb-4 mt-2">
                Nos équipes comptables, juridiques et fiscales se tiennent à votre disposition pour répondre à vos
                questions ! Vous pouvez les
                <br /> contacter directement en envoyant un message dans le chat
            </Paragraph>
            <Button id="intercom-modal-meet-us" data-ga-send="event,button,click,modal-meet-us-old-chat">
                Accédez au chat
            </Button>
        </Wrapper>
    );
};

export default StepOldModal;
