import * as React from 'react';
import { ElementType } from 'react';

//Ce composant permet d'utiliser le style d'un Hn sans son SEO ou réciproquement
export const SeoTitle = ({
    as: Tag = 'div',
    seoStyle = '',
    children,
    ...props
}: {
    as?: ElementType;
    children?: React.ReactNode;
    seoStyle?: string;
    className?: string;
    dangerouslySetInnerHTML?: { __html: string };
}) => {
    const style = seoStyle + (props.className ? ` ${props.className}` : '');
    return (
        <Tag {...props} className={style.trim()}>
            {children}
        </Tag>
    );
};
