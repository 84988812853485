import { graphql } from 'gatsby';
import { RichText, RichTextBlock } from 'prismic-reactjs';
import * as React from 'react';

export const Quote = ({
    slice,
}: {
    slice: {
        primary: {
            author: string;
            content_text: {
                richText: RichTextBlock[];
            };
        };
    };
}) => {
    return (
        <blockquote className="wysiwyg-blockquote">
            <RichText render={slice.primary.content_text.richText} />
            <cite>{slice.primary.author}</cite>
        </blockquote>
    );
};

export const videoDataQuoteFragment = graphql`
    fragment VideoDataQuote on PrismicVideoDataBodyQuote {
        primary {
            author
            content_text {
                richText
                text
            }
        }
        slice_type
    }
`;

export const postDataQuoteFragment = graphql`
    fragment PostQuote on PrismicPostDataBodyQuote {
        id
        primary {
            content_text {
                richText
            }
            author
        }
        slice_type
    }
`;
