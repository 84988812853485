import * as React from 'react';
import { useCallback, useState } from 'react';

const VatNumber = () => {
    const [error, setError] = useState<string>(null);
    const [vatNumber, setVatNumber] = useState<string>(null);
    const [siren, setSiren] = useState<string>('');

    const changed = useCallback(() => {
        setVatNumber(null);
        setError(null);
    }, []);

    const submit = useCallback(
        e => {
            e.preventDefault();

            function computeVatNumber(siren) {
                siren = ('' + siren).replace(/\s*/g, '');
                if (siren.length !== 9) throw new Error();

                const value = Number(siren);
                if (isNaN(value)) throw new Error();

                const prefix = (12 + 3 * (value % 97)) % 97;
                return 'FR' + prefix.toString().padStart(2, '0') + ' ' + siren;
            }

            setTimeout(function () {
                try {
                    setVatNumber(computeVatNumber(siren));
                    setError(null);
                } catch (e) {
                    setVatNumber(null);
                    setError('Votre numéro de SIREN est invalide.');
                }
            }, 200);
        },
        [siren],
    );

    return (
        <div className="VatNumber">
            <div className="panel panel--primary u-textCenter">
                <form onSubmit={submit}>
                    <p className="u-lineHeight-1-2">
                        <b>
                            Saisissez votre numéro de SIREN à 9 chiffres pour obtenir votre numéro de TVA
                            intra-communautaire gratuit.
                        </b>
                    </p>

                    <div className="Container m-tiny">
                        <div className="InputGroup u-fullWidth">
                            <input
                                type="text"
                                value={siren || ''}
                                name="SIREN"
                                className="radius"
                                placeholder="Numéro de SIREN"
                                onKeyUp={changed}
                                onChange={e => setSiren(e.target.value)}
                                required
                            />
                            <div className="InputGroupAddon">
                                <button
                                    data-ga4="container-body-cta-vatnum-click-link"
                                    type="submit"
                                    className="Button m-small ks-computeVatNumber"
                                >
                                    Obtenir mon n° de TVA
                                </button>
                            </div>
                        </div>
                    </div>
                </form>

                {error && <div className="u-textAlert">{error}</div>}

                {vatNumber && (
                    <div>
                        <div>Votre numéro de TVA</div>
                        <div></div>
                        <b>{vatNumber}</b>
                    </div>
                )}
            </div>
        </div>
    );
};

export default VatNumber;
