import styled from '@emotion/styled';

import { breakpoint, colors } from '../../../../../styles/theme';

const getColor = type => {
    switch (type) {
        case 'white':
            return '#11abec';
        case 'reverse':
            return '#013a51';
        case 'video':
            return 'white';
        default:
            return 'white';
    }
};
const getBackgroundColor = type => {
    switch (type) {
        case 'white':
            return 'white';
        case 'darkBlue':
            return colors.$primary700;
        case 'reverse':
        case 'video':
            return 'transparent';
        default:
            return '#11abec';
    }
};

const getBorderColor = type => {
    switch (type) {
        case 'darkBlue':
        case 'reverse':
            return '#013a51';
        case 'video':
            return 'white';
        default:
            return '#11abec';
    }
};

const getHoverColor = type => {
    switch (type) {
        case 'darkBlue':
            return colors.$primary700;
        case 'white':
            return 'white';
        case 'video':
            return '#0b0a8c';
        default:
            return '#11abec';
    }
};

const getBackgroundHoverColor = type => {
    switch (type) {
        case 'white':
            return '#11abec';
        case 'video':
        default:
            return 'white';
    }
};

export const Button = styled.div<{
    left?: boolean;
    type?: 'video' | 'white' | 'reverse' | 'darkBlue';
    fullWidth?: boolean;
    fullWidthOnMobile?: boolean;
}>`
    width: ${props => (props.fullWidth ? '100%' : 'unset')};
    text-decoration: none;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 1rem 2rem;
    border-radius: 3rem;
    transition-duration: 0.2s;
    transition-timing-function: ease-out;
    color: ${props => getColor(props.type)} !important;

    ${breakpoint['$large-max']} {
        padding: 12px 18px 12px 18px;
    }

    ${breakpoint.custom.max('1259')} {
        width: ${props => (props.fullWidthOnMobile ? '100%' : 'unset')};
    }

    cursor: pointer;
    align-self: ${props => (props.left ? 'flex-start' : 'center')};
    font-family: OpenSans-Bold, Arial, sans-serif;
    background-color: ${props => getBackgroundColor(props.type)};
    font-size: 13px;
    line-height: 1.36;
    border: 1px solid ${props => getBorderColor(props.type)};

    &:hover {
        color: ${props => getHoverColor(props.type)} !important;
        background-color: ${props => getBackgroundHoverColor(props.type)};
    }
`;

export const ButtonSubmit = styled.button<{ left?: boolean; fullWidth?: boolean }>`
    width: ${props => (props.fullWidth ? '100%' : 'unset')};
    text-decoration: none;
    display: inline-flex;
    justify-content: center;
    padding: 1rem 2rem;
    border-radius: 3rem;
    transition-duration: 0.2s;
    transition-timing-function: ease-out;
    color: ${props => (props.disabled ? 'grey' : getColor(props.type))} !important;

    @media screen and (max-width: 1024px) {
        padding: 12px 18px 12px 18px;
    }
    cursor: ${props => (props.disabled ? 'initial' : 'pointer')};
    align-self: ${props => (props.left ? 'flex-start' : 'center')};
    font-family: OpenSans-Bold, Arial, sans-serif;
    background-color: ${props => (props.disabled ? 'white' : getBackgroundColor(props.type))};
    font-size: 13px;
    line-height: 1.36;
    border: 1px solid ${props => (props.disabled ? 'grey' : getBorderColor(props.type))};

    &:hover {
        color: ${props => (props.disabled ? 'grey' : getHoverColor(props.type))} !important;
        background-color: ${props => getBackgroundHoverColor(props.type)};
    }
`;
