import { useLocation } from '@reach/router';
import * as React from 'react';

import FacebookIcon from '../../images/icons/facebook.svg';
import LinkedinIcon from '../../images/icons/linkedin.svg';
import TwitterIcon from '../../images/icons/twitter.svg';

const SocialMediaColumn = () => {
    const location = useLocation();

    return (
        <div className="col-md-1">
            <div className="post__share social-link__wrapper">
                <a
                    target="_blank"
                    rel="noopener"
                    href={`https://www.facebook.com/sharer/sharer.php?u=${location.href}`}
                    className="social-link link-gray-primary"
                    data-ga-send="event,button,click,social-facebook"
                >
                    <FacebookIcon />
                </a>
                <a
                    target="_blank"
                    rel="noopener"
                    href={`https://twitter.com/intent/tweet?url=${location.href}`}
                    className="social-link link-gray-primary"
                    data-ga-send="event,button,click,social-twitter"
                >
                    <TwitterIcon />
                </a>
                <a
                    target="_blank"
                    rel="noopener"
                    href={`https://www.linkedin.com/shareArticle?mini=true&url=${location.href}`}
                    className="social-link link-gray-primary"
                    data-ga-send="event,button,click,social-linkedin"
                >
                    <LinkedinIcon />
                </a>
            </div>
        </div>
    );
};
export default SocialMediaColumn;
