import styled from '@emotion/styled';
import React from 'react';

import { breakpoint, colors, fonts } from '../../../../../../styles/theme';
import Divider from '../../../../../UI/Divider';
import SimulatorInputLabel from '../../../../../UI/SimulatorInputLabel';

interface SelectorProps {
    buttons: { id: string; label: string }[];
    currentValue: string;
    setValue: (value: any) => void;
    label: string;
}

const Container = styled.div`
    width: 100%;
    display: flex;
    border-radius: 8px;
    background: #fff;
    height: 48px;
    padding: 4px;
    align-items: center;
`;

const SelectorItem = styled.div<{ isActive: boolean }>`
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 0 8px;
    gap: 4px;
    height: 40px;
    flex: 1;
    border-radius: 4px;
    background: ${props => (props.isActive ? colors.$orange : 'white')};
    color: ${props => (props.isActive ? 'white' : '#516a87')};

    font-family: ${props => (props.isActive ? fonts.OpenSans_SemiBold : fonts.OpenSans_Regular)};
    font-size: ${props => (props.isActive ? '12.5px' : '13px')};
    line-height: 150%;

    ${breakpoint.laptop} {
        font-size: 14px;
    }

    :hover {
        cursor: pointer;
    }
`;

const Selector = ({ buttons, currentValue, setValue, label }: SelectorProps) => {
    const activeIndex = buttons.findIndex(obj => obj.id === currentValue);
    return (
        <div>
            <SimulatorInputLabel text={label} />
            <Container>
                {buttons.map((item, i, a) => {
                    const isActive = currentValue === item.id;
                    return (
                        <>
                            <SelectorItem isActive={isActive} onClick={() => setValue(item.id)}>
                                {item.label}
                            </SelectorItem>
                            {i + 1 !== a.length && i !== activeIndex - 1 && i !== activeIndex && <Divider isVertical />}
                        </>
                    );
                })}
            </Container>
        </div>
    );
};

export default Selector;
