import styled from '@emotion/styled';

import { HEADER } from '../constants/header.const';

export const Banner = styled.div`
    position: relative !important;
    width: 100%;
    height: ${HEADER.HEIGHT.BANNER}px;
    top: 0;
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #0ed094;
`;

export const Message = styled.div`
    display: block;
    text-align: center;
    color: white;
    font-weight: 600;
    font-size: 14px;

    @media (min-width: 1200px) {
        font-size: 16px;
    }

    a {
        margin-left: 0.5rem;
        padding: 1px 4px;
        border-radius: 2px;
        font-weight: 600;
        text-decoration: underline;
        color: #0b3898;
        background-color: #0b38981f;
    }
`;

export const MessageDesktop = styled.div`
    display: none;

    @media (min-width: 768px) {
        display: block;
    }
`;

export const MessageMobile = styled.div`
    padding: 8px 5px;
    display: block;

    @media (min-width: 768px) {
        display: none;
    }
`;
