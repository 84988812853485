import { useLocation } from '@reach/router';
import React, { ReactNode } from 'react';

import { hasValidReferralCookie, isAReferralUrl } from '../../../../utils/cookies';

export const useHeaderBanner = () => {
    const location = useLocation();

    const isFromReferral = isAReferralUrl(location) || hasValidReferralCookie();

    const message: { desktop: ReactNode; mobile: ReactNode } = {
        desktop: (
            <>
                Augmentez votre rémunération, créez une SASU ou une SCI&hellip; Webinars et conseils gratuits, animés
                par nos experts chaque jour&nbsp;!
                <a
                    href="https://www.dougs.fr/webinars"
                    target="_blank"
                    rel="noopener"
                    data-ga-send="event,link,click,headerbar-webinar"
                    data-ga4="topbar-topbandeau-text-inscriptionwebinar-click-link"
                >
                    Inscriptions ici&nbsp;&rarr;
                </a>
            </>
        ),
        mobile: (
            <>
                Augmentez votre rémunération, créez une SASU&hellip; Webinars gratuits&nbsp;!
                <a
                    href="https://www.dougs.fr/webinars"
                    target="_blank"
                    rel="noopener"
                    data-ga-send="event,link,click,headerbar-webinar"
                >
                    Inscriptions&nbsp;&rarr;
                </a>
            </>
        ),
    };

    if (isFromReferral) {
        message.desktop = (
            <>
                Bonjour, vous êtes parrainé&nbsp;! Vous débloquez 2 mois offerts, sécurisez-les en commençant votre
                essai gratuit de Dougs.
                <span>
                    <a
                        className="headerbar-action"
                        href="https://www.dougs.fr/presignup/"
                        target="_blank"
                        rel="noopener nofollow"
                        data-ga-send="event,link,click,headerbar-referral"
                    >
                        Commencer l'essai&nbsp;&rarr;
                    </a>
                </span>
            </>
        );
        message.mobile = (
            <>
                Parrainé&nbsp;! Vous débloquez 2 mois offerts, sécurisez-les en commençant votre essai.
                <span>
                    <a
                        className="headerbar-action"
                        href="https://www.dougs.fr/presignup/"
                        target="_blank"
                        rel="noopener nofollow"
                        data-ga-send="event,link,click,headerbar-referral"
                    >
                        Essayer&nbsp;&rarr;
                    </a>
                </span>
            </>
        );
    }

    return { message };
};
