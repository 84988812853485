import { createTheme, ThemeProvider } from '@mui/material';
import React, { ReactNode } from 'react';

import { colors } from '../styles/theme';

const theme = createTheme({
    palette: {
        primary: {
            main: colors.$primary700,
        },
    },
});

export const MUIThemeProvider = ({ children }: { children: ReactNode }) => {
    return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};
