export interface TableParsed {
    firstLine: string[];
    body: string[][];
}

export const TableParser = (tableHTML: string): TableParsed => {
    const rowRegex = /<tr>(.*?)<\/tr>/gs;
    const rows = [...tableHTML.matchAll(rowRegex)];

    const extractTable = html => {
        const cellRegex = /<td>(.*?)<\/td>/gs;
        const cells = [...html.matchAll(cellRegex)];

        return cells.map(cell =>
            cell[1]
                .replace(/<[^>]*>/g, '')
                .replace(/&nbsp;/g, ' ')
                .replace(/&#8211;/g, '–')
                .replace(/&#8212;/g, '—')
                .replace(/&#8216;/g, '‘')
                .replace(/&#8217;/g, '’')
                .replace(/&#8220;/g, '“')
                .replace(/&#8221;/g, '”')
                .replace(/&#x274c;/g, '❌')
                .replace(/&#x2705;/g, '✔️')
                .replace(/&rsquo;/g, "'")
                .replace(/&lsquo;/g, "'")
                .replace(/&ldquo;/g, '“')
                .replace(/&rdquo;/g, '”')
                .replace(/&lt;/g, '<')
                .replace(/&gt;/g, '>')
                .replace(/&amp;/g, '&')
                .replace(/&quot;/g, '"')
                .replace(/&copy;/g, '©')
                .replace(/&reg;/g, '®')
                .replace(/&trade;/g, '™')
                .replace(/&euro;/g, '€')
                .replace(/&pound;/g, '£')
                .replace(/&yen;/g, '¥')
                .replace(/&cent;/g, '¢')
                .replace(/&deg;/g, '°')
                .replace(/&hellip;/g, '…')
                .replace(/&times;/g, '×')
                .replace(/&divide;/g, '÷')
                .replace(/&sect;/g, '§')
                .replace(/&bull;/g, '•')
                .replace(/&middot;/g, '·')
                .replace(/&para;/g, '¶')
                .replace(/&shy;/g, '')
                .replace(/&iexcl;/g, '¡')
                .replace(/&iquest;/g, '¿')
                .replace(/&aacute;/g, 'á')
                .replace(/&eacute;/g, 'é')
                .replace(/&iacute;/g, 'í')
                .replace(/&oacute;/g, 'ó')
                .replace(/&uacute;/g, 'ú')
                .replace(/&ntilde;/g, 'ñ')
                .replace(/&uuml;/g, 'ü')
                .replace(/&Aacute;/g, 'Á')
                .replace(/&Eacute;/g, 'É')
                .replace(/&Iacute;/g, 'Í')
                .replace(/&Oacute;/g, 'Ó')
                .replace(/&Uacute;/g, 'Ú')
                .replace(/&Ntilde;/g, 'Ñ')
                .replace(/&Uuml;/g, 'Ü')
                .replace(/&agrave;/g, 'à')
                .replace(/&egrave;/g, 'è')
                .replace(/&igrave;/g, 'ì')
                .replace(/&ograve;/g, 'ò')
                .replace(/&ugrave;/g, 'ù')
                .replace(/&ccedil;/g, 'ç')
                .replace(/&eacute;/g, 'é')
                .replace(/&ecirc;/g, 'ê')
                .replace(/&euml;/g, 'ë')
                .replace(/&ocirc;/g, 'ô')
                .replace(/&ucirc;/g, 'û')
                .replace(/&icirc;/g, 'î')
                .replace(/&Agrave;/g, 'À')
                .replace(/&Egrave;/g, 'È')
                .replace(/&Igrave;/g, 'Ì')
                .replace(/&Ograve;/g, 'Ò')
                .replace(/&Ugrave;/g, 'Ù')
                .replace(/&Ccedil;/g, 'Ç')
                .replace(/&Eacute;/g, 'É')
                .replace(/&Ecirc;/g, 'Ê')
                .replace(/&Euml;/g, 'Ë')
                .replace(/&Ocirc;/g, 'Ô')
                .replace(/&Ucirc;/g, 'Û')
                .replace(/&Icirc;/g, 'Î')
                .trim(),
        );
    };

    const firstLine = extractTable(rows[0][1]);

    const body = rows.slice(1).map(row => extractTable(row[1]));

    const jsonTable = {
        firstLine: firstLine,
        body: body,
    };

    return jsonTable;
};
