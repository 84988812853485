import React from 'react';
import { RightContainerCustomMade, TextResponse, TitleResponse } from '../../styles';
import ArrowButton from '../../../../../UI/Button/ArrowButton';
import MeetUsModal from '../../../../../meetUs/Modal/MeetUsModal';
import useModal from '../../../../../../hooks/useModal';

const CustomMadeContent = () => {
    const { isModalOpen, openModal, closeModal } = useModal();
    return (
        <RightContainerCustomMade>
            <MeetUsModal isOpen={isModalOpen} close={closeModal} />
            <TitleResponse>Prenez rendez-vous avez notre équipe pour un devis personnalisé</TitleResponse>
            <TextResponse>
                Votre profil nécessite une analyse plus approfondie de vos besoins, afin de vous proposer une estimation
                sur mesure.
            </TextResponse>
            <ArrowButton
                actionType="MODAL"
                color="ORANGE"
                onClick={openModal}
                trackingId="calculateur-prendre-rendez-vous"
            >
                Prendre rendez-vous
            </ArrowButton>
        </RightContainerCustomMade>
    );
};

export default CustomMadeContent;
