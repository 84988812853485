import { graphql } from 'gatsby';
import * as React from 'react';

import { Guides } from './slices/Guides';
import { Highlight } from './slices/Highlight';
import { Videos } from './slices/Videos';
import { Webinars } from './slices/Webinars';

export const SliceZoneTag = ({ sliceZone }: { sliceZone: readonly Queries.PrismicTagDataBody[] }) => {
    const sliceComponents = {
        webinars: Webinars,
        mise_en_avant: Highlight,
        guides: Guides,
        videos: Videos,
    };
    const sliceZoneContent = sliceZone.map((slice, index) => {
        const SliceComponent = sliceComponents[slice.slice_type];
        let buttonNumber = 0;
        if (SliceComponent) {
            buttonNumber++;
            return <SliceComponent slices={sliceZone} position={buttonNumber} slice={slice} key={`slice-${index}`} />;
        }
        return null;
    });

    return <div>{sliceZoneContent}</div>;
};

export const tagDataguidesFragment = graphql`
    fragment TagGuides on PrismicTagDataBodyGuides {
        id
        slice_type
        items {
            guide {
                document {
                    ... on PrismicGuide {
                        data {
                            slug
                            cover {
                                gatsbyImageData
                                alt
                            }
                            ribbon
                        }
                    }
                }
            }
        }
    }
`;

export const tagDatavideosFragment = graphql`
    fragment TagVideos on PrismicTagDataBodyVideos {
        id
        slice_type
        items {
            video {
                document {
                    ... on PrismicVideo {
                        id
                        first_publication_date
                        uid
                        data {
                            title
                            image_legend {
                                richText
                            }
                            image_description {
                                richText
                            }
                            author {
                                document {
                                    ...PostAuthor
                                }
                            }
                            body {
                                ... on PrismicVideoDataBodyNormalText {
                                    primary {
                                        content_text {
                                            text
                                        }
                                    }
                                }
                            }
                            image {
                                gatsbyImageData(width: 420, placeholder: BLURRED, layout: CONSTRAINED)
                                alt
                            }
                        }
                    }
                }
            }
        }
    }
`;

export const tagDataWordpressContentFragment = graphql`
    fragment TagMiseEnAvant on PrismicTagDataBodyMiseEnAvant {
        id
        slice_type
        items {
            cta_link1
            cta_text1
            image {
                gatsbyImageData
            }
            text
            title
            type
            internal_content_link {
                document {
                    ... on PrismicChecklist {
                        data {
                            form_hubspot_id
                            cta_text
                            title
                            description {
                                text
                            }
                            keyword
                        }
                    }
                }
            }
        }
    }
`;

export const tagWebinarFragment = graphql`
    fragment TagWebinars on PrismicTagDataBodyWebinars {
        id
        slice_type
        primary {
            keyword
        }
    }
`;
