import styled from '@emotion/styled';
import React, { ReactNode } from 'react';

import { useHeaderContext } from '../../../../contexts/headerContextProvider';
import { breakpoint } from '../../../../styles/theme';

type DefaultTopPaddingType = {
    desktop?: number;
    tablet?: number;
    mobile?: number;
};

const DEFAULT_TOP_PADDING = {
    desktop: 60,
    tablet: 24,
    mobile: 12,
} as const;

const Padding = styled.div<{ headerHeight: number; padding: DefaultTopPaddingType }>`
    padding-top: ${props => props.headerHeight + props.padding.mobile}px !important;

    ${breakpoint.tablet} {
        padding-top: ${props => props.headerHeight + props.padding.tablet}px !important;
    }

    ${breakpoint.desktop} {
        padding-top: ${props => props.headerHeight + props.padding.desktop}px !important;
    }
`;

const TopPaddingBasedOnHeader = ({
    padding = DEFAULT_TOP_PADDING,
    children,
    as,
    ...args
}: {
    padding?: DefaultTopPaddingType;
    children?: ReactNode;
    as?: 'div' | 'section';
    [key: string]: unknown;
}) => {
    const { stickyHeader } = useHeaderContext();

    const paddingWithProps = {
        ...DEFAULT_TOP_PADDING,
        ...padding,
    };

    return (
        <Padding headerHeight={stickyHeader.height} padding={paddingWithProps} as={as} {...args}>
            {children}
        </Padding>
    );
};

export default TopPaddingBasedOnHeader;
