import styled from '@emotion/styled';
import * as React from 'react';
import { ReactNode, useEffect, useRef } from 'react';

import CloseIcon from './../../images/icons/close.svg';
import * as styles from './modal.module.scss';

const Close = styled.img`
    width: 24px;
`;

const Modal = ({
    isOpen,
    close,
    children,
    size,
    title,
    adblockModal = false,
    className,
}: {
    isOpen: boolean;
    close: () => void;
    children: ReactNode;
    size?: string;
    title?: string;
    className?: string;
    adblockModal?: boolean;
}) => {
    const useCloseModal = (ref, close) =>
        useEffect(() => {
            const handler = e => {
                if (e.which === 27) close();
            };
            const handleClickOutside = () => {
                if (ref.current && !ref.current.contains(event.target)) {
                    close();
                }
            };
            document.addEventListener('keyup', handler);
            document.addEventListener('click', handleClickOutside, true);
            return () => document.removeEventListener('keyup', handler);
        }, [ref, close]);

    const wrapperRef = useRef(null);
    useCloseModal(wrapperRef, close);
    if (adblockModal) {
        return (
            <div
                data-testid="modal"
                className={`${styles.modal} ${size || ''} ${isOpen ? 'open' : 'closed'} ${className}`}
            >
                <div
                    data-testid="adblock"
                    ref={wrapperRef}
                    className={`${styles.modalContent} no-padding ${size || ''}`}
                >
                    <div className={styles.modalTitle}>
                        <div>{title}</div>
                        <button data-testid="close-button" className={styles.modalClose} onClick={close}>
                            <CloseIcon />
                        </button>
                    </div>
                    <div className={styles.modalBody}>{children}</div>
                </div>
            </div>
        );
    }
    return (
        <div data-testid="modal" className={`${styles.modal} ${size || ''} ${isOpen ? 'open' : 'closed'} ${className}`}>
            <div ref={wrapperRef} className={`${styles.modalContent} ${size || ''}`}>
                <button data-testid="close-button" className={styles.modalClose} onClick={close}>
                    <Close src="/images/icons/fa-times.svg" />
                </button>
                {children}
            </div>
        </div>
    );
};

export default Modal;
