import { useRef, useState } from 'react';

import { useIsomorphicLayoutEffect } from '../../../../hooks/useIsomorphicLayoutEffect';

export type StickyHeaderReturnType = {
    hasReachedScrollBreakpoint: boolean;
    ref: React.RefObject<HTMLDivElement>;
};

const useStickyHeader = (): StickyHeaderReturnType => {
    const [hasReachedScrollBreakpoint, setHasReachedScrollBreakpoint] = useState(false);

    const stickyHeaderRef = useRef<HTMLDivElement>();

    useIsomorphicLayoutEffect(() => {
        const fixedTop = stickyHeaderRef.current?.offsetTop;
        const fixedHeader = () => {
            if (window.scrollY > fixedTop) {
                setHasReachedScrollBreakpoint(true);
            } else {
                setHasReachedScrollBreakpoint(false);
            }
        };
        window.addEventListener('scroll', fixedHeader);
    }, []);

    return {
        hasReachedScrollBreakpoint,
        ref: stickyHeaderRef,
    };
};

export default useStickyHeader;
