import styled from '@emotion/styled';
import * as React from 'react';

import { breakpoint, colors, fonts } from '../../styles/theme';
import MeetUsForm from './Form';

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 32px;
    padding: 64px;
    @media screen and (max-width: 1024px) {
        gap: 0px;
        flex-direction: column;
        padding: 24px;
    }
`;

const TopSection = styled.section`
    display: flex;
    gap: 28px;
    align-items: end;

    ${breakpoint['$large-max']} {
        align-items: start;
        flex-direction: column;
        gap: 8px;
        margin-bottom: 16px;
    }
`;

const FormSection = styled.div`
    background-color: white;
    border-radius: 16px;
`;

const Image = styled.img`
    max-width: 148px;

    ${breakpoint['$large-max']} {
        max-width: 98px;
    }
`;

const Text = styled.div`
    h2 {
        font-size: 24px;
        font-family: Eina-SemiBold, serif;
        ${breakpoint['$large-max']} {
            font-size: 20px;
        }
        color: ${colors.$primary700};
    }

    p {
        font-size: 15px;
        font-weight: 400;
        line-height: 21px;
        font-family: ${fonts.OpenSans_Regular};
        color: ${colors.$primary700};

        ${breakpoint['$large-max']} {
            font-size: 13px;
        }
    }
`;

const Step1 = () => {
    return (
        <Wrapper>
            <TopSection>
                <Image src="/images/meetUs/illu-rdv.svg" alt="Illustration rendez-vous" />
                <Text>
                    <h2>Prendre rendez-vous</h2>
                    <p>Nous avons besoin de quelques informations pour préparer au mieux votre rendez-vous.</p>
                </Text>
            </TopSection>
            <FormSection>
                <MeetUsForm />
            </FormSection>
        </Wrapper>
    );
};

export default Step1;
