import * as React from 'react';

const DateTimeDisplay = ({ value, type, isDanger }: { value: number; type: string; isDanger: boolean }) => {
    return (
        <div className={isDanger ? 'countdown danger' : 'countdown'}>
            <p>{('0' + value).slice(-2)}</p>
            <span>{type}</span>
        </div>
    );
};

export default DateTimeDisplay;
