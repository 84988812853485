import { Link } from 'gatsby';
import * as React from 'react';

import { SeoTitle } from './seo/seoTitle';

const TagItem = (props: { tag: Queries.PrismicTag }) => {
    return (
        <Link data-testid="title-tag-link" className="u-flex pb-2" to={props.tag.uri}>
            <img src="/images/blog/Ova.svg" />
            <div className="pl-1 u-link-hover">{props.tag.data.name}</div>
        </Link>
    );
};

const MoreTags = (props: { tags: Queries.PrismicTag[]; level: number }) => {
    const { tags } = props;

    return (
        <section className="section m-margin section--lightDark">
            <div className="u-pattern left pr-2">
                <img src="/images/blog/Patterns.svg" alt="" />
            </div>
            <div className="row p-7">
                <div className="offset-md-1 col-md-4 u-align-center">
                    <SeoTitle data-testid="title-tag" seoStyle="h2" className="u-high-z-index u-white mb-0 pb-2">
                        Explorez
                        <br />
                        d’autres sujets
                    </SeoTitle>
                </div>
                <div className="col-md-7 u-high-z-index">
                    <div className="row">
                        <div className="col-md-6">
                            {tags.slice(0, 3).map((tag, index) => (
                                <TagItem
                                    data-ga4={`container-level${props.level}-text-title${index + 1}-click-link`}
                                    tag={tag}
                                    key={tag.data.slug}
                                />
                            ))}
                        </div>
                        <div className="col-md-6">
                            {tags.slice(3, 6).map((tag, index) => (
                                <TagItem
                                    data-ga4={`container-level${props.level}-text-title${index + 4}-click-link`}
                                    tag={tag}
                                    key={tag.data.slug}
                                />
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};
export default MoreTags;
