import '../../styles/style.scss';

import { useLocation } from '@reach/router';
import { graphql, useStaticQuery } from 'gatsby';
import * as React from 'react';
import { ReactNode, useEffect, useRef } from 'react';
import { Helmet } from 'react-helmet';

import HeaderContextProvider from '../../contexts/headerContextProvider';
import RootContextProvider from '../../contexts/RootContextProvider';
import TrackingContextProvider from '../../contexts/trackingContextProvider';
import { SeoFragmentFragment } from '../../seo';
import { getCanonicalUrl } from '../../utils/GetCanonicalUrl';
import Footer from './footer/Footer';
import HeaderConstructor from './header/HeaderConstructor';
import scripts from './scripts';

const windowLocation = typeof window === 'undefined' ? null : window.location;

const Layout = ({
    children,
    title,
    author,
    seo,
    hasHeaderMedia = false,
    nolayout = false,
    hasHeaderBanner = true,
    isMinimal = false,
    forceHeaderWhiteBackground = false,
    trackingId,
}: {
    children: ReactNode;
    title?: string;
    author?: any;
    seo?: SeoFragmentFragment;
    hasHeaderMedia?: boolean;
    nolayout?: boolean;
    hasHeaderBanner?: boolean;
    isMinimal?: boolean;
    forceHeaderWhiteBackground?: boolean;
    trackingId?: string;
}) => {
    title = seo?.titleText || title;

    const location = useLocation();
    const noLayout = useRef(windowLocation?.search.includes('nolayout'));
    useEffect(() => {
        const promise = scripts();
        return () => promise.then(unsubscribers => unsubscribers?.forEach(fn => fn?.()));
    }, [location.pathname]);

    useEffect(() => {
        noLayout.current = windowLocation?.search.includes('nolayout');
    }, []);

    const noLayoutRef = noLayout.current || nolayout;

    const imageSocialShareUrl = '/images/dougs-social-share.jpg';
    const data = useStaticQuery(graphql`
        query LayoutQuery {
            site {
                siteMetadata {
                    siteUrl
                }
            }
        }
    `);

    const defaultImageUrl = data.site.siteMetadata.siteUrl + imageSocialShareUrl;

    return (
        <RootContextProvider>
            <Helmet htmlAttributes={{ lang: 'fr' }}>
                <meta charSet="utf-8" />
                <link
                    rel="canonical"
                    href={getCanonicalUrl(location.pathname)}
                    data-baseprotocol="https:"
                    data-basehost="www.dougs.fr"
                />
                {title && <title>{title}</title>}
                <meta name="description" content={seo?.metaDesc} />

                {seo?.metaRobotsNoindex && <meta name="robots" content="noindex" />}
                {seo?.metaRobotsNoindex && <meta name="googlebot" content="noindex" />}

                <meta property="og:title" content={seo?.opengraphTitle || title} />
                <meta property="og:description" content={seo?.opengraphDescription || seo?.metaDesc} />
                <meta property="og:type" content="website" />
                <meta property="og:image" content={defaultImageUrl} />

                <meta name="twitter:title" content={seo?.twitterTitle || title} />
                <meta name="twitter:description" content={seo?.twitterDescription || seo?.metaDesc} />
                <meta name="twitter:creator" content={author?.name || 'Dougs'} />
                <meta name="twitter:card" content="summary" />
                <meta name="twitter:image" content={defaultImageUrl} />

                <meta name="HandheldFriendly" content="True" />
                <meta name="MobileOptimized" content="320" />
                <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=5, user-scalable=1" />
            </Helmet>
            {/* ATTENTION: We need to wrap children in this provider as well to have the 
            global property stickyHeader.height. We'll use it to calculate the top of sticky elements.  */}
            <TrackingContextProvider
                context={{
                    trackingId: trackingId || '',
                }}
            >
                {!noLayoutRef && (
                    <>
                        <HeaderContextProvider
                            context={{
                                hasHeaderMedia,
                                isMinimal,
                                bannerOptions: {
                                    isVisible: hasHeaderBanner,
                                },
                                forceWhiteBackground: forceHeaderWhiteBackground,
                            }}
                        >
                            <HeaderConstructor />
                            {children}
                        </HeaderContextProvider>
                        <Footer isMinimal={isMinimal} />
                    </>
                )}
                {noLayoutRef && children}
            </TrackingContextProvider>
        </RootContextProvider>
    );
};

export default Layout;
