import { useEffect, useState } from 'react';

export default () => {
    const [isScrolled, setIsScrolled] = useState(false);

    useEffect(() => {
        const listener = () => setIsScrolled(window.scrollY >= 1);
        window.addEventListener('scroll', listener, { passive: true });
        listener();
        return () => window.removeEventListener('scroll', listener);
    }, []);

    return isScrolled;
};
