import { useState } from 'react';

const defaultValues = {
    type: 'service',
    ca: 'cat1',
    honoraires: 0,
    lignes: '',
    peoples: 0,
};

export interface SavingsForm {
    type: string;
    ca: string;
    honoraires: number;
    lignes: string;
    peoples: number;
}

const useSimulatorForm = (): {
    form: SavingsForm;
    setSimulatorForm: (updates: Partial<Record<string, string | number>>) => void;
} => {
    const [form, setForm] = useState(defaultValues);

    const setSimulatorForm = (updates: Partial<Record<string, string | number>>) => {
        setForm({ ...form, ...updates });
    };

    return { form, setSimulatorForm };
};

export default useSimulatorForm;
