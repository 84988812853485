import styled from '@emotion/styled';
import * as React from 'react';

import { useHeaderContext } from '../../../contexts/headerContextProvider';
import { HEADER } from './constants/header.const';
import HeaderBanner from './HeaderBanner/HeaderBanner';
import { MobileHeaderContextProvider } from './HeaderMain/context/mobileHeaderProvider';
import HeaderDesktop from './HeaderMain/HeaderDesktop';
import HeaderMinimal from './HeaderMain/HeaderMinimal';
import HeaderMobile from './HeaderMain/HeaderMobile';
import HeaderMedia from './HeaderMedia/HeaderMedia';

const FullHeader = styled.div<{ isScrolled: boolean; headerHeight: number; isBannerFixed: boolean }>`
    display: block;
    position: ${props => (props.isBannerFixed ? 'fixed' : props.isScrolled ? 'fixed' : 'relative')};
    top: 0px;
    z-index: 100;
    width: 100%;
    margin-bottom: ${props => `-${props.headerHeight}px`};
    box-shadow: ${props => (props.isScrolled ? '0px 0px 16px 0px rgba(0, 0, 0, 0.10)' : 'inherit')};
`;

const HeaderConstructor = () => {
    const { bannerOptions, isMinimal, stickyHeader, hasHeaderMedia } = useHeaderContext();

    return (
        <>
            {bannerOptions.isVisible && !HEADER.IS_BANNER_FIXED && <HeaderBanner />}
            <FullHeader
                ref={stickyHeader.ref}
                isScrolled={stickyHeader.hasReachedScrollBreakpoint}
                headerHeight={stickyHeader.height}
                isBannerFixed={HEADER.IS_BANNER_FIXED}
            >
                {bannerOptions.isVisible && HEADER.IS_BANNER_FIXED && <HeaderBanner />}
                {isMinimal && <HeaderMinimal />}

                {!isMinimal && (
                    <>
                        <HeaderDesktop />
                        <MobileHeaderContextProvider>
                            <HeaderMobile />
                        </MobileHeaderContextProvider>
                    </>
                )}
                {hasHeaderMedia && <HeaderMedia />}
            </FullHeader>
        </>
    );
};

export default HeaderConstructor;
