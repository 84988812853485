import React from 'react';

import { RESSOURCES, TOP_SIMULATEURS } from '../constants/data.const';
import Dropdown from './Dropdown/Dropdown';
import DropdownSection from './Dropdown/DropdownSection';

const RessourcesDropdown = () => {
    return (
        <Dropdown title={'Ressources'} trackingId="topbar-bar1-text-title1-hover-dropdownlist">
            <DropdownSection title="Ressources" variant="primary" items={RESSOURCES} />

            <DropdownSection
                title="Top ressources"
                variant="secondary"
                items={TOP_SIMULATEURS}
                bottomLink={{
                    text: 'Voir tous les simulateurs',
                    url: '/ressources/',
                    trackingId: 'topbar-ressources-ressources',
                }}
            />
        </Dropdown>
    );
};

export default RessourcesDropdown;
