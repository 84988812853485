export const capitalize = (s: string): string => s[0].toUpperCase() + s.slice(1);
export const waitFor = async (ms: number): Promise<NodeJS.Timeout> => await new Promise(r => setTimeout(r, ms));
export const isBrowser = typeof window !== 'undefined';

export const fetchAndRetry = (url: string, options = {}, retries = 0): Promise<any> =>
    fetch(url, options).then(async res => {
        if (res.ok) {
            return res.json();
        }
        if (res.status === 429 && retries > 0) {
            await waitFor(300);
            return fetchAndRetry(url, options, retries - 1);
        }
        if (!res.ok) {
            return res.json().then(res => {
                throw new Error(res.errors[0]?.title || 'Une erreur est survenue, veuillez réessayer');
            });
        }
    });

export const findHashtags = searchText => {
    const regexp = /\B\#([A-Za-zÀ-ÿ]+)/g;
    const result = [...searchText.matchAll(regexp)];
    if (result) {
        return result.map(m => m[1]);
    } else {
        return [];
    }
};
