import { graphql, Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import * as React from 'react';

import { SeoTitle } from '../seo/seoTitle';

const GuideThumb = ({
    guide,
    className,
    size,
    withSeoTitles = true,
    index = 1,
}: {
    guide: Queries.PrismicGuide;
    className?: string;
    size?: 'small';
    withSeoTitles?: boolean;
    index?: number;
}) => {
    return (
        <Link
            className={`resource-thumb ${className || ''} ${size ? 'resource-thumb--' + size : ''}`}
            to={'/guide/' + guide.data.slug + '/'}
            data-ga4={`container-guide${index}-img-topimage-click-link`}
            data-ga-send={'event,button,click,blog-' + guide.data.slug}
        >
            <div className="img-ratio-wrapper img-ratio-wrapper--vertical">
                <GatsbyImage
                    loading="lazy"
                    image={guide.data.cover.gatsbyImageData}
                    className="img-ratio"
                    alt={guide.data.cover.alt || ''}
                />
            </div>
            <div className="resource-thumb__text">
                <SeoTitle
                    as={withSeoTitles ? 'h4' : 'div'}
                    seoStyle={!withSeoTitles ? 'h4' : ''}
                    className="resource-thumb__title"
                >
                    {guide.data.ribbon}
                </SeoTitle>
                <div className="resource-thumb__footer">Voir le guide</div>
            </div>
        </Link>
    );
};

export default GuideThumb;

export const query = graphql`
    fragment GuideItem on PrismicGuideData {
        slug
        cover {
            gatsbyImageData
            alt
        }
        ribbon
    }
`;
