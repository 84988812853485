import { graphql } from 'gatsby';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';
import * as React from 'react';
import { ReactElement } from 'react';
import { Helmet } from 'react-helmet';

import { PrismicAuteur, PrismicPost, PrismicPostData, PrismicVideo } from '../../graphql-types';
import TopPaddingBasedOnHeader from '../components/layout/header/utils/TopPaddingBasedOnHeader';
import Layout from '../components/layout/Layout';
import GuideBanner from '../components/media/GuideBanner';
import PostAside from '../components/media/PostAside';
import PostInfos from '../components/media/PostInfos';
import PostTags from '../components/media/PostTags';
import SocialMediaColumn from '../components/media/SocialMediaColumn';
import Breadcrumb from '../components/navigation/Breadcrumb';
import { SliceZone } from '../components/prismic/SliceZoneLegacy';
import SectionMoreVideos from '../components/section/SectionMoreVideos';
import { Route, ROUTES } from '../constants/const/routes.const';

const Video = ({
    data,
}: {
    data: {
        prismicVideo: PrismicVideo;
    };
}): ReactElement => {
    const video: PrismicVideo = {
        ...data.prismicVideo,
        data: {
            ...data.prismicVideo.data,
            labels: [
                {
                    tag: { document: { uri: '/videos/', data: { name: 'Vidéos' } } } as never,
                },
            ],
        },
    };
    const breadcrumb: Route[] = [ROUTES.HOME, ROUTES.VIDEOS];
    const asidePosts = video.data.related_posts
        .filter(post => post.post?.document)
        .map(post => {
            const relatedPost = post.post?.document.data as PrismicPostData;
            return {
                title: relatedPost.titre,
                uri: (post.post?.document as PrismicPost).uri,
                data: relatedPost,
            };
        });

    const structuredData = {
        '@context': 'https://schema.org',
        '@type': 'VideoObject',
        mainEntityOfPage: {
            '@type': 'WebPage',
            '@id': `https://dougs.fr/videos/${video.uid}`,
        },
        name: video.data.title,
        description: video.data.body[0].primary.content_text.text.slice(0, 500),
        thumbnailUrl: [video.data.embed_video.thumbnail_url],
        uploadDate: video.first_publication_date,
        embedUrl: video.data.embed_video.embed_url,
    };

    return (
        <Layout
            hasHeaderMedia
            seo={{ titleText: video.data.title + ' - Dougs Compta', metaDesc: video.data.description }}
        >
            {video.data.embed_video && (
                <Helmet>
                    <script type="application/ld+json">{JSON.stringify(structuredData)}</script>
                    <meta property="og:image" content={video.data.embed_video.thumbnail_url} />
                    <meta name="twitter:image" content={video.data.embed_video.thumbnail_url} />
                </Helmet>
            )}
            <main>
                <article data-post-id={video.id}>
                    <TopPaddingBasedOnHeader padding={{ desktop: 0, tablet: 0, mobile: 0 }} />

                    <div className="container">{breadcrumb && <Breadcrumb items={breadcrumb} />}</div>
                    <header>
                        <div className="container">
                            <div className="row row--center">
                                <div className="col-md-8">
                                    <h1 className="u-textCenter mt-6" itemProp="headline">
                                        {video.data.title}
                                    </h1>
                                </div>
                            </div>
                            <div className="row row--center">
                                <div className="col-md-10">
                                    <div className="video-ratio-wrapper">
                                        <div dangerouslySetInnerHTML={{ __html: video.data.embed_video.html }} />
                                    </div>
                                </div>
                            </div>
                            <div className="row row--center">
                                <div className="col-md-8">
                                    <div className="post__info">
                                        <PostInfos entryTime author={video.data.author?.document as PrismicAuteur} />
                                        <div className="post__info__right">
                                            <PostTags
                                                date={video.first_publication_date}
                                                entryTime
                                                post={{
                                                    ...video,
                                                    uri: `${ROUTES.VIDEOS.uri + video.uid}/`,
                                                    author: { node: video.data.author?.document?.data },
                                                    title: video.data.title,
                                                    date: video.first_publication_date,
                                                    content: video.data?.body
                                                        ?.map(slice =>
                                                            slice.primary?.content_text?.richText
                                                                .map(raw => raw.text)
                                                                .join(' '),
                                                        )
                                                        .join(' '),
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </header>

                    <div className="post__body container">
                        <div className="row">
                            <div className="col-md-3">
                                {asidePosts.length > 0 && (
                                    <PostAside category={{ name: 'Articles en lien' }} posts={asidePosts} />
                                )}
                            </div>

                            <div className="col-md-8">
                                <section itemProp="articleBody">
                                    <div className={'wysiwyg-wrapper post__content'}>
                                        <SliceZone sliceZone={video.data.body} noChecklist />
                                    </div>
                                </section>
                            </div>
                            <SocialMediaColumn />
                        </div>
                    </div>
                </article>
                <div className="post__bottom">
                    <GuideBanner withSeoTitles={false} />
                    <SectionMoreVideos />
                </div>
            </main>
        </Layout>
    );
};

export default withPrismicPreview(Video);

export const videoQuery = graphql`
    query ($id: String!) {
        prismicVideo(id: { eq: $id }) {
            id
            uid
            _previewable
            data {
                title
                description
                author {
                    document {
                        ...PostAuthor
                    }
                }
                embed_video {
                    html
                    embed_url
                    thumbnail_url
                }
                body {
                    ...VideoDataText
                    ...VideoDataQuote
                    ...VideoDataOverline
                    ...VideoDataButton
                    ...VideoDataCard
                    ...VideoDataLine
                }
                related_posts {
                    post {
                        document {
                            ...PostPrismic
                        }
                    }
                }
            }
            first_publication_date
            last_publication_date
        }
    }
`;
