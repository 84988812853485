import { graphql } from 'gatsby';
import { RichText, RichTextBlock } from 'prismic-reactjs';
import * as React from 'react';

import useRedirect from '../../../hooks/useRedirect';
import ArrowButton from '../../UI/Button/ArrowButton';
import styled from '@emotion/styled';
import { breakpoint, colors, fonts } from '../../../styles/theme';
import { checkRichText } from '../utils';

const UTMLINK = {
    'https://chez.dougs.fr/simulateur-remuneration/':
        'https://chez.dougs.fr/simulateur-remuneration?utm_campaign=Contenu P. BLOG&utm_source=ctablog&utm_medium=contenu-premium&utm_term=simulateur&utm_content=remuneration',
    'https://chez.dougs.fr/simulateurs-tva/':
        'https://chez.dougs.fr/simulateurs-tva?utm_campaign=Contenu P. BLOG&utm_source=ctablog&utm_medium=contenu-premium&utm_term=simulateur&utm_content=tva-detaille',
    'https://chez.dougs.fr/modele-previsionnel/':
        'https://chez.dougs.fr/modele-previsionnel?utm_campaign=Contenu P. BLOG&utm_source=ctablog&utm_medium=contenu-premium&utm_term=previ&utm_content=previsionnel-dougs-x-shine',
    'https://chez.dougs.fr/calendrier-fiscal/':
        'https://chez.dougs.fr/calendrier-fiscal?utm_campaign=Contenu P. BLOG&utm_source=ctablog&utm_medium=contenu-premium&utm_term=simulateur&utm_content=calendrier fiscal',
    'https://chez.dougs.fr/simulateur-cotisations-independants/':
        'https://chez.dougs.fr/simulateur-cotisations-independants?utm_campaign=Contenu P. BLOG&utm_source=ctablog&utm_medium=contenu-premium&utm_term=simulateur&utm_content=cotisations sociales',
    'https://chez.dougs.fr/simulateur-tva-e-commerce/':
        'https://chez.dougs.fr/simulateur-tva-e-commerce?utm_campaign=Contenu P. BLOG&utm_source=ctablog&utm_medium=contenu-premium&utm_term=simulateur&utm_content=tva-e-commerce',
    'https://chez.dougs.fr/simulateur-taxe-puma/':
        'https://chez.dougs.fr/simulateur-taxe-puma?utm_campaign=Contenu P. BLOG&utm_source=ctablog&utm_medium=contenu-premium&utm_term=simulateur&utm_content=taxte-puma',
    'https://chez.dougs.fr/checklist-remuneration-eurl-sarl/':
        'https://chez.dougs.fr/checklist-remuneration-eurl-sarl?utm_campaign=Contenu P. BLOG&utm_source=ctablog&utm_medium=contenu-premium&utm_term=checklist&utm_content=remuneration-sarl-eurl',
    'https://chez.dougs.fr/checklist-remuneration/':
        'https://chez.dougs.fr/checklist-remuneration?utm_campaign=Contenu P. BLOG&utm_source=ctablog&utm_medium=contenu-premium&utm_term=checklist&utm_content=remuneration-sas-sasu',
    'https://chez.dougs.fr/checklist-10-essentiels/':
        'https://chez.dougs.fr/checklist-10-essentiels?utm_campaign=Contenu P. BLOG&utm_source=ctablog&utm_medium=contenu-premium&utm_term=checklist&utm_content=10essentiels',
    'https://chez.dougs.fr/capital-social/':
        'https://chez.dougs.fr/capital-social?utm_campaign=Contenu P. BLOG&utm_source=ctablog&utm_medium=contenu-premium&utm_term=checklist&utm_content=capital-social',
    'https://chez.dougs.fr/checklist-tvs-dougs/':
        'https://chez.dougs.fr/checklist-tvs-dougs?utm_campaign=Contenu P. BLOG&utm_source=ctablog&utm_medium=contenu-premium&utm_term=checklist&utm_content=tvs',
    'https://chez.dougs.fr/checklist-tresorerie/':
        'https://chez.dougs.fr/checklist-tresorerie?utm_campaign=Contenu P. BLOG&utm_source=ctablog&utm_medium=contenu-premium&utm_term=checklist&utm_content=tresorerie',
    'https://www.dougs.fr/guide/guide-creation-entreprise/':
        'https://www.dougs.fr/guide/guide-creation-entreprise/?utm_campaign=Contenu P. BLOG&utm_source=ctablog&utm_medium=contenu-premium&utm_term=guide&utm_content=creation-entreprise',
    'https://www.dougs.fr/guide/guide-pour-tout-savoir-sur-la-facturation/':
        'https://www.dougs.fr/guide/guide-pour-tout-savoir-sur-la-facturation/?utm_campaign=Contenu P. BLOG&utm_source=ctablog&utm_medium=contenu-premium&utm_term=guide&utm_content=facturation',
    'https://chez.dougs.fr/modele-facture-avoir/':
        'https://chez.dougs.fr/modele-facture-avoir?utm_campaign=Contenu P. BLOG&utm_source=ctablog&utm_medium=contenu-premium&utm_term=modele&utm_content=facture-avoir',
    'https://chez.dougs.fr/modele-facture/':
        'https://chez.dougs.fr/modele-facture?utm_campaign=Contenu P. BLOG&utm_source=ctablog&utm_medium=contenu-premium&utm_term=modele&utm_content=facture',
    'https://www.dougs.fr/guide/guide-des-charges-deductibles/':
        'https://www.dougs.fr/guide/guide-des-charges-deductibles?utm_campaign=Contenu P. BLOG&utm_source=ctablog&utm_medium=contenu-premium&utm_term=guide&utm_content=depenses-deductibles',
    'https://www.dougs.fr/guide/createur-dentreprise-bien-gerer-ses-premiers-papiers/':
        'https://www.dougs.fr/guide/createur-dentreprise-bien-gerer-ses-premiers-papiers?utm_campaign=Contenu P. BLOG&utm_source=ctablog&utm_medium=contenu-premium&utm_term=guide&utm_content=premiers-papiers',
    'https://www.dougs.fr/guide/entrepreneurs-bien-gerer-ses-documents-recurrents/':
        'https://www.dougs.fr/guide/entrepreneurs-bien-gerer-ses-documents-recurrents?utm_campaign=Contenu P. BLOG&utm_source=ctablog&utm_medium=contenu-premium&utm_term=guide&utm_content=papiers-recurrents',
    'https://www.dougs.fr/guide/choisir-statut-juridique-creation-entreprise/':
        'https://www.dougs.fr/guide/choisir-statut-juridique-creation-entreprise?utm_campaign=Contenu P. BLOG&utm_source=ctablog&utm_medium=contenu-premium&utm_term=guide&utm_content=statuts-juridiques',
};
export const addUtm = link => {
    return UTMLINK[link] || link;
};

const getTrackingId = (url: string): string => {
    const trackingSlug = url.split('/')[3];

    if (url.startsWith('https://app.dougs.fr')) {
        return `app-signup`;
    }

    return `resource-${trackingSlug}`;
};
const htmlSerializer = (type, element, content, children) => {
    if (type === 'hyperlink') {
        const { getUrl } = useRedirect(element.data.url);
        const url = addUtm(getUrl());

        const trackingId = getTrackingId(url);

        return (
            <ArrowButton
                actionType="INTERNAL_LINK"
                trackingId={`blog-${trackingId}`}
                linkOptions={{
                    href: url,
                }}
                isWrap
            >
                {children}
            </ArrowButton>
        );
    }
};

const Container = styled.div`
    display: flex;
    padding: 30px;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    border-radius: 16px;
    background: ${colors.$primary200};

    ${breakpoint.laptop} {
        padding: 40px;
    }

    p {
        font-size: 18px;
        margin-bottom: 0;
        font-family: ${fonts.Eina_SemiBold};
        color: ${colors.$primary700};
        line-height: 125%;

        ${breakpoint.laptop} {
            font-size: 24px;
        }
    }
`;

export const Card = ({
    slice,
}: {
    slice: {
        primary: {
            content_text: {
                richText: RichTextBlock[];
            };
        };
    };
}) => {
    return (
        <Container>
            <RichText render={checkRichText(slice.primary.content_text.richText)} htmlSerializer={htmlSerializer} />
        </Container>
    );
};

export const query = graphql`
    fragment VideoDataCard on PrismicVideoDataBodyCard {
        primary {
            content_text {
                richText
                text
            }
        }
        slice_type
    }
`;
