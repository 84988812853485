import styled from '@emotion/styled';
import { Link } from 'gatsby';
import React from 'react';

import { useHeaderContext } from '../../../../contexts/headerContextProvider';
import { breakpoint, colors, fonts } from '../../../../styles/theme';
import { NavButton } from '../../../UI/Button/NavButton';
import Navbar from './Navbar/Navbar';
import { Header, StyledAnchor, StyledLink } from './styles/styles';

const StyledHeader = styled(Header)`
    justify-content: space-between;
    height: 72px;
    padding: 0 16px;
`;

const LeftSection = styled.div`
    display: flex;
    gap: 8px;
`;

const StyledLogo = styled.img`
    width: 100px;
    height: 26px;
`;

const RightSection = styled.div`
    display: flex;
    gap: 8px;
    align-items: center;
`;

const Telephone = styled(StyledAnchor)`
    font-family: ${fonts.OpenSans_Bold};
    font-size: 13px;
    color: ${colors.$primary700};

    display: none;
    align-items: center;

    img {
        width: 12px;
        margin-right: 6px;
        align-self: center;
    }

    @media only screen and (min-width: 1246px) {
        display: flex;
    }
`;

const TelephoneMobile = styled.a`
    font-family: ${fonts.OpenSans_Bold};
    font-size: 13px;
    color: ${colors.$primary700};

    width: 40px;
    height: 40px;

    display: none;
    align-items: center;
    justify-content: center;

    img {
        width: 16px;
        align-self: center;
    }

    padding: 8px;
    border-radius: 50%;
    background-color: transparent;
    height: 40px;

    &:hover {
        background-color: rgba(1, 58, 81, 0.05);
    }

    @media only screen and (min-width: 1150px) and (max-width: 1245px) {
        display: flex;
    }
`;

const HideIfMobile = styled.div`
    ${breakpoint.custom.max('1161')} {
        display: none;
    }
`;

const HeaderDesktop = () => {
    const { stickyHeader, hasHeaderMedia, forceWhiteBackground } = useHeaderContext();

    return (
        <HideIfMobile>
            <StyledHeader
                isScrolled={stickyHeader.hasReachedScrollBreakpoint}
                forceWhiteBackground={forceWhiteBackground}
                hasHeaderMedia={hasHeaderMedia}
            >
                <LeftSection>
                    <StyledLink
                        to={'/'}
                        className="logo"
                        title="Expert comptable en ligne"
                        data-ga-send="event,link,click,topbar-logo"
                        data-ga4="topbar-bar1-img-logodougs-click-link"
                    >
                        <StyledLogo src="/images/header/logo-dougs.svg" alt="Dougs expert comptable en ligne" />
                    </StyledLink>

                    <Navbar />
                </LeftSection>

                <RightSection>
                    <Telephone href="tel:0428296262" data-ga-send="event,link,click,topbar-phone">
                        <img src="/images/header/icn-tel.svg" alt="Telephone" />
                        <span>04 28 29 62 62</span>
                    </Telephone>

                    <TelephoneMobile href="tel:0428296262" data-ga-send="event,link,click,topbar-phone">
                        <img src="/images/header/icn-tel.svg" alt="Telephone" />
                    </TelephoneMobile>

                    <a
                        href="https://app.dougs.fr"
                        data-ga-send="event,button,click,topbar-login"
                        data-ga4="topbar-bar1-cta-text-click-link"
                        rel="nofollow"
                    >
                        <NavButton variant="secondary">Se connecter</NavButton>
                    </a>

                    <Link
                        to="/presignup"
                        target="_blank"
                        rel="noopener nofollow"
                        data-ga-send="event,button,click,topbar-signup"
                        data-ga4="topbar-bar1-cta-button1-click-link"
                    >
                        <NavButton variant="primary">S'inscrire</NavButton>
                    </Link>
                </RightSection>
            </StyledHeader>
        </HideIfMobile>
    );
};

export default HeaderDesktop;
