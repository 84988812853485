import { useState, useEffect } from 'react';

const useScrollToIds = (ids: string[]): string => {
    const [activeId, setActiveId] = useState(null);

    useEffect(() => {
        const handleScroll = () => {
            for (let id of ids) {
                const element = document.getElementById(id);
                if (element) {
                    const rect = element.getBoundingClientRect();
                    const isInViewport = rect.top >= 0 && rect.bottom <= window.innerHeight;

                    if (isInViewport) {
                        setActiveId(id);
                        return;
                    }
                }
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [ids]);

    return activeId;
};

export default useScrollToIds;
