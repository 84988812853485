import { graphql } from 'gatsby';
import { RichText, RichTextBlock } from 'prismic-reactjs';
import * as React from 'react';

export const Overline = ({
    slice,
}: {
    slice: {
        primary: {
            content_text: {
                richText: RichTextBlock[];
            };
        };
    };
}) => {
    return (
        <div className="wysiwyg-overline">
            <span>
                <RichText render={slice.primary.content_text.richText} />
            </span>
        </div>
    );
};

export const query = graphql`
    fragment VideoDataOverline on PrismicVideoDataBodyOverline {
        primary {
            content_text {
                richText
                text
            }
        }
        slice_type
    }
`;
