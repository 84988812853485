import React from 'react';

import Step1Modal from '../Modal/step1Modal';
import StepCalendlyModal from '../Modal/stepCalendlyModal';
import StepNotEligibleModal from '../Modal/stepNotEligibleModal';
import StepOldModal from '../Modal/stepOldModal';
import Step1 from '../step1';
import StepCalendly from '../stepCalendly';
import StepNotEligible from '../stepNotEligible';
import StepOld from '../stepOld';
import { Steps } from '../types/types';

export const getStep = (step: Steps, isModal = false) => {
    switch (step) {
        case Steps.NotFrance:
        case Steps.BadActivity:
        case Steps.Both:
            return isModal ? <StepNotEligibleModal reason={step} /> : <StepNotEligible reason={step} />;
        case Steps.OldClient:
            return isModal ? <StepOldModal /> : <StepOld />;
        case Steps.Calendly:
            return isModal ? <StepCalendlyModal /> : <StepCalendly />;
        default:
            return isModal ? <Step1Modal /> : <Step1 />;
    }
};
