import React, { createContext, ReactNode, useContext, useState } from 'react';

type MobileHeaderContextTypes = {
    isMenuOpen: boolean;
    setIsMenuOpen: React.Dispatch<React.SetStateAction<boolean>>;
    isSubmenuActive: boolean;
    setIsSubmenuActive: React.Dispatch<React.SetStateAction<boolean>>;
};

const MobileHeaderContext = createContext<MobileHeaderContextTypes>({
    isMenuOpen: null,
    setIsMenuOpen: () => {
        return;
    },
    isSubmenuActive: null,
    setIsSubmenuActive: () => {
        return;
    },
});

export const MobileHeaderContextProvider = ({ children }: { children: ReactNode }) => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [isSubmenuActive, setIsSubmenuActive] = useState<boolean>(false);

    return (
        <MobileHeaderContext.Provider value={{ isMenuOpen, setIsMenuOpen, isSubmenuActive, setIsSubmenuActive }}>
            {children}
        </MobileHeaderContext.Provider>
    );
};

export function useMobileHeaderContext() {
    return useContext(MobileHeaderContext);
}
