import { Link } from 'gatsby';
import { DomElement, domToReact } from 'html-react-parser';
import * as React from 'react';
import { useMemo } from 'react';

import DownloadChecklist from '../components/media/DownloadChecklist';
import WysiwygOverline from '../components/media/WysiwygOverline';
import WysiwygPanel from '../components/media/WysiwygPanel';
import VatNumber from '../components/VatNumer';
import { parser } from '../utils/HtmlReactParser';
import useRedirect from './useRedirect';

const replacements = {
    'vat-number': () => <VatNumber />,
    'wp-block-preformatted': children => <WysiwygOverline>{children}</WysiwygOverline>,
    card: children => <WysiwygPanel> {children} </WysiwygPanel>,
};

export default (content: string) => {
    if (!content) {
        return null;
    }
    const wordPressUrl = '//content.dougs.fr/';

    const uploadsUrl = '//content.dougs.fr/wp-content/uploads/';
    const dougsUrl = '//www.dougs.fr/';

    return useMemo(() => {
        let hCounter = 0;
        const parserOptions = {
            replace: (domNode: DomElement) => {
                if (
                    domNode.name === 'a' &&
                    domNode.attribs.href &&
                    domNode.attribs.href.includes(wordPressUrl) &&
                    !domNode.attribs.href.includes(uploadsUrl)
                ) {
                    const uri = '/' + domNode.attribs.href.replace(/https?:/, '').replace(wordPressUrl, '');
                    const { getUrl } = useRedirect(uri);
                    const attributes = domNode.attribs;
                    delete attributes.href;
                    delete attributes.class;
                    delete attributes.style;
                    return (
                        <Link to={getUrl()} className={domNode.attribs.class} {...attributes}>
                            {domToReact(domNode.children, parserOptions)}
                        </Link>
                    );
                }
                if (domNode.name === 'a' && domNode.attribs.href && domNode.attribs.href.includes(dougsUrl)) {
                    const uri = '/' + domNode.attribs.href.replace(/https?:/, '').replace(dougsUrl, '');
                    const attributes = domNode.attribs;
                    const { getUrl } = useRedirect(uri);
                    delete attributes.href;
                    delete attributes.class;
                    delete attributes.style;
                    return (
                        <a
                            target="_blank"
                            rel="noopener"
                            href={getUrl()}
                            className={domNode.attribs.class}
                            {...attributes}
                        >
                            {domToReact(domNode.children, parserOptions)}
                        </a>
                    );
                }

                if (domNode.name === 'iframe' && domNode.attribs.src.includes('www.youtube.com/embed')) {
                    const thumbnail = domNode.attribs.src.split('/').pop();
                    return (
                        <div itemProp="video" itemScope itemType="https://schema.org/VideoObject">
                            <meta itemProp="embedURL" content={domNode.attribs.src} />
                            <meta
                                itemProp="thumbnailUrl"
                                content={'https://img.youtube.com/vi/' + thumbnail + '/0.jpg'}
                            />
                            <meta itemProp="uploadDate" content={'13/04/2021'} />
                            <meta
                                itemProp="name"
                                content={domNode.attribs.title || 'Dougs - Expert-comptable en ligne'}
                            />
                            <meta
                                itemProp="description"
                                content={
                                    domNode.attribs.title ||
                                    'Des vidéos simples pour vous accompagner au quotidien sur votre comptabilité en ligne, la création ou la gestion de votre entreprise.'
                                }
                            />
                            <iframe {...domNode.attribs} />
                        </div>
                    );
                }

                if (replacements[domNode.name]) {
                    return replacements[domNode.name](domToReact(domNode.children, parserOptions));
                }

                const classNames = domNode.attribs?.['class'];
                if (classNames) {
                    for (const className of classNames.split(' ')) {
                        if (replacements[className]) {
                            return replacements[className](domToReact(domNode.children, parserOptions));
                        }
                    }
                }

                if (domNode.name === 'h2') {
                    ++hCounter;
                    domNode.attribs.class = ['u-scroll-margin-top'];
                    if (hCounter === 3) {
                        return (
                            <>
                                <DownloadChecklist></DownloadChecklist>
                                <h2 id={domNode.attribs?.id} className="u-scroll-margin-top">
                                    {domToReact(domNode.children, parserOptions)}
                                </h2>
                            </>
                        );
                    }
                }

                return domNode;
            },
        };
        return parser(content, parserOptions);
    }, [content]);
};
