import { graphql, useStaticQuery } from 'gatsby';

export default (url: string) => {
    const normalizeUrl = url => {
        url = (url || '').trim().replace('https://www.dougs.fr', '');
        if (!/^https?:\/\//.test(url) && !url.startsWith('/')) url = '/' + url;
        if (!url.includes('#') && !url.includes('?') && !url.endsWith('/')) url += '/';
        return url;
    };

    const data = useStaticQuery(query);

    const getUrl = () => {
        const normalizedUrl = normalizeUrl(url);
        const redirectedUrl = data.allRedirects.nodes[0].data.redirects
            .filter(redirect => redirect.type === 301)
            .find(redirect => {
                const origin = normalizeUrl(redirect.origin);
                return origin === url || origin === normalizedUrl;
            });

        return redirectedUrl ? normalizeUrl(redirectedUrl.url) : normalizedUrl;
    };

    return {
        getUrl,
    };
};

const query = graphql`
    {
        allRedirects: allPrismicWebsiteconfig {
            nodes {
                id
                data {
                    redirects {
                        url
                        origin
                        type
                    }
                }
            }
        }
    }
`;
