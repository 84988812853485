import styled from '@emotion/styled';
import * as React from 'react';
import { useEffect } from 'react';

import useBreakpoints from '../../../hooks/useBreakpoints';
import useIsScrolled from '../../../hooks/useIsScrolled';
import { breakpoint } from '../../../styles/theme';
import * as styles from './sticky-footer.module.scss';

export type ctaTagsType = { tags: string[]; url: string; cta_text: { html: string } };

const StickyFooterWrapper = styled.div`
    ${breakpoint['$medium-min']} {
        display: none !important;
    }
`;

const StickyFooter = ({ ctaTag }: { ctaTag?: ctaTagsType }) => {
    const isScrolled = useIsScrolled();
    const isSmall = useBreakpoints(['small']);

    useEffect(() => {
        if (isSmall) {
            if (isScrolled && ctaTag) {
                document.getElementsByClassName('intercom-launcher-frame')[0]?.classList.add('u-translate-up');
                document.getElementsByClassName('intercom-launcher')[0]?.classList.add('u-translate-up');
            } else {
                document.getElementsByClassName('intercom-launcher-frame')[0]?.classList.remove('u-translate-up');
                document.getElementsByClassName('intercom-launcher')[0]?.classList.remove('u-translate-up');
            }
        }
    }, [isScrolled, ctaTag, isSmall]);
    if (!ctaTag) return null;
    return (
        <StickyFooterWrapper>
            <a
                className={styles.sticky + (!isScrolled ? ' scrolled' : '')}
                data-ga-send={`event,button,click,cta-footer-${ctaTag.tags[0]}`}
                href={ctaTag.url}
                dangerouslySetInnerHTML={{ __html: ctaTag.cta_text.html }}
            />
        </StickyFooterWrapper>
    );
};

export default StickyFooter;
