import React from 'react';

import { CREA_NETLINKING_PAGES, RESSOURCES_CREA } from '../constants/data.const';
import Dropdown from './Dropdown/Dropdown';
import DropdownSection from './Dropdown/DropdownSection';

const CreaDropdown = () => {
    return (
        <Dropdown title={"Création d'entreprise"} trackingId="topbar-creation-hover">
            <DropdownSection variant="primary" items={CREA_NETLINKING_PAGES} />

            <DropdownSection title="RESSOURCES" variant="secondary" items={RESSOURCES_CREA} />
        </Dropdown>
    );
};

export default CreaDropdown;
