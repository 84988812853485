import React from 'react';
import { DisplayPriceContainer, SavingsDisplay, PriceText, StyledImage } from '../styles';
import { IGatsbyImageData } from 'gatsby-plugin-image';

interface DisplayResultPriceProps {
    image: IGatsbyImageData;
    savings?: number;
    titleParams: any;
}

const DisplayResultPrice = ({ image, savings, titleParams }: DisplayResultPriceProps) => {
    return (
        <DisplayPriceContainer>
            <StyledImage image={image} alt="service" />
            {titleParams.displayPrice && (
                <SavingsDisplay>
                    {savings > 0 ? savings : 0} €<span>*</span>
                </SavingsDisplay>
            )}
            <PriceText fontSize={titleParams.fontSize}>{titleParams.label}</PriceText>
        </DisplayPriceContainer>
    );
};

export default DisplayResultPrice;
