export type Route = { uri: string; name: string };

export const ROUTES = {
    HOME: {
        uri: '/',
        name: 'Accueil',
    },
    MEDIA: {
        uri: '/blog/',
        name: 'Blog',
    },
    VIDEOS: {
        uri: '/videos/',
        name: 'Vidéos',
    },
    BLOG: {
        uri: '/blog/posts/',
        name: 'Blog',
    },
    GUIDES: {
        uri: '/guides/',
        name: 'Guides',
    },
    CUSTOMER_CASES: {
        uri: '/cas-client/',
        name: 'Cas client',
    },
    SIMULATORS: {
        uri: '/simulateurs/',
        name: 'Simulateurs',
    },
    FEATURES: {
        uri: '/fonctionnalites/',
        name: 'Fonctionnalités',
    },
    TAX_SUMMARY: {
        uri: '/bilan-liasse-fiscale/',
        name: 'Bilan et déclarations fiscales',
    },
    EXPENSE: {
        uri: '/note-de-frais/',
        name: 'Notes de frais',
    },
    PERFORMANCES: {
        uri: '/performances-en-temps-reel/',
        name: 'Performances en temps réel',
    },
    BANK_SYNCHRONIZATION: {
        uri: '/synchronisation-bancaire/',
        name: 'Synchronisation bancaire',
    },
    PRICES: {
        uri: '/tarifs/',
        name: 'Tarifs',
    },
    COMPANY_CREATION: {
        uri: '/creation-entreprise/',
        name: 'Créer votre entreprise',
    },
    FACTURATION: {
        uri: '/logiciel-facturation-gratuit/',
        name: 'Logiciel de facturation',
    },
    WEBINAR: {
        uri: '/webinars/',
        name: 'Webinars',
    },
    PARTNER_CONTENT: {
        uri: '/blog/tag/contenus-partenaires/',
        name: 'Contenus partenaires',
    },
};
