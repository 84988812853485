exports.linkResolver = doc => {
    switch (doc.type) {
        case 'post': {
            return `/blog/${doc.uid}/`;
        }
        case 'video': {
            return `/videos/${doc.uid}/`;
        }
        case 'tag': {
            return `/blog/tag/${doc.slug}/`;
        }
        case 'auteur': {
            return `/blog/author/${doc.slug}/`;
        }
        case 'guide': {
            return `/guide/${doc.slug}/`;
        }
        default:
            return '/';
    }
};
