import * as React from 'react';

import { ROUTES } from '../../../constants/const/routes.const';
import PostThumb from '../../media/PostThumb';

export const Videos = ({
    slice,
}: {
    slice: {
        items: [
            {
                video: { document: Queries.PrismicVideoData };
            },
        ];
    };
}) => {
    const videos = slice.items.map(video => video.video.document) || [];
    return (
        <section className="section">
            <div className="container">
                <div className="h2">Videos</div>
                <div className="row thumb-list">
                    {videos.map((video, index) => (
                        <div className="col-md-4 col-sm-12" key={index}>
                            <PostThumb
                                oldImg
                                post={{
                                    uri: `${ROUTES.VIDEOS.uri + video.uid}/`,
                                    author: { node: video.data.author?.document?.data },
                                    title: video.data.title,
                                    date: video.first_publication_date,
                                    img: video.data.image.gatsbyImageData,
                                    alt: video.data.image.alt,
                                    content: video.data?.body
                                        ?.map(slice => slice.primary?.content_text?.text)
                                        .join(' '),
                                    tags: [
                                        {
                                            tag: { document: { uri: '/videos/', data: { name: 'Vidéos' } } },
                                        },
                                    ],
                                }}
                                size="small"
                            />
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
};
