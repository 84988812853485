import React, { useState } from 'react';
import Selector from './components/Inputs/Selector';
import SliderWithInput from './components/SliderWithInput';
import { graphql, useStaticQuery } from 'gatsby';
import ArrowButton from '../../../UI/Button/ArrowButton';
import ResponseSimulator from './components/Responses/ResponseSimulator';
import { OPERATIONS_BANQUAIRES, TYPE_ACTIVITE } from './const';
import {
    ArrowButtonLayout,
    ContainerSimulator,
    FlexOne,
    FormContainer,
    HeaderSimulator,
    InputsDisplay,
    SimulatorTitle,
    StyledImage,
    WrapperDesktopDivider,
    WrapperMobileDivider,
} from './styles';
import useSimulatorForm from './hooks/useSimulatorForm';
import getSavingsWithDougs from '../../../../utils/getSavingWithDougs';
import Divider from '../../../UI/Divider';
import InputNumber from './components/Inputs/InputNumber';
import InputSelect from './components/Inputs/InputSelect';

const CONST_SLIDER = {
    min: 0,
    max: 25000,
    step: 500,
};

const SectionSimulator = ({ isInBlog }: { isInBlog?: boolean }) => {
    const data = useStaticQuery(query);

    const { form, setSimulatorForm } = useSimulatorForm();

    const [showResponse, setShowResponse] = useState(false);
    const [result, setResult] = useState(null);

    const isSci = !TYPE_ACTIVITE.find(e => e.id === form.type).chiffreAffaire;

    const handleCalculate = () => {
        setShowResponse(true);
        const result = getSavingsWithDougs(form);
        setResult(result);
    };

    const handleSetCa = value => {
        setSimulatorForm({ ca: value, ...(value === 'cat1' ? { lignes: '' } : {}) });
    };

    if (showResponse)
        return (
            <ContainerSimulator isInBlog={isInBlog}>
                <ResponseSimulator setShowResponse={setShowResponse} result={result} isInBlog={isInBlog} />
            </ContainerSimulator>
        );

    return (
        <ContainerSimulator isInBlog={isInBlog}>
            <HeaderSimulator isInBlog={isInBlog}>
                <StyledImage
                    image={data.calculatorImage.childImageSharp.gatsbyImageData}
                    alt="service"
                    isInBlog={isInBlog}
                />
                <div>
                    <SimulatorTitle>Économisez avec Dougs</SimulatorTitle>
                    <p>
                        Vous avez déjà un expert-comptable ? Estimez combien vous pouvez économiser grâce à Dougs,
                        l’expert-comptable en ligne.
                    </p>
                </div>
            </HeaderSimulator>
            {!isInBlog && (
                <>
                    <WrapperMobileDivider>
                        <Divider />
                    </WrapperMobileDivider>
                    <WrapperDesktopDivider>
                        <Divider isVertical />
                    </WrapperDesktopDivider>
                </>
            )}
            <FormContainer>
                <Selector
                    currentValue={form.type}
                    setValue={value => setSimulatorForm({ type: value })}
                    buttons={TYPE_ACTIVITE}
                    label="Type d’activité"
                />
                {!isSci && (
                    <Selector
                        currentValue={form.ca}
                        setValue={handleSetCa}
                        buttons={TYPE_ACTIVITE.find(e => e.id === form.type).chiffreAffaire}
                        label="Chiffre d'affaires annuel"
                    />
                )}
                <SliderWithInput
                    label="Honoraires annuels actuels"
                    tooltipText="Indiquez vos honoraires annuels pour la tenue de votre comptabilité, la TVA, le bilan, le juridique et l'établissement des bulletins de salaire"
                    value={form.honoraires}
                    valueLabel="honoraires"
                    setValue={setSimulatorForm}
                    params={CONST_SLIDER}
                />
                {!isSci && (
                    <InputsDisplay>
                        <FlexOne>
                            <InputNumber
                                label="Nombre de salarié(s)"
                                value={form.peoples}
                                valueLabel="peoples"
                                setValue={setSimulatorForm}
                            />
                        </FlexOne>
                        <FlexOne>
                            <InputSelect
                                label="Nombre mensuel d'opérations bancaires"
                                tooltipText="Indiquez le nombre de lignes figurant sur le relevé bancaire du dernier mois (débit et crédit)"
                                value={form.lignes}
                                valueLabel="lignes"
                                setValue={setSimulatorForm}
                                disabled={form.ca === 'cat1'}
                                itemsList={OPERATIONS_BANQUAIRES}
                                placeholder="Sélectionner un nombre"
                            />
                        </FlexOne>
                    </InputsDisplay>
                )}
                <ArrowButtonLayout isInBlog={isInBlog}>
                    <ArrowButton
                        actionType="SIMPLE"
                        onClick={handleCalculate}
                        color="ORANGE"
                        trackingId="calculateur-cta-calculer"
                    >
                        Calculer mes économies
                    </ArrowButton>
                </ArrowButtonLayout>
            </FormContainer>
        </ContainerSimulator>
    );
};

export default SectionSimulator;

const query = graphql`
    {
        calculatorImage: file(relativePath: { eq: "tarif/icon-emprunt.png" }) {
            childImageSharp {
                gatsbyImageData(height: 120, quality: 50, layout: CONSTRAINED)
            }
        }
    }
`;
