import * as React from 'react';
import { createContext, ReactNode, useContext } from 'react';

import { HEADER } from '../components/layout/header/constants/header.const';
import useStickyHeader, { StickyHeaderReturnType } from '../components/layout/header/hooks/useStickyHeader';
import { getHeaderHeight } from '../components/layout/header/utils/getHeaderHeight';
import useIsScrollDown from '../hooks/useIsScrollDown';

type HeaderContextType = {
    hasHeaderMedia: boolean;
    isMinimal: boolean;
    bannerOptions?: {
        isVisible: boolean;
    };
    stickyHeader?: StickyHeaderReturnType & { height?: number; dynamicHeight?: number };
    isScrollingDown?: boolean;
    forceWhiteBackground?: boolean;
};

const HeaderContext = createContext<HeaderContextType>({
    hasHeaderMedia: false,
    isMinimal: false,
    bannerOptions: {
        isVisible: false,
    },
    stickyHeader: {
        hasReachedScrollBreakpoint: false,
        ref: undefined,
    },
    isScrollingDown: false,
    forceWhiteBackground: false,
});

export default function HeaderContextProvider({
    context,
    children,
}: {
    context: HeaderContextType;
    children: ReactNode;
}) {
    const stickyHeader = useStickyHeader();
    const isScrollingDown = useIsScrollDown();

    const headerHeight = getHeaderHeight(context.isMinimal, context.hasHeaderMedia, context.bannerOptions.isVisible);

    const dynamicHeight = isScrollingDown && context.hasHeaderMedia ? headerHeight - HEADER.HEIGHT.MAIN : headerHeight;

    return (
        <HeaderContext.Provider
            value={{
                ...context,
                stickyHeader: {
                    ...stickyHeader,
                    height: headerHeight,
                    dynamicHeight: dynamicHeight,
                },
                isScrollingDown,
            }}
        >
            {children}
        </HeaderContext.Provider>
    );
}

export function useHeaderContext() {
    return useContext(HeaderContext);
}
