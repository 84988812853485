import styled from '@emotion/styled';
import { graphql, Link, useStaticQuery } from 'gatsby';
import * as React from 'react';
import { ReactElement } from 'react';

import { DateTime, formatDate } from '../../../hooks/useFormatDate';
import { WebinarAvatar } from '../../../templates/webinars';
import { SeoTitle } from '../../seo/seoTitle';
import { Button } from '../../UI/Button/legacy/button/Button';

const StyledButton = styled(Button)`
    font-size: 14px;
    font-weight: 700;
    padding: 10px 16px;
`;

const WebinarRow = ({
    webinar,
    position,
    index,
}: {
    webinar?: Queries.Webinar;
    position: number;
    index: number;
}): ReactElement => {
    if (!webinar.sessions[0]) return null;
    return (
        <div className="WebinarRow container">
            <div className="WebinarInfos">
                <div className="WebinarSubInfos">
                    <WebinarAvatar webinar={webinar} />
                    <div className="WebinarSubInfosText">
                        <div className="WebinarTitle">{webinar.event.title}</div>
                        <div className="WebinarSubTitle">
                            <p>
                                {`le ${formatDate(
                                    webinar.sessions[0].estimated_started_at,
                                    DateTime.DATE_SHORT,
                                )} à ${formatDate(webinar.sessions[0].estimated_started_at, DateTime.TIME_24_SIMPLE)}`}
                            </p>
                            {webinar.sessions.length > 2 && (
                                <div> {`ou ${webinar.sessions.length - 1} autres dates`}</div>
                            )}
                            {webinar.sessions.length === 2 && (
                                <div> {`ou ${webinar.sessions.length - 1} autre date`}</div>
                            )}
                        </div>
                    </div>
                </div>
                <div className="WebinarSelect">
                    <Link
                        state={{ sessionId: webinar.sessions[0].id }}
                        to={`/webinars/${webinar.event.slug}/`}
                        data-ga-send={`event,button,click,webinar${index}-supertag,upcoming`}
                        data-ga4={`miniature-level${position}-cta-webinar${index}live-click-link`}
                    >
                        <StyledButton type="white"> Je m'inscris</StyledButton>
                    </Link>
                </div>
            </div>
        </div>
    );
};

export const Webinars = ({
    slice,
    position,
}: {
    slice: {
        primary: { keyword: string };
    };
    position: number;
}) => {
    const webinars = useStaticQuery(query);

    const othersWebinars = webinars.allWebinar.nodes.filter(
        webinar => !webinar.event.title.toLowerCase().includes(slice.primary.keyword.toLowerCase()),
    );

    const themeWebinars = webinars.allWebinar.nodes.filter(webinar =>
        webinar.event.title.toLowerCase().includes(slice.primary.keyword.toLowerCase()),
    );
    return (
        <div className="mb-10">
            <SeoTitle as="div" seoStyle="h2">
                Webinars
            </SeoTitle>
            {[...themeWebinars, ...othersWebinars].slice(0, 3).map((webinar, index) => (
                <WebinarRow webinar={webinar} position={position} index={index} key={'webinar-' + index}></WebinarRow>
            ))}
            <Link className="u-flex-center" to={'/webinars'}>
                <Button
                    //data-ga-send={`event,button,click,allwebinar-supertag`}
                    data-ga4={`container-level${position}-cta-allwebinar-click-link`}
                    type={'white'}
                >
                    Voir tous les Webinars →
                </Button>
            </Link>
        </div>
    );
};

const query = graphql`
    {
        allWebinar(
            filter: { event: { show_in_company_page: { eq: true } } }
            sort: { sessions: { estimated_started_at: ASC } }
        ) {
            nodes {
                id
                meta {
                    record_count
                }
                event {
                    title
                    slug
                    status
                    owner {
                        attributes {
                            avatar_link
                            first_name
                            last_name
                        }
                    }
                    sessions_count
                }
                sessions {
                    id
                    event_id
                    status
                    estimated_started_at
                }
            }
        }
    }
`;
