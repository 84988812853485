import algoliasearch from 'algoliasearch/lite';
import { navigate } from 'gatsby';
import * as React from 'react';
import { Dispatch, SetStateAction, useCallback, useState } from 'react';
import { Configure, connectAutoComplete, InstantSearch } from 'react-instantsearch-dom';

import { ALGOLIA } from '../../constants/const/algolia.const';
import { ROUTES } from '../../constants/const/routes.const';
import { PostDatas } from './PostHighlight';
import PostThumb from './PostThumb';
import * as styles from './search-form.module.scss';
import SearchBar from './SearchBar';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const searchClient = algoliasearch(ALGOLIA.APP_ID, ALGOLIA.ADMIN_KEY);

const Autocomplete = connectAutoComplete(
    ({
        hits,
        refine,
        currentRefinement,
        opened,
        setOpened,
    }: {
        hits: PostDatas[];
        currentRefinement?: string;
        refine: (search) => void;
        opened: boolean;
        setOpened: Dispatch<SetStateAction<boolean>>;
    }) => {
        const [className, setClassName] = useState('');

        const closeForm = useCallback(() => {
            setClassName('close');
            setTimeout(() => {
                refine('');
                setOpened(false);
                setClassName('');
            }, 200);
        }, [refine, setOpened]);

        const seeMore = useCallback(
            refinement => {
                closeForm();
                navigate(ROUTES.BLOG.uri, {
                    state: { refinement: refinement },
                });
            },
            [closeForm],
        );
        return opened ? (
            <div className={`${styles.searchMedia} ${className}`}>
                <div className={styles.searchOverlay} onClick={() => closeForm()}></div>
                <form role="search" id="search-form" className={styles.searchForm} onSubmit={e => e.preventDefault()}>
                    <SearchBar closeForm={closeForm}></SearchBar>

                    {currentRefinement && (
                        <div className={styles.searchResult}>
                            <div className="container">
                                {hits.length ? (
                                    <>
                                        <div className={`row thumb-list ${styles.searchResultList}`}>
                                            {hits.map((hit, index) => (
                                                <div className="col-md-3 col-sm-6" key={index}>
                                                    <PostThumb
                                                        oldImg
                                                        post={{
                                                            ...hit,
                                                            img: hit.img,
                                                        }}
                                                        size="small"
                                                    ></PostThumb>
                                                </div>
                                            ))}
                                        </div>
                                        <div className="u-textCenter my-3">
                                            <button
                                                type="button"
                                                className="Button m-outline m-small"
                                                onClick={() => seeMore(currentRefinement)}
                                            >
                                                Voir plus d'articles
                                            </button>
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        <div className="u-textCenter my-5">
                                            <div className="h4 mb-4">Aucun résultat</div>
                                            <button type="button" className="Button" onClick={() => seeMore('')}>
                                                Voir tous nos articles
                                            </button>
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>
                    )}
                </form>
            </div>
        ) : null;
    },
);

const SearchForm = ({
    onSearchStateChange,
    opened,
    setOpened,
}: {
    onSearchStateChange?: (searchState) => void;
    opened: boolean;
    setOpened: Dispatch<SetStateAction<boolean>>;
}) => {
    return (
        <InstantSearch indexName={'posts'} searchClient={searchClient} onSearchStateChange={onSearchStateChange}>
            <Configure hitsPerPage={4} distinct />
            <Autocomplete opened={opened} setOpened={setOpened} />
        </InstantSearch>
    );
};

export default SearchForm;
