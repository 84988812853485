import { Link } from 'gatsby';
import * as React from 'react';

import * as styles from './post-infos.module.scss';

export type AuthorInfos = Queries.PrismicAuteur;

const PostInfos = ({ author, entryTime = false }: { date?: string; author: AuthorInfos; entryTime?: boolean }) => {
    if (!author?.data) return <div />;

    return (
        <Link
            to={author.uri}
            data-ga-send="event,button,click,blog-author"
            data-ga4="container-author-text-topauthor-click-link"
            className={styles.postInfos}
            rel="nofollow"
        >
            {author.data.avatar && (
                <img
                    loading="lazy"
                    alt={author.data.name}
                    src={author.data.avatar.url}
                    className="avatar mr-1"
                    height="32"
                    width="32"
                />
            )}
            <div>
                <div className={styles.postInfosAuthor} id={entryTime ? 'author' : undefined}>
                    {author.data.name}
                </div>
                <div className={'text-mini u-block' + (entryTime ? ' entry-time' : '')}>
                    {author.data.job || 'rédacteur'}
                </div>
            </div>
        </Link>
    );
};

export default PostInfos;
