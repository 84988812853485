import styled from '@emotion/styled';
import { Link } from 'gatsby';
import * as React from 'react';

import { ROUTES } from '../../../constants/const/routes.const';
import GuideThumb from '../../media/GuideThumb';
import { SeoTitle } from '../../seo/seoTitle';

const GuidesWrapper = styled.div`
    display: flex;
    flex-direction: column-reverse;
    width: 100vw;
    margin-left: -50vw;
    left: 50%;
    position: relative;
`;

export const Guides = ({
    slice,
    position,
}: {
    slice: {
        items: [
            {
                guide: { document: Queries.PrismicGuideData };
            },
        ];
    };
    position: number;
}) => {
    const guides = slice.items.map(guide => guide.guide.document) || [];
    return (
        <GuidesWrapper>
            <div className="section section--dark section--large">
                <div className="container">
                    <div className="row u-align-center">
                        <div className="col-md-3">
                            <SeoTitle as={'div'} seoStyle={'h2'}>
                                Les guides pratiques de l’entrepreneur
                            </SeoTitle>
                            <Link
                                to={ROUTES.GUIDES.uri}
                                data-ga4={`container-level${position}-cta-allguide-click-link`}
                                className="Button m-outline m-small mb-3"
                            >
                                Les voir tous &nbsp;&rarr;
                            </Link>
                        </div>
                        <div className="col-md-9">
                            <div className="slider">
                                <div className="slider__content">
                                    {guides.slice(0, 3).map((guide, index) => (
                                        <div className="slider__item" key={index}>
                                            <GuideThumb
                                                index={index + 1}
                                                withSeoTitles={false}
                                                guide={guide}
                                                size="small"
                                                className="resource-thumb--dark"
                                            />
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </GuidesWrapper>
    );
};
