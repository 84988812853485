export const isAReferralUrl = location => !!location.search.match(/r=\w{10}/);
export const hasValidReferralCookie = (): boolean => {
    let hasValidCookie = false;
    if (
        typeof document !== 'undefined' &&
        document.cookie &&
        !!document.cookie.match('dg_referral') &&
        !!document.cookie.match('dg_referral_created_at')
    ) {
        const cookie = document.cookie.split(';').find(c => !!c.match('dg_referral_created_at'));
        const cookieCreatedAt = cookie.split('=')[1];
        if (cookieCreatedAt) {
            hasValidCookie = parseInt(cookieCreatedAt, 10) + 1000 * 60 * 60 * 24 * 5 > Date.now();
        }
    }

    return hasValidCookie;
};
