import algoliasearch from 'algoliasearch/lite';
import * as React from 'react';
import { useCallback, useEffect, useRef, useState } from 'react';
import { connectAutoComplete } from 'react-instantsearch-dom';

import { ALGOLIA } from '../../constants/const/algolia.const';
import CloseIcon from './../../images/icons/close.svg';
import SearchIcon from './../../images/icons/search.svg';
import * as styles from './search-bar.module.scss';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const searchClient = algoliasearch(ALGOLIA.APP_ID, ALGOLIA.ADMIN_KEY);

const SearchBar = connectAutoComplete(
    ({
        currentRefinement,
        refine,
        closeForm,
    }: {
        currentRefinement?: string;
        refine: (search) => void;
        closeForm: () => void;
    }) => {
        const timer = useRef(null);

        const [currentValue, setCurrentValue] = useState(currentRefinement);

        const onChangeDebounced = useCallback(
            value => {
                clearTimeout(timer.current);
                timer.current = setTimeout(() => {
                    refine(value);
                }, 300);
                setCurrentValue(value);
            },
            [refine],
        );

        useEffect(() => {
            setCurrentValue(currentRefinement);
        }, [currentRefinement]);

        return (
            <div className={styles.searchBar}>
                <div className="search-icon">
                    <SearchIcon />
                </div>
                <input
                    type="search"
                    placeholder="Rechercher un article..."
                    id="search-input"
                    value={currentValue}
                    onChange={event => onChangeDebounced(event.currentTarget.value)}
                    autoFocus
                />
                <button type="button" className={styles.searchClose} onClick={() => closeForm()}>
                    <CloseIcon />
                </button>
            </div>
        );
    },
);

export default SearchBar;
