import styled from '@emotion/styled';
import React, { useEffect, useState } from 'react';
import { colors, fonts } from '../../../styles/theme';
import useScrollToIds from '../../../hooks/useScrollToIds';

enum VARIANT {
    DEFAULT = 'DEFAULT',
    FOOTER = 'FOOTER',
}

interface SummaryProps {
    items: { slug: string; label: string }[];
    variant?: keyof typeof VARIANT;
    disable?: boolean;
    isMobile?: boolean;
}

interface StatusSummaryItem {
    default: string;
    active: string;
    hover?: string;
}

interface VariantColors {
    title: StatusSummaryItem;
    chipNumber: StatusSummaryItem;
    chipBackground: StatusSummaryItem;
}

const variantColors = {
    [VARIANT.DEFAULT]: {
        title: {
            default: colors.$gray,
            active: colors.$primary700,
            hover: colors.$gray700,
        },
        chipNumber: {
            default: colors.$gray350,
            active: 'white',
            hover: colors.$gray700,
        },
        chipBackground: {
            default: '#E6ECF2',
            active: colors.$primary700,
            hover: colors.$gray300,
        },
    },
    [VARIANT.FOOTER]: {
        title: {
            default: colors.$gray400,
            active: 'white',
        },
        chipNumber: {
            default: colors.$gray400,
            active: colors.$primary700,
        },
        chipBackground: {
            default: '#FFFFFF1A',
            active: 'white',
        },
    },
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 12px;
`;

const SummaryItem = styled.div`
    display: flex;
    gap: 12px;
    align-items: center;
`;

const TitleLink = styled.a<{ isActive?: boolean; variantColors: VariantColors }>`
    color: ${props => (props.isActive ? props.variantColors.title.active : props.variantColors.title.default)};
    font-family: ${fonts.OpenSans_Regular};
    font-size: 14px;
    line-height: 150%;

    ${props =>
        !props.isActive &&
        `
    ${SummaryItem}:hover & {
        color: ${props.variantColors.title.hover};
    }
    `}
`;

const DisableTitle = styled.div<{ variantColors: VariantColors }>`
    color: ${props => props.variantColors.title.default};
    font-family: ${fonts.OpenSans_Regular};
    font-size: 14px;
    line-height: 150%;
`;

const DisableChipNumber = styled.div<{ variantColors: VariantColors }>`
    color: ${props => props.variantColors.chipNumber.default};
    font-size: 13px;
    font-family: ${fonts.OpenSans_Bold};
    min-width: 24px;
    height: 24px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 12px;
    background: ${props => props.variantColors.chipBackground.default};
`;

const ChipNumber = styled.div<{ isActive?: boolean; variantColors: VariantColors }>`
    color: ${props =>
        props.isActive ? props.variantColors.chipNumber.active : props.variantColors.chipNumber.default};
    font-size: 13px;
    font-family: ${fonts.OpenSans_Bold};
    min-width: 24px;
    height: 24px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 12px;
    background: ${props =>
        props.isActive ? props.variantColors.chipBackground.active : props.variantColors.chipBackground.default};

    ${props =>
        !props.isActive &&
        `
        ${SummaryItem}:hover & {
            color: ${props.variantColors.chipNumber.hover};
            background: ${props.variantColors.chipBackground.hover};
        }
    `}
`;

export const Summary = ({ items, variant = 'DEFAULT', disable, isMobile }: SummaryProps) => {
    const [activeItem, setActiveItem] = useState(items[0] ? items[0].slug : null);
    const activeId = useScrollToIds(items.map(item => item.slug));

    useEffect(() => {
        if (activeId && !disable) setActiveItem(activeId);
        if (disable || isMobile) setActiveItem(null);
    }, [activeId]);

    return (
        <Container>
            {items.map((title, index) => {
                return disable ? (
                    <SummaryItem key={title.slug}>
                        <DisableChipNumber variantColors={variantColors[variant]}>{index + 1}</DisableChipNumber>
                        <DisableTitle variantColors={variantColors[variant]}>{title.label}</DisableTitle>
                    </SummaryItem>
                ) : (
                    <SummaryItem key={title.slug}>
                        <ChipNumber isActive={title.slug === activeItem} variantColors={variantColors[variant]}>
                            {index + 1}
                        </ChipNumber>
                        <TitleLink
                            isActive={title.slug === activeItem}
                            variantColors={variantColors[variant]}
                            href={`#${title.slug}`}
                            data-ga-send={`event,button,click,blog-summary-chapter${index + 1}`}
                            data-ga4={`container-summary-text-chapter${index + 1}-click-link`}
                            onClick={() => setActiveItem(title.slug)}
                        >
                            {title.label}
                        </TitleLink>
                    </SummaryItem>
                );
            })}
        </Container>
    );
};
