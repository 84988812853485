import { useMemo } from 'react';

export enum DateTime {
    DATE_SHORT = 'DATE_SHORT', // 11/02/2024
    DATE_FULL = 'DATE_FULL', // 14 décembre 2023
    DATE_HUGE = 'DATE_HUGE', // Lundi 1 Novembre 2023 à 10:00
    DATE_HUGE_WITHOUT_YEAR = 'DATE_HUGE_WITHOUT_YEAR', // Lundi 1 Novembre à 10:00
    TIME_24_SIMPLE = 'TIME_24_SIMPLE', // 10:00
}

const monthNames = [
    'janvier',
    'février',
    'mars',
    'avril',
    'mai',
    'juin',
    'juillet',
    'août',
    'septembre',
    'octobre',
    'novembre',
    'décembre',
] as const;

const dayNames = ['dimanche', 'lundi', 'mardi', 'mercredi', 'jeudi', 'vendredi', 'samedi'] as const;

interface FormattedDate {
    day: {
        number: string;
        text: string;
    };
    month: {
        number: string;
        text: string;
    };
    year: number;
    hours: string;
    minutes: string;
}

function computeDate(inputDate: number | string): FormattedDate {
    const date = typeof inputDate === 'number' ? new Date(inputDate * 1000) : new Date(inputDate);
    const day = String(date.getDate()).padStart(2, '0');
    const dayString = dayNames[date.getDay()]; // Get day and pad with leading zero if needed
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Get month (+1 because months are zero-based) and pad with leading zero if needed
    const monthString = monthNames[date.getMonth()];
    const year = date.getFullYear(); // Get full year
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');

    return {
        day: {
            number: day,
            text: dayString,
        },
        month: {
            number: month,
            text: monthString,
        },
        year,
        hours,
        minutes,
    };
}

export default (inputDate: number | string, format: DateTime = DateTime.DATE_FULL) => {
    const formattedDate = useMemo(() => formatDate(inputDate, format), [inputDate, format]);
    return formattedDate;
};

export function formatDate(inputDate: number | string, format: DateTime = DateTime.DATE_HUGE) {
    const { day, month, year, hours, minutes } = computeDate(inputDate);

    switch (format) {
        case DateTime.DATE_SHORT:
            return `${day.number}/${month.number}/${year}`;
        case DateTime.TIME_24_SIMPLE:
            return `${hours}:${minutes}`;
        case DateTime.DATE_HUGE:
            return `${day.text} ${day.number} ${month.text} ${year} à ${hours}:${minutes}`;
        case DateTime.DATE_HUGE_WITHOUT_YEAR:
            return `${day.text} ${day.number} ${month.text} à ${hours}:${minutes}`;
        case DateTime.DATE_FULL:
            const formattedDay = day.number.charAt(0) === '0' ? day.number.slice(1) : day.number; // Delete first digit if the day is < 10
            return `${formattedDay} ${month.text} ${year}`;
        default:
            return `${day.number}/${month.text}/${year}`;
    }
}
