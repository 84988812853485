import React, { useMemo } from 'react';

import Divider from '../../UI/Divider';
import useFormatDate from '../../../hooks/useFormatDate';
import { readingTimePostCalculator } from '../../../utils/ReadingTime';
import { TagItem } from '../../tag/Tag';
import PostTag from '../PostTag';
import { Container, PostHighlightBottom, ReadingTime, TagsList, TimeInfo } from './PostHighlight.style';
import { PostHighlightProps } from './PostHighlight.types';
import styled from '@emotion/styled';
import { breakpoint } from '../../../styles/theme';
import PostInfos from '../PostInfos';

const Title = styled.h1`
    font-size: 28px;
    text-align: center;
    line-height: 125%;
    margin-bottom: 12px;

    ${breakpoint.laptop} {
        font-size: 40px;
    }
`;

const PostHighlight = ({ post }: PostHighlightProps) => {
    const readingTime = post.readingTime || readingTimePostCalculator(post);

    const tagList = useMemo(() => {
        const itemsPrismic: TagItem[] = post.data?.labels.length
            ? post.data.labels
                  .filter(tag => tag.tag?.document)
                  .map(tag => {
                      return {
                          text: tag.tag?.document?.data.name,
                          url: tag.tag?.document?.uri,
                      };
                  })
            : [];
        return itemsPrismic;
    }, [post?.data?.labels, readingTime]);

    const dateMessage = () => {
        if (post.last_publication_date !== post.first_publication_date)
            return `mis à jour le ${useFormatDate(post.last_publication_date)}`;
        return `publié le ${useFormatDate(post.last_publication_date)}`;
    };

    return (
        <Container>
            <Title>{post.title || post.data.titre}</Title>

            <TagsList>
                {tagList
                    .filter(tag => tag.text)
                    .map((tag, index) => (
                        <PostTag label={tag.text} url={tag.url || null} key={index} />
                    ))}
            </TagsList>

            <Divider />
            <PostHighlightBottom>
                {post.data?.author?.document && (
                    <PostInfos author={post.data.author?.document as Queries.PrismicAuteur} />
                )}
                <TimeInfo>
                    <time
                        data-lastmod={post.last_publication_date}
                        dateTime={post.data?.published_date || post.first_publication_date}
                    >
                        {dateMessage()}
                    </time>

                    <ReadingTime>
                        <img src="/images/UI-icons/clock-two.svg" />
                        <>{`${readingTime} min.`}</>
                    </ReadingTime>
                </TimeInfo>
            </PostHighlightBottom>

            <Divider />
        </Container>
    );
};

export default PostHighlight;
