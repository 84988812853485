import * as React from 'react';

import dougsMediaLogo from './../../images/logo/logo-dougs-media.svg';
import * as styles from './media-slogan-block.module.scss';

const MediaSloganBlock = () => (
    <div className="section">
        <div className={styles.sloganBlock}>
            <div className="container">
                <img src={dougsMediaLogo} alt="Logo dougs.media" className={styles.sloganBlockLogo} />
                <div className={styles.sloganBlockText}>Le Média des entrepreneurs qui gèrent&nbsp;!</div>
            </div>
        </div>
    </div>
);

export default MediaSloganBlock;
