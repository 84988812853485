import styled from '@emotion/styled';

import { breakpoint, colors, fonts } from '../../../styles/theme';

export const NavButton = styled.button<{ variant: 'primary' | 'secondary' }>`
    display: flex;
    height: 40px;
    padding: 0px 24px;
    justify-content: center;
    align-items: center;

    cursor: pointer;

    border-radius: 22px;
    border: 1px solid;
    border-color: ${props => (props.variant === 'secondary' ? 'rgba(1, 58, 81, 0.2)' : colors.$primary)};
    background-color: ${props => (props.variant === 'secondary' ? 'transparent' : colors.$primary)};

    font-family: ${fonts.OpenSans_Bold};
    color: ${props => (props.variant === 'secondary' ? colors.$primary700 : 'white')};

    &:hover {
        border-color: ${props => (props.variant === 'secondary' ? 'rgba(1, 58, 81, 0.2)' : 'rgba(14, 139, 191, 1)')};

        background-color: ${props =>
            props.variant === 'secondary' ? 'rgba(1, 58, 81, 0.05)' : 'rgba(14, 139, 191, 1)'};
    }

    ${breakpoint.custom.max('1259')} {
        width: 100%;
    }
`;
