// extracted by mini-css-extract-plugin
export var fade = "post-highlight-module--fade--bdb24";
export var imgRatio = "post-highlight-module--img-ratio--64a8f";
export var imgRatioWrapper = "post-highlight-module--img-ratio-wrapper--99831";
export var postHighlight = "post-highlight-module--post-highlight--49fbc";
export var postHighlightEmailBlock = "post-highlight-module--post-highlight-emailBlock--ff075";
export var postHighlightExcerpt = "post-highlight-module--post-highlight-excerpt--9a083";
export var postHighlightFooter = "post-highlight-module--post-highlight-footer--a46f1";
export var postHighlightFooterBottom = "post-highlight-module--post-highlight-footerBottom--945b6";
export var postHighlightImage = "post-highlight-module--post-highlight-image--90c1d";
export var postHighlightMaxHeight = "post-highlight-module--post-highlight-maxHeight--18315";
export var postHighlightText = "post-highlight-module--post-highlight-text--1750e";
export var postHighlightTextEmail = "post-highlight-module--post-highlight-textEmail--53d39";
export var postHighlightTitle = "post-highlight-module--post-highlight-title--7f33f";
export var slideDown = "post-highlight-module--slideDown--26524";