import { ROUTES } from '../constants/const/routes.const';

export type BreadcrumbItem = {
    uri?: string;
    name?: string;
    parent?: { node: BreadcrumbItem };
};

export const breadcrumbParser = (ancestor: BreadcrumbItem): { uri?: string; name: string }[] => [
    ...(ancestor?.parent ? breadcrumbParser(ancestor.parent.node) : []),
    {
        uri: ancestor.uri,
        name: ancestor.name,
    },
];

export const mediaBreadcrumbParser = (ancestor: BreadcrumbItem): { uri?: string; name: string }[] => [
    ROUTES.HOME,
    ROUTES.MEDIA,
    ...breadcrumbParser(ancestor),
];
