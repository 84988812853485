import styled from '@emotion/styled';

import { breakpoint, colors, fonts } from '../../../styles/theme';

export const Card = styled.div`
    display: flex;
    padding: 32px;
    align-items: center;
    flex-direction: column;
    gap: 16px;
    align-self: stretch;
    border-radius: 16px;
    background: ${colors.$greenSuccess};

    ${breakpoint.tablet} {
        padding: 40px;
        gap: 24px;
        flex-direction: row;
    }
`;

export const Text = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
    flex: 1 0 0;
    width: 100%;

    ${breakpoint.tablet} {
        gap: 8px;
    }

    h3 {
        color: #fff;
        font-family: ${fonts.Eina_SemiBold};
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 125%; /* 30px */
        margin-bottom: 0;

        ${breakpoint.tablet} {
            font-size: 24px;
        }
    }

    ul {
        display: flex;
        flex-direction: column;
        gap: 0px !important;
        li {
            align-self: stretch;
            color: #fff;
            font-family: ${fonts.OpenSans_Regular};
            font-size: 15px;
            font-style: normal;
            font-weight: 400;
            line-height: 150%; /* 24px */
            margin-bottom: 0px !important;

            ${breakpoint.tablet} {
                font-size: 16px;
            }
        }
    }
`;

export const ButtonWrapper = styled.div`
    width: 100%;
    ${breakpoint.tablet} {
        width: auto;
    }
`;
