import styled from '@emotion/styled';
import { graphql } from 'gatsby';
import * as React from 'react';

import { colors } from '../../../styles/theme';
import HubspotForm from '../../hubspot/HubspotForm';
import { SeoTitle } from '../../seo/seoTitle';

const WysiwygPanel = styled.div`
    background-color: ${colors.$primary800} !important;
    border-radius: 16px;
    padding: 40px;

    p {
        font-size: 1rem !important;
        color: white !important;
    }

    .h4 {
        color: white;
    }

    span,
    strong {
        color: white !important;
        font-weight: normal !important;
    }

    input {
        padding: 10px 16px !important;
    }

    .hs-form-required {
        color: red !important;
        font-weight: normal !important;
    }
`;

export const Newsletter = () => {
    return (
        <WysiwygPanel>
            <SeoTitle className="mb-2" seoStyle="h4">
                Inscrivez-vous à la newsletter Dougs !
            </SeoTitle>
            <HubspotForm target={'blog'} trackingId="newsletterSlice" formId={'e87517fb-7c30-4855-9129-b3c300ad0b7b'} />
        </WysiwygPanel>
    );
};

export const query = graphql`
    fragment VideoDataCard on PrismicVideoDataBodyCard {
        primary {
            content_text {
                richText
                text
            }
        }
        slice_type
    }
`;
