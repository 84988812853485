import styled from '@emotion/styled';

import { breakpoint } from '../../styles/theme';

export const Wrapper = styled.div`
    width: 100%;
    padding: 0 24px;

    ${breakpoint.desktop} {
        padding: 0px;
        width: 1080px;
        margin-right: auto;
        margin-left: auto;
    }
`;
