import styled from '@emotion/styled';
import { Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import * as React from 'react';

import { breakpoint } from '../styles/theme';
import { highlight } from './moreTagsData/data';
import { SeoTitle } from './seo/seoTitle';

const ProductHighlightWrapper = styled.div`
    display: flex;
    gap: 16px;
    margin-bottom: 32px;
    justify-content: space-around;

    ${breakpoint['$large-max']} {
        flex-direction: column;
        align-items: center;
        gap: 32px;
    }
`;

const StyledSeoTitle = styled(SeoTitle)`
    text-align: center;
    margin-bottom: 48px;
    padding-top: 24px;
`;

const ImageWrapper = styled.div`
    * > * > img {
        height: 15vh !important;
        max-width: 100%;
        max-height: 100%;
        display: block;
        margin: auto;
    }
`;

export const ProductHighlight = ({
    marginTop = false,
    product,
    tracking,
}: {
    marginTop?: boolean;
    tracking?: string;
    product: Queries.PrismicTag;
}) => {
    return (
        <div>
            <div className={`post-category__name post-category__name--none${marginTop ? ' mt-2' : ''} u-textCenter`}>
                {product.data.cta_title}
            </div>
            <ImageWrapper className="mt-2 u-textCenter">
                <GatsbyImage image={product.data.cta_image.gatsbyImageData} alt={product.data.cta_image.alt} />
            </ImageWrapper>
            <div className="mt-2 u-textCenter">
                <Link
                    className="u-btn"
                    to={product.data.cta_link}
                    data-ga-send={`event,button,click,${tracking || 'blog'}-cta-${product.data.slug}`}
                    data-ga4={`miniature-level2-img-product-click-link-${product.data.slug}`}
                >
                    <span className="btn-content">{product.data.cta_text}</span>
                </Link>
            </div>
        </div>
    );
};

export const ProductsHighlight = ({ tracking }: { tracking?: string }) => (
    <>
        <StyledSeoTitle seoStyle={'h2'}>Dougs simplifie la vie des entrepreneurs</StyledSeoTitle>
        <ProductHighlightWrapper>
            {highlight.map(tag => (
                <ProductHighlight key={tag.id} product={tag} tracking={tracking} />
            ))}
        </ProductHighlightWrapper>
    </>
);
