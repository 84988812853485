import React from 'react';

import { ROUTES } from '../../../../../constants/const/routes.const';
import { NavLink, StyledNav } from '../styles/styles';
import ComptaDropdown from './ComptaDropdown';
import CreaDropdown from './CreaDropdown';
import RessourcesDropdown from './RessourcesDropdown';

const Navbar = () => {
    return (
        <StyledNav>
            <ComptaDropdown />
            <CreaDropdown />
            <NavLink
                to={ROUTES.FACTURATION.uri}
                activeClassName={'active'}
                data-ga-send="event,button,click,topbar-invoicing"
                data-ga4="topbar-bar1-text-title3-click-link"
            >
                {ROUTES.FACTURATION.name}
            </NavLink>
            <NavLink
                to={ROUTES.PRICES.uri}
                activeClassName={'active'}
                data-ga-send="event,button,click,topbar-pricing"
                data-ga4="topbar-bar1-text-title4-click-link"
            >
                {ROUTES.PRICES.name}
            </NavLink>

            <RessourcesDropdown />
        </StyledNav>
    );
};

export default Navbar;
