import styled from '@emotion/styled';
import React, { ReactNode } from 'react';

import { useHeaderContext } from '../../../../contexts/headerContextProvider';

const StickyElement = styled.div<{ headerHeight: number; topPadding: number }>`
    padding-top: ${props => props.topPadding}px;
    position: sticky;
    top: ${props => props.headerHeight}px !important;
    transition: all 0.1s ease-in-out;
`;

const DEFAULT_TOP_PADDING = 24;

const Sticky = ({
    children,
    as,
    topPadding = DEFAULT_TOP_PADDING,
    ...args
}: {
    children: ReactNode | ReactNode[];
    as?: 'div' | 'section' | 'article';
    topPadding?: number;
    [key: string]: unknown;
}) => {
    const { stickyHeader } = useHeaderContext();

    return (
        <StickyElement topPadding={topPadding} headerHeight={stickyHeader.dynamicHeight} as={as} {...args}>
            {children}
        </StickyElement>
    );
};

export default Sticky;
