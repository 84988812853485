import styled from '@emotion/styled';
import * as React from 'react';
import { useEffect, useRef } from 'react';

import { breakpoint } from '../../styles/theme';

interface ImageModalProps {
    isOpen: boolean;
    close: () => void;
    src: string;
    alt: string;
}

const Container = styled.div<{ isOpen: boolean }>`
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 12px;
    background: rgba(0, 0, 0, 0.7);
    backdrop-filter: blur(4px);
    opacity: 1;
    z-index: 100;
    visibility: ${props => (props.isOpen ? 'visible' : 'hidden')};

    ${breakpoint.tablet} {
        padding: 24px;
    }
`;

const Modal = styled.div`
    max-height: 100%;
    max-width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const StyledImage = styled.img`
    border-radius: 8px;
    max-height: 100%;
    max-width: 100%;
    object-fit: contain;
`;

const CloseButton = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    position: absolute;
    top: 12px;
    right: 12px;
    transition: transform 0.3s ease;
    width: 40px;
    height: 40px;
    background: white;
    border-radius: 8px;
    box-shadow:
        0px 8px 40px 0px rgba(0, 0, 0, 0.1),
        0px 0px 8px 0px rgba(0, 0, 0, 0.05);

    ${breakpoint.tablet} {
        top: 24px;
        right: 24px;
    }

    :hover {
        transform: scale(1.1);
    }
`;

const ImageModal = ({ isOpen, close, src, alt }: ImageModalProps) => {
    const useCloseModalOutside = (ref, close) =>
        useEffect(() => {
            const handler = e => {
                if (e.which === 27) close();
            };
            const handleClickOutside = () => {
                if (ref.current && !ref.current.contains(event.target)) {
                    close();
                }
            };
            document.addEventListener('keyup', handler);
            document.addEventListener('click', handleClickOutside, true);
            return () => document.removeEventListener('keyup', handler);
        }, [ref, close]);

    const wrapperRef = useRef(null);
    useCloseModalOutside(wrapperRef, close);

    return (
        <Container isOpen={isOpen}>
            <CloseButton onClick={close}>
                <img src="/images/icons/close.svg" />
            </CloseButton>
            <Modal ref={wrapperRef}>
                <StyledImage src={src} alt={alt} />
            </Modal>
        </Container>
    );
};

export default ImageModal;
