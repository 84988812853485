import { PrismicPreviewProvider } from 'gatsby-plugin-prismic-previews';
import * as React from 'react';

import AuthorPrismicPage from './src/templates/authorPrismic';
import CategoryPage from './src/templates/categoryPrismic';
import Guide from './src/templates/guidePrismic';
import Post from './src/templates/postPrismic';
import TagPage from './src/templates/tagPrismic';
import Video from './src/templates/video';
import { linkResolver } from './src/utils/linkResolver';
import Cookies from 'universal-cookie';

export const onInitialClientRender = () => {
    adBlockDetector && adBlockDetector();
};

export const onRouteUpdate = location => {
    const mixpanel = require('mixpanel-browser');
    mixpanel.init(process.env.GATSBY_MIXPANEL_API_KEY, { debug: process.env.GATSBY_MIXPANEL_DEBUG_MODE === 'true' });
    if (mixpanel) {
        const cookies = new Cookies();
        const dg_referral = cookies.get('dg_referral');

        mixpanel.track('Revenue Marketing WebSite Page Viewed', {
            Platform: 'Marketing WebSite',
            URL: location.location.pathname,
            dg_referral,
            branch: process.env.BRANCH,
        });
    }
};

export const wrapRootElement = ({ element }) => {
    return (
        <PrismicPreviewProvider
            repositoryConfigs={[
                {
                    repositoryName: process.env.GATSBY_PRISMIC_REPO_NAME,
                    linkResolver: linkResolver,
                    componentResolver: {
                        post: Post,
                        video: Video,
                        tag: TagPage,
                        author: AuthorPrismicPage,
                        guide: Guide,
                        category: CategoryPage,
                    },
                },
            ]}
        >
            {element}
        </PrismicPreviewProvider>
    );
};
