import { HEADER } from '../constants/header.const';

export const getHeaderHeight = (isMinimal: boolean, hasHeaderMedia: boolean, bannerIsVisible: boolean) => {
    if (isMinimal) {
        if (HEADER.IS_BANNER_FIXED && bannerIsVisible) {
            return hasHeaderMedia ? HEADER.HEIGHT.MINIMAL_FIXED_MEDIA : HEADER.HEIGHT.MINIMAL_FIXED;
        }
        return hasHeaderMedia ? HEADER.HEIGHT.MINIMAL_MEDIA : HEADER.HEIGHT.MINIMAL;
    }

    if (HEADER.IS_BANNER_FIXED && bannerIsVisible) {
        return hasHeaderMedia ? HEADER.HEIGHT.MAIN_FIXED_MEDIA : HEADER.HEIGHT.MAIN_FIXED;
    }

    return hasHeaderMedia ? HEADER.HEIGHT.MAIN_MEDIA : HEADER.HEIGHT.MAIN;
};
