import * as React from 'react';
import { ReactNode } from 'react';

const WysiwygOverline = ({ children }: { children: ReactNode }) => {
    return (
        <div className="wysiwyg-overline">
            <span>{children}</span>
        </div>
    );
};

export default WysiwygOverline;
