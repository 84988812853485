import styled from '@emotion/styled';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { ClickAwayListener, Tooltip as MUITooltip, tooltipClasses, TooltipProps } from '@mui/material';
import React from 'react';

import { breakpoint, colors, fonts } from '../../../../../styles/theme';

const HideOnMobile = styled.div`
    ${breakpoint['$large-max']} {
        display: none;
    }
`;
const HideOnDesktop = styled.div`
    display: none;
    ${breakpoint['$large-max']} {
        display: block;
    }
`;

export const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
    <MUITooltip {...props} classes={{ popper: className }} />
))(() => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: '#013A51',
        color: 'white',
        fontFamily: fonts.OpenSans_Regular,
        fontWeight: 400,
        lineHeight: '150%',
        maxWidth: 320,
        padding: 8,
        fontSize: 12,
        border: '1px solid #dadde9',
    },
    [`.${tooltipClasses.arrow}`]: {
        color: '#013A51',
    },
}));

const StyledInfoOutlinedIcon = styled(InfoOutlinedIcon)<{ disabled?: boolean }>`
    color: ${props => (props.disabled ? '#a8bfd7' : colors.$gray)};
    display: flex;
    font-size: 16px;
    width: 16px;
    height: 16px;
    justify-content: center;
    align-items: center;

    margin-bottom: 1px;
`;

const Tooltip = ({ text, tracking, disabled }: { text: string; tracking?: string; disabled?: boolean }) => {
    const [open, setOpen] = React.useState(false);

    const handleTooltipClose = () => {
        setOpen(false);
    };

    const toogleTooltip = () => {
        setOpen(prev => !prev);
    };

    return (
        <>
            <HideOnMobile>
                <StyledTooltip title={text} placement="top-end" arrow data-ga-send={`event,button,click,${tracking}`}>
                    <StyledInfoOutlinedIcon disabled={disabled} />
                </StyledTooltip>
            </HideOnMobile>
            <HideOnDesktop>
                <ClickAwayListener onClickAway={handleTooltipClose}>
                    <div data-ga-send={`event,button,click,${tracking}`}>
                        <StyledTooltip
                            title={text}
                            placement="top-start"
                            arrow
                            PopperProps={{
                                disablePortal: true,
                            }}
                            onClose={handleTooltipClose}
                            open={open}
                            disableFocusListener
                            disableHoverListener
                            disableTouchListener
                        >
                            <StyledInfoOutlinedIcon onClick={toogleTooltip} disabled={disabled} />
                        </StyledTooltip>
                    </div>
                </ClickAwayListener>
            </HideOnDesktop>
        </>
    );
};

export default Tooltip;
