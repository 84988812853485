import { graphql, useStaticQuery } from 'gatsby';
import * as React from 'react';

import { ROUTES } from '../../constants/const/routes.const';
import PostThumb from '../media/PostThumb';

const SectionMoreVideos = () => {
    const prismicVideos = useStaticQuery(query);
    const videos = prismicVideos.allVideos.nodes;
    if (!videos.length || videos.length <= 0) return null;
    return (
        <section className="section">
            <div className="container">
                <div className="h2">À lire aussi</div>
                <div className="row thumb-list">
                    {videos.map((video, index) => (
                        <div className="col-md-3 col-sm-6" key={index}>
                            <PostThumb
                                oldImg
                                post={{
                                    uri: `${ROUTES.VIDEOS.uri + video.uid}/`,
                                    author: { node: video.data.author?.document?.data },
                                    title: video.data.title,
                                    date: video.first_publication_date,
                                    img: video.data.image.gatsbyImageData,
                                    alt: video.data.image.alt,
                                    content: video.data?.body
                                        ?.map(slice => slice.primary?.content_text?.text)
                                        .join(' '),
                                    tags: [
                                        {
                                            tag: { document: { uri: '/videos/', data: { name: 'Vidéos' } } },
                                        },
                                    ],
                                }}
                                size="small"
                            />
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
};

export default SectionMoreVideos;

const query = graphql`
    {
        allVideos: allPrismicVideo(
            filter: { data: { indexed: { eq: true } } }
            sort: { first_publication_date: DESC }
            limit: 4
        ) {
            nodes {
                id
                first_publication_date
                uid
                data {
                    title
                    image_legend {
                        richText
                    }
                    image_description {
                        richText
                    }
                    author {
                        document {
                            ...PostAuthor
                        }
                    }
                    body {
                        ... on PrismicVideoDataBodyNormalText {
                            primary {
                                content_text {
                                    text
                                }
                            }
                        }
                    }
                    image {
                        gatsbyImageData(width: 420, placeholder: BLURRED, layout: CONSTRAINED)
                        alt
                    }
                }
            }
        }
    }
`;
