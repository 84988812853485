import * as React from 'react';

import useFormatDate from '../../hooks/useFormatDate';
import Tag, { TagItem } from './Tag';
import * as styles from './tag-list.module.scss';

const TagList = ({
    tags,
    className,
    date,
    entryTime,
    noDate = false,
}: {
    tags: TagItem[];
    className?: string;
    entryTime?: boolean;
    noDate?: boolean;
    date?: string;
}) => {
    const formatedDate = useFormatDate(date);

    return (
        <div className={`${styles.tagList} ${className || ''}`}>
            {tags
                .filter(tag => tag.text)
                .map((tag, index) => (
                    <Tag
                        text={tag.text}
                        url={tag.url || null}
                        className={`${styles.tag} ${tag.className || ''}`}
                        key={index}
                    />
                ))}
            {!noDate && (
                <time
                    className={'text-mini u-block pl-1 u-align-self-center'}
                    itemProp="datePublished"
                    dateTime={date}
                    id={entryTime && 'datePublished'}
                >
                    {formatedDate}
                </time>
            )}
        </div>
    );
};

export default TagList;
