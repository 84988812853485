import styled from '@emotion/styled';
import { Checkbox, FormControl, FormControlLabel, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { classNames } from 'primereact/utils';
import * as React from 'react';
import { Controller } from 'react-hook-form';

import { colors, fonts } from '../../../styles/theme';
import Spinner from '../../Spinner';
import { ButtonSubmit } from '../../UI/Button/legacy/button/Button';
import { formData } from '../constants/constants';
import useMeetUsForm from '../hooks/useMeetUsForm';

const FirstNames = styled.div`
    display: flex;
    gap: 8px;
    @media screen and (max-width: 1024px) {
        flex-direction: column;
        gap: 16px;
    }
`;

const StyledTextField = styled(TextField)`
    border-color: ${colors.$gray300};
    border-radius: 4px;
    width: 100%;

    label {
        color: ${colors.$gray400};
    }
`;

const StyledSelect = styled(Select)`
    border-color: ${colors.$gray300};
    border-radius: 4px;
    width: 100%;

    label {
        color: ${colors.$gray400};
    }
`;

const StyledForm = styled.form`
    display: flex;
    flex-direction: column;
    gap: 16px;
`;
const ErrorMessage = styled.div`
    color: red;
    align-self: center;
    font-size: 12px;
    margin-bottom: -12px;
`;

const StyledInputLabel = styled(InputLabel)`
    color: #868e96;
`;

const MentionRGPD = styled.span`
    color: ${colors.$gray};

    font-family: ${fonts.OpenSans_Regular};
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 16.5px */

    margin-top: -8px;

    a {
        color: ${colors.$gray};
        font-family: ${fonts.OpenSans_Regular};
        font-size: 11px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
        text-decoration-line: underline;
    }
`;

export default function MeetUsFormModal(): JSX.Element {
    const { control, moreDetail, errors, isSubmitting, onSubmit, handleSubmit } = useMeetUsForm();

    return (
        <div className="card flex justify-content-center">
            <StyledForm onSubmit={handleSubmit(onSubmit)}>
                <FirstNames>
                    <Controller
                        name="firstName"
                        control={control}
                        rules={{ required: true, minLength: 2 }}
                        render={({ field, fieldState }) => (
                            <StyledTextField
                                error={!!fieldState.error}
                                variant="outlined"
                                size="medium"
                                label="Prénom"
                                value={field.value}
                                inputRef={field.ref}
                                onChange={e => field.onChange(e.target.value)}
                            />
                        )}
                    />
                    <Controller
                        name="lastName"
                        control={control}
                        rules={{ required: true, minLength: 2 }}
                        render={({ field, fieldState }) => (
                            <StyledTextField
                                variant="outlined"
                                size="medium"
                                label="Nom"
                                error={!!fieldState.error}
                                value={field.value}
                                inputRef={field.ref}
                                className={classNames({ 'p-invalid': fieldState.error })}
                                onChange={e => field.onChange(e.target.value)}
                            />
                        )}
                    />
                </FirstNames>
                <Controller
                    name="email"
                    control={control}
                    rules={{
                        required: 'Required',
                        pattern: {
                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                            message: 'invalid email address',
                        },
                    }}
                    render={({ field, fieldState }) => (
                        <StyledTextField
                            fullWidth
                            variant="outlined"
                            size="medium"
                            label="Email"
                            type="email"
                            error={!!fieldState.error}
                            value={field.value}
                            inputRef={field.ref}
                            className={classNames({ 'p-invalid': fieldState.error })}
                            onChange={e => field.onChange(e.target.value)}
                        />
                    )}
                />
                <Controller
                    name="phone"
                    control={control}
                    rules={{ required: true, minLength: 10 }}
                    render={({ field, fieldState }) => (
                        <StyledTextField
                            fullWidth
                            variant="outlined"
                            size="medium"
                            label="Téléphone"
                            type="number"
                            error={!!fieldState.error}
                            value={field.value}
                            inputRef={field.ref}
                            className={classNames({ 'p-invalid': fieldState.error })}
                            onChange={e => field.onChange(e.target.value)}
                        />
                    )}
                />
                <Controller
                    name="location"
                    control={control}
                    rules={{ required: true }}
                    render={({ field, fieldState }) => (
                        <FormControl>
                            <StyledInputLabel>Domiciliation de la société</StyledInputLabel>
                            <StyledSelect
                                value={field.value}
                                label="Domiciliation de la société"
                                size="medium"
                                name="city"
                                error={!!fieldState.error}
                                onChange={e => field.onChange(e.target.value)}
                            >
                                {formData.cities.map(item => (
                                    <MenuItem key={item.value} value={item.value}>
                                        {item.label}
                                    </MenuItem>
                                ))}
                            </StyledSelect>
                        </FormControl>
                    )}
                />
                <Controller
                    name="activity"
                    control={control}
                    rules={{ required: true }}
                    render={({ field, fieldState }) => (
                        <FormControl>
                            <StyledInputLabel>Secteur d’activité</StyledInputLabel>
                            <StyledSelect
                                value={field.value}
                                label="Secteur d'activité"
                                size="medium"
                                name="city"
                                error={!!fieldState.error}
                                onChange={e => field.onChange(e.target.value)}
                            >
                                {formData.activities
                                    .map(item => (
                                        <MenuItem key={item.value} value={item.value}>
                                            {item.label}
                                        </MenuItem>
                                    ))
                                    .sort(() => 0.5 - Math.random())}
                            </StyledSelect>
                        </FormControl>
                    )}
                />
                {moreDetail === 'Autre' && (
                    <Controller
                        name="activityFreeInput"
                        control={control}
                        rules={{ required: true, minLength: 2 }}
                        render={({ field, fieldState }) => (
                            <StyledTextField
                                variant="outlined"
                                size="medium"
                                label="Precisez votre activité"
                                error={!!fieldState.error}
                                value={field.value}
                                inputRef={field.ref}
                                onChange={e => field.onChange(e.target.value)}
                            />
                        )}
                    />
                )}
                <Controller
                    name="serviceOfInterest"
                    control={control}
                    rules={{ required: true }}
                    render={({ field, fieldState }) => (
                        <FormControl>
                            <StyledInputLabel>Service recherché</StyledInputLabel>
                            <StyledSelect
                                value={field.value}
                                label="Service recherché"
                                size="medium"
                                name="city"
                                error={!!fieldState.error}
                                onChange={e => field.onChange(e.target.value)}
                            >
                                {formData.services.map(item => (
                                    <MenuItem key={item.value} value={item.value}>
                                        {item.label}
                                    </MenuItem>
                                ))}
                            </StyledSelect>
                        </FormControl>
                    )}
                />
                <Controller
                    name="client"
                    control={control}
                    render={({ field }) => (
                        <FormControlLabel
                            control={
                                <Checkbox
                                    value={field.value}
                                    onChange={e => {
                                        field.onChange(e.target.checked);
                                    }}
                                />
                            }
                            label="Je suis déjà client chez Dougs"
                        />
                    )}
                />

                <MentionRGPD>
                    Dougs utilise les informations fournies pour vous permettre d’utiliser nos services, vous proposer
                    des informations et contenu pertinents afin de simplifier votre vie d’entrepreneur. Pour en savoir
                    plus consultez notre{' '}
                    <a href="https://www.dougs.fr/politique-confidentialite" target="_blank">
                        politique de confidentialité.
                    </a>
                </MentionRGPD>
                <ErrorMessage>{errors.fetch && errors.fetch.message}</ErrorMessage>
                <ButtonSubmit
                    fullWidth
                    data-ga-send="event,button,click,modal-meet-us-submit"
                    type="submit"
                    disabled={isSubmitting}
                >
                    {isSubmitting && <Spinner />}
                    Choisir un créneau →
                </ButtonSubmit>
            </StyledForm>
        </div>
    );
}
