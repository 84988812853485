import React from 'react';

import { ROUTES } from '../../../../../constants/const/routes.const';
import { FONCTIONNALITES, POUR_QUI, SERVICES } from '../constants/data.const';
import Dropdown from './Dropdown/Dropdown';
import DropdownSection from './Dropdown/DropdownSection';

const ComptaDropdown = () => {
    return (
        <Dropdown title={'Expert-comptable'} trackingId="topbar-bar1-text-title1-hover-dropdownlist" isFirstDropdown>
            <DropdownSection title="Services" variant="primary" items={SERVICES} />
            <DropdownSection
                title="Fonctionnalités"
                variant="primary"
                items={FONCTIONNALITES}
                bottomLink={{
                    text: 'Voir toutes les fonctionnalités',
                    url: ROUTES.FEATURES.uri,
                    trackingId: 'topbar-feature-summary',
                }}
            />
            <DropdownSection title="Pour qui ?" variant="secondary" items={POUR_QUI} />
        </Dropdown>
    );
};

export default ComptaDropdown;
