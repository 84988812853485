import React from 'react';
import { Buttons, Details, RightContainerCustomMade, TextResponse, TitleResponse } from '../../styles';
import ArrowButton from '../../../../../UI/Button/ArrowButton';
import MeetUsModal from '../../../../../meetUs/Modal/MeetUsModal';
import useModal from '../../../../../../hooks/useModal';

const SuccessContent = ({ pack, peoples }) => {
    const { isModalOpen, openModal, closeModal } = useModal();
    return (
        <RightContainerCustomMade>
            <MeetUsModal isOpen={isModalOpen} close={closeModal} />
            <TitleResponse>Comment Dougs vous accompagne ?</TitleResponse>
            <TextResponse>
                {pack.map(line => (
                    <li>{line}</li>
                ))}
                {peoples > 0 && <li>Pack social (fiches de paie de vos salariés)</li>}
            </TextResponse>
            <Buttons>
                <ArrowButton
                    actionType="INTERNAL_LINK"
                    color="ORANGE"
                    linkOptions={{
                        href: 'https://app.dougs.fr/signup',
                        rel: 'noopener nofollow',
                        target: '_blank',
                    }}
                    trackingId="calculateur-essayer-dougs"
                >
                    Essayer gratuitement
                </ArrowButton>
                <ArrowButton
                    actionType="MODAL"
                    color="LIGHT_ORANGE"
                    onClick={openModal}
                    trackingId="calculateur-prendre-rendez-vous"
                >
                    Prendre rendez-vous
                </ArrowButton>
            </Buttons>

            <Details>
                * L’économie annuelle estimée repose sur notre tarification et sur les données que vous avez
                renseignées.
            </Details>
        </RightContainerCustomMade>
    );
};

export default SuccessContent;
