import styled from '@emotion/styled';
import { StaticImage } from 'gatsby-plugin-image';
import * as React from 'react';

import MeetUsFormModal from './FormModal';

const Wrapper = styled.div`
    display: flex;
    gap: 120px;
    @media screen and (max-width: 1024px) {
        gap: 0px;
        flex-direction: column;
        padding: 24px;
    }
`;

const Title = styled.div`
    font-size: 40px;
    font-family: Eina-SemiBold, serif;
    @media screen and (max-width: 1024px) {
        font-size: 32px;
    }
    color: white;
`;

const Paragraph = styled.div`
    color: white;
`;
const Pattern = styled.div`
    background: url('/images/blog/modal/cover-pattern-2.svg') no-repeat center;
    background-size: contain;
    position: absolute;
    left: 37%;
    right: 64.24%;
    top: 21.11%;
    bottom: 77.7%;
    width: 24px;
    @media screen and (max-width: 1024px) {
        display: none;
    }
`;

const Pattern2 = styled.div`
    background: url('/images/blog/modal/cover-pattern-3.svg') no-repeat center;
    background-size: contain;
    position: absolute;
    left: 34%;
    right: 66.25%;
    top: 15.02%;
    bottom: 81%;
    width: 18px;
    height: 18px;
    @media screen and (max-width: 1024px) {
        display: none;
    }
`;

const Pattern3 = styled.div`
    background: url('/images/blog/modal/cover-pattern-1.svg') no-repeat center;
    background-size: contain;
    position: absolute;
    left: 7%;
    top: 2%;
    bottom: 85.09%;
    width: 24px;
    @media screen and (max-width: 1024px) {
        display: none;
    }
`;

const Col = styled.div`
    flex: 1;
    max-width: 420px;
    width: 100%;
    .img {
        width: 100%;
        @media screen and (max-width: 1024px) {
            display: none;
        }
    }
`;

const FormWrapper = styled.div`
    background-color: white;
    padding: 24px;
    border-radius: 16px;
`;

const Step1Modal = () => {
    return (
        <Wrapper>
            <Col>
                <Title>
                    <Pattern />
                    <Pattern2 />
                    <Pattern3 />
                    Prendre
                    <br /> rendez-vous
                </Title>
                <Paragraph className="mb-4 mt-2">
                    Faisons connaissance 😊
                    <br /> Nous avons besoin de quelques informations pour préparer au mieux votre rendez-vous.
                </Paragraph>
                <StaticImage
                    className="img"
                    src={'../../../images/blog/illu-091.svg'}
                    alt="Rendez-vous avec Dougs pour faire connaissance"
                />
            </Col>
            <Col>
                <FormWrapper>
                    <MeetUsFormModal />
                </FormWrapper>
            </Col>
        </Wrapper>
    );
};

export default Step1Modal;
