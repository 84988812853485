import { graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';
import * as React from 'react';
import { useCallback } from 'react';

import HubspotForm from '../components/hubspot/HubspotForm';
import TopPaddingBasedOnHeader from '../components/layout/header/utils/TopPaddingBasedOnHeader';
import Layout from '../components/layout/Layout';
import GuideBanner from '../components/media/GuideBanner';
import Modal from '../components/modals/Modal';
import Breadcrumb from '../components/navigation/Breadcrumb';
import SectionFactureX from '../components/section/SectionFactureX';
import { ROUTES } from '../constants/const/routes.const';
import useModal from '../hooks/useModal';

const windowLocation = typeof window === 'undefined' ? null : window.location;

const Guide = ({ data }: { data: { prismicGuide: Queries.PrismicGuide } }) => {
    const guide = data.prismicGuide;

    const { isModalOpen, openModal, closeModal } = useModal();

    const onSubmitForm = useCallback(() => {
        (document.getElementsByName('content_url')[0] as any).value = window.location.href;
    }, []);

    const seo = {
        titleText: guide.data.meta_title || `${guide.data.title}  - Dougs Compta`,
        metaDesc: guide.data.meta_description,
    };

    const breadcrumb = [ROUTES.HOME, ROUTES.GUIDES, { uri: guide.url, name: guide.data.title }];
    const simplifiedNavigation = windowLocation && windowLocation.search.includes('nolayout');

    return (
        <Layout seo={seo} hasHeaderMedia>
            <TopPaddingBasedOnHeader padding={{ desktop: 0, tablet: 0, mobile: 0 }} />
            <main className="guide-detail">
                {!simplifiedNavigation && breadcrumb && (
                    <div className="container">
                        <Breadcrumb items={breadcrumb} />
                    </div>
                )}
                <div className="section">
                    <div className="container">
                        <div className="row guide-detail__hero">
                            <div className="col-lg-5 col-md-6 offset-lg-1">
                                <h1>{guide.data.ribbon}</h1>

                                <div dangerouslySetInnerHTML={{ __html: guide.data.introduction.html }}></div>

                                <button
                                    className="Button mt-3"
                                    data-ga-send={`event,button,click,guide-${guide.data.slug}-cta1`}
                                    onClick={openModal}
                                >
                                    Télécharger&nbsp;&rarr;
                                </button>
                            </div>
                            {!simplifiedNavigation && (
                                <div className="col-lg-4 offset-md-1 col-md-5">
                                    <div
                                        className="u-clickable"
                                        data-ga-send={`event,button,click,guide-${guide.data.slug}-image`}
                                        onClick={openModal}
                                    >
                                        <GatsbyImage
                                            loading="lazy"
                                            image={guide.data.cover.gatsbyImageData}
                                            className="guide-detail__cover"
                                            alt={guide.data.cover.alt || ''}
                                        />
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>

                <section className="section section--gray section--large">
                    <div className="container">
                        <div className="row u-align-center">
                            <div className="col-md-5 offset-md-1">
                                <div className="triptic">
                                    {guide.data.content_image_1.gatsbyImageData && (
                                        <GatsbyImage
                                            alt={guide.data.content_image_1.alt || ''}
                                            loading="lazy"
                                            image={guide.data.content_image_1.gatsbyImageData}
                                            className="triptic__image"
                                        />
                                    )}
                                    {guide.data.content_image_2.gatsbyImageData && (
                                        <GatsbyImage
                                            alt={guide.data.content_image_2.alt || ''}
                                            loading="lazy"
                                            image={guide.data.content_image_2.gatsbyImageData}
                                            className="triptic__image"
                                        />
                                    )}
                                    {guide.data.content_image_3.gatsbyImageData && (
                                        <GatsbyImage
                                            alt={guide.data.content_image_3.alt || ''}
                                            loading="lazy"
                                            image={guide.data.content_image_3.gatsbyImageData}
                                            className="triptic__image"
                                        />
                                    )}
                                </div>
                            </div>
                            <div className="col-md-5 offset-md-1">
                                <h2>À l'intérieur du Guide</h2>

                                <ol className="list-summary">
                                    {guide.data.summary.map((item, index) => (
                                        <li className="list-summary__item" key={index}>
                                            <span className="list-summary__number">{index + 1}</span>
                                            <span className="list-summary__text">{item.summary_title}</span>
                                        </li>
                                    ))}
                                </ol>

                                <button
                                    className="Button mt-3"
                                    data-ga-send={`event,button,click,guide-${guide.data.slug}-cta2`}
                                    onClick={openModal}
                                >
                                    Télécharger&nbsp;&rarr;
                                </button>
                            </div>
                        </div>
                    </div>
                </section>
            </main>

            {!simplifiedNavigation && <GuideBanner />}
            {guide.data.slug === 'guide-pour-tout-savoir-sur-la-facturation' && (
                <SectionFactureX tracking="guide-facturation-facturX-section" />
            )}
            <Modal isOpen={isModalOpen} close={closeModal} size="small">
                <h2>Téléchargez le guide gratuit.</h2>
                <HubspotForm
                    trackingId="guide"
                    formId="b6daa47b-f089-4263-b898-a2f3d8c62c94"
                    onSubmit={onSubmitForm}
                ></HubspotForm>
            </Modal>
        </Layout>
    );
};

export default withPrismicPreview(Guide);

export const postQuery = graphql`
    query ($id: String) {
        prismicGuide(id: { eq: $id }) {
            id
            prismicId
            _previewable
            first_publication_date
            last_publication_date
            data {
                slug
                title
                meta_title
                meta_description
                ribbon
                summary {
                    summary_title
                }
                content_image_1 {
                    gatsbyImageData
                    alt
                }
                content_image_2 {
                    gatsbyImageData
                    alt
                }
                content_image_3 {
                    gatsbyImageData
                    alt
                }
                cover {
                    gatsbyImageData
                    alt
                }
                introduction {
                    html
                }
            }
        }
    }
`;
