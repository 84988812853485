import React from 'react';

import { ROUTES } from '../../../../../../constants/const/routes.const';
import { FONCTIONNALITES, POUR_QUI, SERVICES } from '../../constants/data.const';
import MobileSubmenu from './MobileSubmenu/MobileSubmenu';
import SubmenuSection from './MobileSubmenu/SubmenuSection';

const ComptaSubmenu = () => {
    return (
        <MobileSubmenu title={'Expert-comptable'} trackingId="'topbar-bar1-text-title1-hover-dropdownlist'">
            <SubmenuSection title="Services" variant="primary" items={SERVICES} />
            <SubmenuSection
                title="Fonctionnalités"
                variant="primary"
                items={FONCTIONNALITES}
                bottomLink={{
                    text: 'Voir toutes les fonctionnalités',
                    url: ROUTES.FEATURES.uri,
                    trackingId: 'topbar-feature-summary',
                }}
            />
            <SubmenuSection title="Pour qui ?" variant="secondary" items={POUR_QUI} />
        </MobileSubmenu>
    );
};

export default ComptaSubmenu;
