export type payloadType = {
    email: string;
    phone: string;
    lastName: string;
    firstName: string;
    activity: string;
    location: string;
    serviceOfInterest: string;
    activityFreeInput: string;
    client: boolean;
    fetch: boolean;
};

export enum Steps {
    NotFrance = 'not_france',
    BadActivity = 'bad_activity',
    Both = 'both',
    OldClient = 'old_client',
    Calendly = 'calendly',
}

export enum IneligibleReasons {
    Activity = 'activity',
    Location = 'location',
}
