import { Link } from 'gatsby';
import * as React from 'react';

import { PaginationData } from '../types/Pagination';

const Pagination = ({ uri, pagination }: { uri: string; pagination: PaginationData }) => {
    return (
        <nav>
            <ul className="pagination">
                {pagination.current > 1 && (
                    <li>
                        <Link
                            to={pagination.current === 2 ? `${uri}` : `${uri}page/${pagination.current - 1}/`}
                            className="pagination__prev"
                            rel="prev"
                        >
                            &larr;&nbsp;
                        </Link>
                    </li>
                )}

                {[-3, -2, -1, 0, 1, 2, 3]
                    .filter(
                        offset => pagination.current + offset >= 1 && pagination.current + offset <= pagination.count,
                    )
                    .map(offset => (
                        <li key={pagination.current + offset} className={offset ? '' : 'current'}>
                            <Link
                                to={
                                    pagination.current + offset === 1
                                        ? `${uri}`
                                        : `${uri}page/${pagination.current + offset}/`
                                }
                            >
                                {pagination.current + offset}
                            </Link>
                        </li>
                    ))}

                {pagination.current < pagination.count && (
                    <li>
                        <Link to={`${uri}page/${pagination.current + 1}/`} rel="next" className="pagination__next">
                            &nbsp;&rarr;
                        </Link>
                    </li>
                )}
            </ul>
        </nav>
    );
};

export default Pagination;
