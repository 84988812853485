import { useEffect, useRef, useState } from 'react';

export default () => {
    const [isScrollDown, setIsScrollDown] = useState(false);
    const lastScrollTop = useRef(0);

    useEffect(() => {
        window.addEventListener(
            'scroll',
            () => {
                const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
                setIsScrollDown(scrollTop > lastScrollTop.current);
                lastScrollTop.current = scrollTop <= 0 ? 0 : scrollTop;
            },
            false,
        );
    }, [lastScrollTop]);

    return isScrollDown;
};
