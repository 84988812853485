import * as React from 'react';
import { ReactNode } from 'react';

const WysiwygPanel = ({ children }: { children: ReactNode[] }) => {
    return (
        <div className="wysiwyg-panel">
            {children.flat().map((child, key) => (
                <div data-testid="wysiwyg-panel-child" key={key}>
                    {child}
                </div>
            ))}
        </div>
    );
};

export default WysiwygPanel;
