import { graphql } from 'gatsby';
import * as React from 'react';
import Faq from 'react-faq-component';

import { isBrowser } from '../../../utils/utils';
import styled from '@emotion/styled';

const HiddenFaqForSEO = styled.div`
    display: none;
`;

const Decorator = (props: { children: React.ReactNode }) => {
    isBrowser &&
        document &&
        document
            .querySelectorAll('[role="listitem"]')
            .forEach(
                (element: HTMLElement, index) =>
                    (element.dataset.ga4 = `container-blog-FAQ-text-title${index + 1}-click-link`),
            );
    return <React.Fragment>{props.children}</React.Fragment>;
};

export const FaqSlice = ({
    slice,
}: {
    slice: {
        items;
    };
}) => {
    const data = slice.items as [Queries.PrismicPostDataBodyFaqItem];
    return (
        <div className="mb-6">
            <Decorator>
                <Faq
                    data={{
                        rows: data.map(item => {
                            return {
                                title: item.question,
                                content: item.answer.html,
                            };
                        }),
                    }}
                />
            </Decorator>
            <HiddenFaqForSEO>
                {data.map(item => (
                    <div key={item.question}>
                        <h3>{item.question}</h3>
                        <div dangerouslySetInnerHTML={{ __html: item.answer.html }} />
                    </div>
                ))}
            </HiddenFaqForSEO>
        </div>
    );
};

export const query = graphql`
    fragment VideoDataButton on PrismicVideoDataBodyButton {
        primary {
            content_text {
                richText
                text
            }
        }
        slice_type
    }
`;
