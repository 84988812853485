import styled from '@emotion/styled';
import React from 'react';

import { colors } from '../../styles/theme';

interface DividerProps {
    isVertical?: boolean;
    marginY?: number;
    isOpacityActivated?: boolean;
    size?: number;
}

const DividerHorizontal = styled.div<{ marginY: number; isOpacityActivated: boolean; size: number }>`
    height: ${props => `${props.size}px`};
    background-color: ${props => (props.isOpacityActivated ? 'rgba(255, 255, 255, 0.10)' : colors.$gray300)};
    margin: ${props => (props.marginY ? props.marginY + 'px 0' : 0)};
    width: 100%;
`;

const DividerVertical = styled.div<{ isOpacityActivated: boolean; size: number }>`
    width: ${props => `${props.size}px`};
    height: 100%;
    background-color: ${props => (props.isOpacityActivated ? 'rgba(255, 255, 255, 0.10)' : colors.$gray300)};
    height: 100%;
`;

const Divider = ({ isVertical, marginY, isOpacityActivated, size = 1 }: DividerProps) => {
    return isVertical ? (
        <DividerVertical isOpacityActivated={isOpacityActivated} size={size} />
    ) : (
        <DividerHorizontal isOpacityActivated={isOpacityActivated} size={size} marginY={marginY} />
    );
};

export default Divider;
