import * as React from 'react';
import ZoomableImage from './ZoomableImage';
import { transformPrismicImageToGatsbyImage } from '../utils';
import styled from '@emotion/styled';

const ImageContainer = styled.div``;

export const Image = ({
    slice,
}: {
    slice: {
        primary: {
            legend: string;
            image: {
                url: string;
                alt: string;
            };
        };
    };
}) => {
    return (
        <ImageContainer>
            <ZoomableImage
                image={transformPrismicImageToGatsbyImage(slice.primary.image.url)}
                alt={slice.primary.image.alt}
            />
            {slice.primary.legend && <div className="text-mini italic">{slice.primary.legend}</div>}
        </ImageContainer>
    );
};
