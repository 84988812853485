import React from 'react';

import { RESSOURCES, TOP_SIMULATEURS } from '../../constants/data.const';
import MobileSubmenu from './MobileSubmenu/MobileSubmenu';
import SubmenuSection from './MobileSubmenu/SubmenuSection';

const RessourcesSubmenu = () => {
    return (
        <MobileSubmenu title={'Ressources'} trackingId="'topbar-bar1-text-title1-hover-dropdownlist'">
            <SubmenuSection title="Ressources" variant="primary" items={RESSOURCES} />

            <SubmenuSection
                title="Top simulateurs"
                variant="secondary"
                items={TOP_SIMULATEURS}
                bottomLink={{
                    text: 'Voir tous les simulateurs',
                    url: '/ressources/',
                    trackingId: 'topbar-ressources-ressources',
                }}
            />
        </MobileSubmenu>
    );
};

export default RessourcesSubmenu;
