import styled from '@emotion/styled';
import { Link } from 'gatsby';
import React from 'react';
import { breakpoint, colors, fonts } from '../../styles/theme';

const Container = styled.div`
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: max-content;
    background: ${colors.$primary200};

    padding: 0px 8px;
    height: 24px;
    font-size: 12px;

    ${breakpoint.laptop} {
        padding: 0px 12px;
        height: 32px;
        font-size: 13px;
    }

    :hover {
        background: #cae5f2;
    }

    color: ${colors.$primary700};
    font-family: ${fonts.OpenSans_Regular};
    font-weight: 600;
`;

interface PostTagProps {
    label: string;
    url?: string;
}

const PostTag = ({ label, url }: PostTagProps) => {
    return (
        <>
            {url ? (
                <Link to={url} data-ga-send="event,button,click,blog-tag" data-ga4="container-tag-text-click-link">
                    <Container>{label}</Container>
                </Link>
            ) : (
                <Container>{label}</Container>
            )}
        </>
    );
};

export default PostTag;
