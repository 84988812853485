export const TYPE_ACTIVITE = [
    {
        label: 'Prestation de service',
        id: 'service',
        chiffreAffaire: [
            {
                label: 'Inf. à 247k€',
                id: 'cat1',
            },
            {
                label: 'Entre 247k€ et 600k€',
                id: 'cat2',
            },
            {
                label: 'Sup. à 600k€',
                id: 'cat3',
            },
        ],
    },
    {
        label: 'Vente de produits',
        id: 'produit',
        chiffreAffaire: [
            {
                label: 'Inf. à 810k€',
                id: 'cat1',
            },
            {
                label: 'Entre 810k€ et 1,5M€',
                id: 'cat2',
            },
            {
                label: 'Sup. à 1,5M€',
                id: 'cat3',
            },
        ],
    },
    {
        label: 'SCI / LMNP',
        id: 'sci',
        chiffreAffaire: null,
    },
];

export const OPERATIONS_BANQUAIRES = [
    {
        label: '-',
        value: '',
        price: 159,
        disabled: true,
    },
    {
        label: '0 à 50 opérations',
        value: '0_a_50',
        price: 159,
    },
    {
        label: '51 à 100 opérations',
        value: '51_a_100',
        price: 189,
    },
    {
        label: '101 à 150 opérations',
        value: '101_a_150',
        price: 219,
    },
    {
        label: '151 à 200 opérations',
        value: '151_a_200',
        price: 249,
    },
    {
        label: '201 opérations ou plus',
        value: 'over_201',
        price: 279,
    },
];

export const PACK = {
    SERENITE: [
        'Pack Sérénité (le plus adapté à votre profil)',
        'Conseils et accompagnement illimités',
        'Accès à un tableau de bord en temps réel',
        'Formalités juridiques incluses (dépôt des comptes, AGOA...)',
    ],
    PME: [
        "Pack PME, Dougs gère les spécificités de la compta d'engagement",
        'Conseils et accompagnement illimités',
        'Accès à un tableau de bord en temps réel',
        'Formalités juridiques incluses (dépôt des comptes, AGOA...)',
    ],
    LMNP: [
        'Pack LMNP / SCI (le plus adapté à votre profil)',
        'Conseils et accompagnement illimités',
        'Suivi et préparation de vos déclarations de TVA',
    ],
};
