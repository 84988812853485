export const breakpoints = {
    small: 0,
    smallPhone: 374,
    medium: 640,
    large: 1024,
    xlarge: 1226,
    xxlarge: 1460,
};

export const customMax = (px: string): string => `@media only screen and (max-width: ${px}px)`;
export const customMin = (px: string): string => `@media only screen and (min-width: ${px}px)`;

export const breakpoint = {
    '$small-min': `@media only screen and (min-width: ${breakpoints.small}px)`,
    '$small-only': `@media only screen and (min-width: ${breakpoints.small}px) and (max-width: ${breakpoints.medium}px)`,

    '$smallPhone-min': `@media only screen and (min-width: ${breakpoints.smallPhone}px)`,
    '$smallPhone-only': `@media only screen and (min-width: ${breakpoints.smallPhone}px) and (max-width: ${breakpoints.medium}px)`,
    '$smallPhone-max': `@media only screen and (max-width: ${breakpoints.smallPhone}px)`,

    '$medium-min': `@media only screen and (min-width: ${breakpoints.medium}px)`,
    '$medium-max': `@media only screen and (max-width: ${breakpoints.medium}px)`,
    '$medium-only': `@media only screen and (min-width: ${breakpoints.medium}px) and (max-width: ${breakpoints.large}px)`,

    '$large-min': `@media only screen and (min-width: ${breakpoints.large}px)`,
    '$large-max': `@media only screen and (max-width: ${breakpoints.large}px)`,
    '$large-only': `@media only screen and (min-width: ${breakpoints.large}px) and (max-width: ${breakpoints.xlarge}px)`,

    '$xlarge-min': `@media only screen and (min-width: ${breakpoints.xlarge}px)`,
    '$xlarge-max': `@media only screen and (max-width: ${breakpoints.xlarge}px)`,

    tablet: `@media only screen and (min-width: ${breakpoints.medium}px)`,
    laptop: `@media only screen and (min-width: ${breakpoints.large}px)`,
    desktop: `@media only screen and (min-width: ${breakpoints.xlarge}px)`,
    custom: {
        max: customMax,
        min: customMin,
    },
};

export const fonts = {
    OpenSans_Bold: 'OpenSans-Bold, Arial, sans-serif',
    OpenSans_SemiBold: 'OpenSans-SemiBold, Arial, sans-serif',
    OpenSans_Regular: 'OpenSans-Regular, Arial, sans-serif',
    Eina_SemiBold: 'Eina03-SemiBold, Arial, sans-serif',
    Eina03_Regular: 'Eina03-Regular, Arial, sans-serif',
    Eina_Regular: 'Eina-Regular, Arial, sans-serif',
};

export const colors = {
    $primary: '#11abec',
    $primary100: '#F1F7FC',
    $primary200: '#dff2fb', // used only in components/testimonialquote, pages/temoignagesdetails & pages/temoignagesmain
    $primary300: '#c0ede9', // used only in pages/features & pages/guide
    $primary500: '#0073c0', // used only in menu / not in design system
    $primary700: '#013a51',
    $primary800: '#002534',
    $primary900: '#000f13',
    $primaryHighlight: '#4da6e2', // used only in abstract/mixins, base/link, pages/jobs / not in design system

    $gray: '#58676d',
    $gray700: '#3E444C',
    $gray600: '#E0EAF0', // Not in design system
    $gray500: '#686e75',
    $gray400: '#868e96',
    $gray350: '#686e75',
    $gray300: '#dce4ed',
    $gray200: '#f4f4f4',
    $gray100: '#F3F5F8',
    $grayMidlight: '#e9e9e9', // not in design system
    $graylight: '#c4c4c4', // not in design system

    $green: '#0ed094',
    $greenSuccess: '#06533b',
    $green200: '#cff6ea',
    $red: '#fd755f',
    $red200: '#ffe3df',
    $red600: '#FF6347',
    $redWarning: '#d32f2f', //not in design system, red from MUI

    $light: '#faf4e4',
    $lightBlue: '#93c1ef',
    $darkBlue: '#0b0a8c',
    $pink: '#ffb8b1',

    $yellow: '#ffca22',
    $yellow200: '#FFF5BF',

    $orange: '#faa11c',
    $lightOrange: '#fff7e8',
};
