export const readingTimeCalculator = (content: string): number => Math.round(content.split(' ').length / 200);

const DEFAULT_READING_TIME = 5;

export const readingTimePostCalculator = (post: any): number => {
    // TODO
    const content =
        post.content ||
        post.data?.body
            ?.map(slice => slice.primary?.content_text?.richText.map(content => content.text).join(' '))
            .join(' ')
            .trim() ||
        post.data?.body
            ?.map(slice => slice.primary?.content?.richText.map(content => content.text).join(' '))
            .join(' ');

    return content ? Math.round(content.split(' ').length / 200) : DEFAULT_READING_TIME;
};
