import styled from '@emotion/styled';
import { graphql, useStaticQuery } from 'gatsby';
import * as React from 'react';

import { breakpoint, colors } from '../../styles/theme';
import { Button } from '../UI/Button/legacy/button/Button';
import HubspotForm from '../hubspot/HubspotForm';
import { SeoTitle } from '../seo/seoTitle';
import * as styles from './post-highlight.module.scss';

const PremiumHashtag = styled.span`
    color: ${colors.$green};
    font-size: 20px;
    margin-right: 4px;
`;

const PremiumHashtagWrapper = styled.div`
    display: flex;
    align-items: center;
`;

const PremiumContent = styled.div`
    margin-top: 24px !important;
    justify-content: center;
    ${breakpoint['$large-max']} {
        margin-top: 0px !important;
        padding: 0px !important;
    }
`;

const PremiumContentTitle = styled.div`
    font-family: Eina-SemiBold, Arial, sans-serif;
    font-size: 26px;
    line-height: 30px;
`;

const PremiumContentBody = styled.div`
    display: flex;
    flex-direction: column;
    padding: 32px;
    color: white;
`;

const PremiumContentOverlay = styled.div<{ src: string }>`
    background: linear-gradient(270deg, rgba(1, 58, 81, 0.45) 0%, rgba(1, 58, 81, 0.9) 100%),
        ${props => `url(${props.src})`};
    background-size: 100%;
    background-position-y: center;
    ${breakpoint['$large-min']} {
        margin: 24px;
        margin-left: 16px;
    }
`;

const PostWithNewsetter = () => {
    const data = useStaticQuery(query);
    const prismicBlogconfig: Queries.PrismicBlogconfigData = data.prismicBlogconfig.data;
    const { hashtag, title, url, cta_title, image } = prismicBlogconfig;
    const firstsWords = title.split(' ').slice(0, -1).join(' ');
    const lastWord = title.split(' ').pop();
    const formatedTitle = firstsWords + '&nbsp;' + lastWord;
    return (
        <div className={`row u-no-margin ${styles.postHighlight}`}>
            <PremiumContent className={`col-md-8 u-no-margin u-align-self-center ${styles.postHighlight}`}>
                <PremiumContentOverlay src={image.url}>
                    <PremiumContentBody className="col-md-7">
                        <PremiumHashtagWrapper>
                            <PremiumHashtag>#</PremiumHashtag>
                            {hashtag}
                        </PremiumHashtagWrapper>
                        <PremiumContentTitle className="mt-1 mb-1">
                            <span dangerouslySetInnerHTML={{ __html: formatedTitle }} />
                        </PremiumContentTitle>
                        <a
                            href={url.url}
                            target="_blank"
                            rel="noopener nofollow"
                            data-ga4="container-miniature1-premium-button-click-link"
                            data-ga-send="event,button,click,blog-cta-premium"
                        >
                            <Button left className="mt-1">
                                {cta_title}
                            </Button>
                        </a>
                    </PremiumContentBody>
                </PremiumContentOverlay>
            </PremiumContent>
            <div className={`${styles.postHighlightEmailBlock} col-md-4 panel panel--primary u-no-margin`}>
                <SeoTitle className="mb-2" seoStyle="h4">
                    Inscrivez-vous
                    <br />à la newsletter Dougs !
                </SeoTitle>
                <HubspotForm trackingId="newsletter" formId={'e87517fb-7c30-4855-9129-b3c300ad0b7b'} />
            </div>
        </div>
    );
};

export default PostWithNewsetter;

const query = graphql`
    {
        prismicBlogconfig {
            data {
                cta_title
                hashtag
                title
                url {
                    url
                }
                image {
                    url
                }
            }
        }
    }
`;
