import { graphql } from 'gatsby';
import * as React from 'react';

import DownloadChecklist from '../media/DownloadChecklist';
import VatNumber from '../VatNumer';
import { Button } from './slices/Button';
import { Card } from './slices/Card';
import { FaqSlice as Faq } from './slices/Faq';
import { Guide } from './slices/Guide';
import { Image } from './slices/Image';
import { LineHorizontal } from './slices/LineHorizontal';
import { Newsletter } from './slices/Newsletter';
import { Overline } from './slices/Overline';
import { Quote } from './slices/Quote';
import { Summary } from './slices/Summary';
import { Text } from './slices/Text';
import { WordpressContent } from './slices/WordpressContent';

export const SliceZone = ({
    sliceZone,
    noChecklist = false,
}: {
    sliceZone: Queries.PrismicPostDataBody[];
    noChecklist: boolean;
}) => {
    const sliceComponents = {
        normal_text: Text,
        overline: Overline,
        quote: Quote,
        button: Button,
        card: Card,
        line_horizontal: LineHorizontal,
        image: Image,
        guide: Guide,
        tva_number: VatNumber,
        download_checklist: DownloadChecklist,
        wordpresscontent: WordpressContent,
        summary: Summary,
        faq: Faq,
        newsletter: Newsletter,
    };
    if (
        !noChecklist &&
        !sliceZone
            .map(slice => slice.slice_type)
            .find(slicetype => slicetype === 'download_checklist' || slicetype === 'wordpresscontent')
    ) {
        sliceZone.splice(3, 0, { id: '', slice_type: 'download_checklist', slice_label: '' });
    }
    const sliceZoneContent = sliceZone.map((slice, index) => {
        const SliceComponent = sliceComponents[slice.slice_type];
        let buttonNumber = 0;
        if (SliceComponent) {
            if (slice.slice_type === 'button') {
                buttonNumber++;
            }
            return (
                <SliceComponent slices={sliceZone} buttonNumber={buttonNumber} slice={slice} key={`slice-${index}`} />
            );
        }
        return null;
    });

    return <div>{sliceZoneContent}</div>;
};

export const postDataNewsletterFragment = graphql`
    fragment PostNewsletter on PrismicPostDataBodyNewsletter {
        id
        slice_type
    }
`;

export const postDataImageFragment = graphql`
    fragment PostImage on PrismicPostDataBodyImage {
        id
        slice_type
        primary {
            legend
            description
            image {
                url
                alt
            }
        }
    }
`;

export const postDataWordpressContentFragment = graphql`
    fragment PostWordpress on PrismicPostDataBodyWordpresscontent {
        id
        slice_type
        primary {
            content {
                richText
                html
                text
            }
        }
    }
`;

export const postDataOverlineFragment = graphql`
    fragment PostOverline on PrismicPostDataBodyOverline {
        id
        slice_type
        primary {
            content_text {
                richText
            }
        }
    }
`;

export const postDataNormalTextFragment = graphql`
    fragment PostNormalText on PrismicPostDataBodyNormalText {
        id
        slice_type
        primary {
            content_text {
                richText
                html
                text
            }
        }
    }
`;

export const postDataLineHorizontalFragment = graphql`
    fragment PostLineHorizontal on PrismicPostDataBodyLineHorizontal {
        id
        slice_type
        primary {
            content_text {
                richText
            }
        }
    }
`;

export const postDataCardFragment = graphql`
    fragment PostCard on PrismicPostDataBodyCard {
        id
        slice_type
        primary {
            content_text {
                richText
            }
        }
    }
`;

export const postDataButtonFragment = graphql`
    fragment PostButton on PrismicPostDataBodyButton {
        id
        slice_type
        primary {
            content_text {
                richText
            }
            link {
                url
                target
            }
        }
    }
`;

export const postDataGuideFragment = graphql`
    fragment PostGuide on PrismicPostDataBodyGuide {
        id
        slice_type
    }
`;

export const postDataTvaFragment = graphql`
    fragment PostTVA on PrismicPostDataBodyTvaNumber {
        id
        slice_type
    }
`;

export const postDataDownloadFragment = graphql`
    fragment PostDownload on PrismicPostDataBodyDownloadChecklist {
        id
        slice_type
        primary {
            checklist {
                document {
                    ... on PrismicChecklist {
                        id
                        _previewable
                        data {
                            cta_text
                            description {
                                text
                            }
                            form_hubspot_id
                            title
                            image {
                                gatsbyImageData
                            }
                        }
                    }
                }
            }
        }
    }
`;

export const postDataSummaryFragment = graphql`
    fragment PostSummary on PrismicPostDataBodySummary {
        id
        slice_type
    }
`;

export const postDataFaqFragment = graphql`
    fragment PostFaq on PrismicPostDataBodyFaq {
        id
        slice_type
        items {
            answer {
                html
                text
            }
            question
        }
    }
`;
